import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
import { Toogle } from '../../../components/toogle/Toogle'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
import { validateFields } from '../../../utils/form.validator'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { FaInfo } from 'react-icons/fa'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { maxNumber } from '../../../utils/mask/maxNumber'

export function AssociatiosCreate() {
    const { api_auth, api_financial, defaultCallsSchedules } = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [servicesList, setServicesList] = useState([])
    const [activeTab, setActiveTab] = useState(1)

    //selected variables
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [agreementsAccess, setAgreementsAccess] = useState(true)
    const [requireRegistrationCode, setRequireRegistrationCode] = useState(false)

    // config financial
    const [configFinancial, setConfigFinancial] = useState({
        useFinancialUsersBalance: true,
        useAutoRenewBalance: false,
        autoRenewResetBalance: false,
        autoRenewPeriod: '',
        autoRenewMonth: '',
        autoRenewDay: '',
        balanceType: '',
        balanceValue: ''
    })
    const boolOptions = [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
    ]
    const renewPeriodOptions = [
        { value: 'month', label: 'Mensal' },
        { value: 'year', label: 'Anual' },
    ]
    const balanceTypeOptions = [
        { value: "fixed", label: "Fixo" },
        { value: "percentageOfSalary", label: "Porcentagem do salário" },
    ]

    useEffect(() => {
        setShowUniversalModal(false)
        async function getData() {
            const servicesData = await defaultCallsSchedules.getServices(userData[0].token)
            if (servicesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(servicesData.data)
            }
            setServicesList(servicesData.data)
        }

        getData()
    }, [])

    async function handleSubmit() {
        setShowUniversalModal(false)
        let requiredFields = [
            { "name": 'name', "value": name, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' }
        ]
        let configFinancialFields = []
        let currentTab = activeTab

        if (currentTab === 1) {
            if (validateFields(requiredFields)?.length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalText('Campos obrigatórios não preenchidos!')
                return setShowNotificationModalSuccess(false)
            }
        }
        
        if (configFinancial?.useFinancialUsersBalance) {
            configFinancialFields.push(...[
                { "name": 'useAutoRenewBalance', "value": String(configFinancial?.useAutoRenewBalance), "required": true, "type": 'string' },
                { "name": 'balanceType', "value": configFinancial?.balanceType, "required": true, "type": 'string' },
                { "name": 'balanceValue', "value": configFinancial?.balanceValue, "required": true, "type": 'string' },
            ])
        }

        if (configFinancial?.useFinancialUsersBalance && configFinancial?.useAutoRenewBalance) {
            configFinancialFields.push(...[
                { "name": 'autoRenewDay', "value": configFinancial?.autoRenewDay, "required": true, "type": 'string' },
                { "name": 'autoRenewPeriod', "value": configFinancial?.autoRenewPeriod, "required": true, "type": 'string' }
            ])
        }

        if (configFinancial?.useAutoRenewBalance && configFinancial?.autoRenewPeriod === "year") {
            configFinancialFields.push({ "name": 'autoRenewMonth', "value": configFinancial?.autoRenewMonth, "required": true, "type": 'string' })
        }

        if (configFinancialFields?.length > 0) {
            setActiveTab(2)
            currentTab = 2
        }

        if (currentTab === 2) {
            if (validateFields(configFinancialFields)?.length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos da aba "Financeiro"')
            }
        }

        try {
            setLoading(true)
            const created = await api_auth.post(`/api/v1/auth/associations`, {
                name,
                description: description ? description : undefined,
                agreementsAccess,
                requireRegistrationCode,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            await api_financial.post(`/api/v1/financial/associations/${created?.data?.data[0]?.id}`, {
                useFinancialUsersBalance: [true, false].includes(configFinancial?.useFinancialUsersBalance) ? configFinancial?.useFinancialUsersBalance : undefined,
                useAutoRenewBalance: [true, false].includes(configFinancial?.useAutoRenewBalance) ? configFinancial?.useAutoRenewBalance : undefined,
                autoRenewPeriod: configFinancial?.autoRenewPeriod || undefined,
                autoRenewMonth: configFinancial?.autoRenewMonth ? Number(configFinancial?.autoRenewMonth) : undefined,
                autoRenewDay: configFinancial?.autoRenewDay ? Number(configFinancial?.autoRenewDay) : undefined,
                autoRenewResetBalance: [true, false].includes(configFinancial?.autoRenewResetBalance) ? configFinancial?.autoRenewResetBalance : undefined,
                balanceType: configFinancial?.balanceType || undefined,
                balanceValue: configFinancial?.balanceValue ? Number(configFinancial?.balanceValue) : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Associação criada com sucesso!")
            navigate(-1)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                if (error) {
                    return setShowNotificationModalText(responseError(error))
                }
                if (activeTab === 1) {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Financeiro".')
                } else {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Dados".')
                }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className='p-4 flex flex-col items-center justify-center gap-2'>
                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>
                        Já existem serviços cadastrados no sistema, lembre-se adicionar valores para esta nova associação após a criação
                    </p>
                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>
                        Deseja continuar?
                    </p>
                    <div className='flex flex-row w-full gap-2 items-center justify-center'>
                        <Button onClick={() => handleSubmit()} shadow={true}>Cadastrar</Button>
                        <Button approval={false} onClick={() => setShowUniversalModal(false)} shadow={true}>Cancelar</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Cadastro de associação'}></Title>
                </TitlePage>
                <Body hasFooter={true}>

                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='w-full h-full'>
                                {
                                    userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3) &&
                                    <div className='w-1/4 h-8 flex flex-row items-start justify-start border border-gray-300/50 mb-6'>
                                        <a
                                            onClick={() => setActiveTab(1)}
                                            className={`
                                                    cursor-pointer transition-all ease-in duration-200 text-sm
                                                    w-1/2 h-full ${activeTab === 1 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                    font-semibold flex flex-col items-center justify-center
                                                `}
                                        >
                                            Dados
                                        </a>
                                        <a
                                            onClick={() => setActiveTab(2)}
                                            className={`
                                                    cursor-pointer transition-all ease-out duration-200 text-sm
                                                    w-1/2 h-full ${activeTab === 2 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                    font-semibold flex flex-col items-center justify-center
                                                `}
                                        >
                                            Financeiro
                                        </a>
                                    </div>
                                }
                                {
                                    activeTab === 1 ?
                                        <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                            <LabelInput text={'Nome: *'}>
                                                <Input id='name' type={'text'} value={name} onChange={(e) => setName(e.target.value)}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Descrição: *'}>
                                                <Input id='description' type={'text'} value={description} onChange={(e) => setDescription(e.target.value)}></Input>
                                            </LabelInput>
                                            <Tippy content={'Mantenha ativado caso esta associação possa ver os convênios disponíveis no sistema'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'>
                                                <div>
                                                    <LabelInput text={'Acesso a convênios'}>
                                                        <Toogle value={agreementsAccess} status={agreementsAccess} onClick={() => setAgreementsAccess(!agreementsAccess)} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>
                                            <Tippy content={'Ative caso esta associação exija que no cadastro do usuário haja matrícula'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'>
                                                <div>
                                                    <LabelInput text={'Exige matrícula'}>
                                                        <Toogle value={requireRegistrationCode} status={requireRegistrationCode} onClick={() => setRequireRegistrationCode(!requireRegistrationCode)} />
                                                    </LabelInput>
                                                </div>
                                            </Tippy>
                                        </form> :
                                        <div className='w-full items-start justify-start flex flex-col gap-4'>
                                            <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                                <div className='flex flex-row flex-wrap gap-4 w-full items-start justify-start'>
                                                    <div className='flex flex-col items-start justify-start'>
                                                        <LabelInput text={'Controla saldo de usuários: *'}>
                                                            <InputAutoComplete
                                                                id={'useFinancialUsersBalance'}
                                                                preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useFinancialUsersBalance)?.label || ''}
                                                                data={boolOptions}
                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, useFinancialUsersBalance: e?.value }))}
                                                                optionList={['label']}
                                                                selectedLabel={'label'}
                                                            />
                                                        </LabelInput>
                                                    </div>
                                                    {
                                                        configFinancial?.useFinancialUsersBalance &&
                                                        <>
                                                            <div className='flex flex-col items-start justify-start'>
                                                                <LabelInput text={'Automatizar renovação de saldos: *'}>
                                                                    <InputAutoComplete
                                                                        id={'useAutoRenewBalance'}
                                                                        preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useAutoRenewBalance)?.label || ''}
                                                                        data={boolOptions}
                                                                        onChange={(e) => setConfigFinancial(prev => ({ ...prev, useAutoRenewBalance: e?.value }))}
                                                                        optionList={['label']}
                                                                        selectedLabel={'label'}
                                                                    />
                                                                </LabelInput>
                                                            </div>
                                                            {
                                                                configFinancial?.useAutoRenewBalance &&
                                                                <>
                                                                    <LabelInput text={'Período de renovação: *'}>
                                                                        <InputAutoComplete
                                                                            id={'autoRenewPeriod'}
                                                                            preSelectedValue={renewPeriodOptions.find(op => op?.value === configFinancial?.autoRenewPeriod)?.label || ''}
                                                                            data={renewPeriodOptions}
                                                                            onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewPeriod: e?.value }))}
                                                                            optionList={['label']}
                                                                            selectedLabel={'label'}
                                                                        />
                                                                    </LabelInput>
                                                                    {
                                                                        configFinancial?.autoRenewPeriod === "year" &&
                                                                        <>
                                                                            <LabelInput text={'Informe o mês: *'}>
                                                                                <Input
                                                                                    id='autoRenewMonth'
                                                                                    charLimit={2}
                                                                                    placeholder={'Ex: 1'}
                                                                                    max={12}
                                                                                    type={'number'}
                                                                                    value={configFinancial?.autoRenewMonth || ''}
                                                                                    onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewMonth: maxNumber(12, e.target.value) }))}
                                                                                ></Input>
                                                                            </LabelInput>
                                                                        </>
                                                                    }

                                                                    <LabelInput text={'Informe o dia: *'}>
                                                                        <Input
                                                                            id='autoRenewDay'
                                                                            charLimit={2}
                                                                            placeholder={'Ex: 1'}
                                                                            type={'number'}
                                                                            value={configFinancial?.autoRenewDay || ''}
                                                                            onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewDay: maxNumber(31, e.target.value) }))}
                                                                        ></Input>
                                                                    </LabelInput>
                                                                    <div className='flex sm:w-auto gap-1 relative'>
                                                                        <LabelInput text={'Resetar saldo na renovação: *'}>
                                                                            <InputAutoComplete
                                                                                id={'autoRenewResetBalance'}
                                                                                preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.autoRenewResetBalance)?.label || ''}
                                                                                data={boolOptions}
                                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewResetBalance: e?.value }))}
                                                                                optionList={['label']}
                                                                                selectedLabel={'label'}
                                                                            />
                                                                        </LabelInput>
                                                                        <Tippy content={'Informe "Não" para que o saldo seja acumulado ou "Sim" para que na próxima renovação o saldo anterior seja zerado.'}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='top'
                                                                            delay={100}
                                                                        >
                                                                            <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                                                <FaInfo />
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                </>
                                                            }

                                                            <LabelInput text={'Saldo: *'}>
                                                                <InputAutoComplete
                                                                    id={'balanceType'}
                                                                    preSelectedValue={balanceTypeOptions.find(op => op?.value === configFinancial?.balanceType)?.label || ''}
                                                                    data={balanceTypeOptions}
                                                                    onChange={(e) => setConfigFinancial(prev => ({ ...prev, balanceType: e?.value }))}
                                                                    optionList={['label']}
                                                                    selectedLabel={'label'}
                                                                />
                                                            </LabelInput>
                                                            {
                                                                configFinancial?.balanceType !== "percentageOfSalary"
                                                                    ?
                                                                    <LabelInput text={'Valor do saldo: *'}>
                                                                        <CurrencyInput
                                                                            id={`balanceValue`}
                                                                            className='pl-2 border border-gray-400 border-opacity-75 
                                                                            outline-none
                                                                            sm:w-96 w-full
                                                                            text-sm sm:text-sm
                                                                            transition-all duration-200
                                                                            focus:shadow-borderShadow
                                                                            h-10 sm:h-8
                                                                            dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                                            placeHolder={configFinancial.balanceValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configFinancial.balanceValue) : 'R$ 0,00'}
                                                                            onChange={(e) => {
                                                                                setConfigFinancial(prev => ({ ...prev, balanceValue: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                                            }}
                                                                        />
                                                                    </LabelInput> :
                                                                    <LabelInput text={'Valor do saldo: (%) *'}>
                                                                        <Input
                                                                            id='balanceValue'
                                                                            charLimit={4}
                                                                            placeholder={'Ex: 1'}
                                                                            type={'number'}
                                                                            value={configFinancial?.balanceValue || ''}
                                                                            onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, balanceValue: maxNumber(100, e.target.value) }))}
                                                                        ></Input>
                                                                    </LabelInput>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/associations')}>Cancelar</Button>
                        <Button shadow={true} approval={true} onClick={() => {
                            if (servicesList?.length > 0) {
                                setShowUniversalModal(true)
                            } else {
                                handleSubmit()
                            }
                        }}>Cadastrar</Button>
                    </div>
                </Footer>
            </Container>
        </>
    )
}
