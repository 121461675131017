// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Footer } from '../../../components/footer/Footer'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { mCPF } from '../../../utils/validators/cpfMask'
import { validateEmail } from '../../../utils/validators/email.validator'
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { v4 as uuid } from 'uuid'
//services
import { FaEdit, FaInfo, FaMinus, FaPlus } from 'react-icons/fa'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { maskCpf } from '../../../utils/mask/maskCpf'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'
import { maxNumber } from '../../../utils/mask/maxNumber'

export function ClientsCreate() {
    const { api_auth, api_financial, defaultCallsAuth } = useApiContext()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [selectedAssociate, setSelectedAssociate] = useState()
    const [associationsList, setAssociationsList] = useState([])
    const [companiesList, setCompaniesList] = useState([])
    const [customersData, setCustomersData] = useState([])
    const [loading, setLoading] = useState(false)
    const [userDependents, setUserDependents] = useState({})
    const [newDependent, setNewDependent] = useState({})
    const [showNewDependent, setShowNewDependent] = useState(false)
    const [newDependentId, setNewDependentId] = useState('')
    const [userDependentsKeys, setUserDependentsKeys] = useState([])
    const [activeTab, setActiveTab] = useState(1)

    const [selectedClientName, setSelectedClientName] = useState('')
    const [selectedClientSubname, setSelectedClientSubname] = useState('')
    const [selectedClientEmail, setSelectedClientEmail] = useState('')
    const [selectedClientBirthday, setSelectedClientBirthday] = useState('')
    const [selectedClientCPF, setSelectedClientCPF] = useState('')
    const [selectedClientGender, setSelectedClientGender] = useState('')
    const [selectedClientDDDPhone, setSelectedClienDDDPhone] = useState('')
    const [selectedClientPhone, setSelectedClientPhone] = useState('')
    const [selectedClientDDDCell, setSelectedClientDDDCell] = useState('')
    const [selectedClientCell, setSelectedClientCell] = useState('')
    const [requireRegistrationCode, setRequireRegistrationCode] = useState(false)
    const [registrationCode, setRegistrationCode] = useState()
    const [selectedCompany, setSelectedCompany] = useState('')
    const [salary, setSalary] = useState(0)

    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        },
    ]

    // config financial
    const [configFinancial, setConfigFinancial] = useState({
        useDefaultAssociationConfigBalance: true,
        useFinancialUsersBalance: false,
        useAutoRenewBalance: false,
        autoRenewPeriod: '',
        autoRenewMonth: '',
        autoRenewDay: '',
        autoRenewResetBalance: false,
        balanceType: '',
        balanceValue: ''
    })
    const boolOptions = [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
    ]
    const renewPeriodOptions = [
        { value: 'month', label: 'Mensal' },
        { value: 'year', label: 'Anual' },
    ]
    const balanceTypeOptions = [
        { value: "fixed", label: "Fixo" },
        { value: "percentageOfSalary", label: "Porcentagem do salário" },
    ]

    useEffect(() => {

        setLoading(true)
        async function getData() {

            const associationsGet = await defaultCallsAuth.getAssociations(userData[0]?.token, 1)
            const customersDataAPI = await defaultCallsAuth.getCustomers(userData[0].token)
            const companiesData = await defaultCallsAuth.getCompanies(userData[0].token)
            if (companiesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(companiesData.data)
            }
            setCompaniesList(companiesData.data)

            if (customersData.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(customersData.data)
                return
            }

            if (associationsGet.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(associationsGet.data)
                return
            }
            setShowNotificationModalSuccess(true)
            setCustomersData(customersDataAPI)
            if (!customersDataAPI?.data?.openToCommunity) {
                setAssociationsList(associationsGet.data.filter(association => association.id !== 1))
            } else {
                setAssociationsList(associationsGet.data)
            }

            setLoading(false)
        }

        getData()

    }, [])

    async function handleSubmit() {
        let message = 'Sucesso ao cadastrar usuário'

        const fields = [
            { "name": 'selectedClientName', "value": selectedClientName, "required": true, "type": 'string' },
            { "name": 'selectedClientSubname', "value": selectedClientSubname, "required": true, "type": 'string' },
            { "name": 'selectedClientEmail', "value": selectedClientEmail, "required": true, "type": 'string' },
            { "name": 'selectedClientBirthday', "value": selectedClientBirthday, "required": true, "type": 'string' },
            { "name": 'selectedClientCPF', "value": selectedClientCPF, "required": false, "type": 'string' },
            { "name": 'selectedClientGender', "value": selectedClientGender, "required": true, "type": 'string' },
            { "name": 'selectedClientCell', "value": selectedClientCell, "required": true, "type": 'string' },
            { "name": 'selectedClientDDDCell', "value": selectedClientDDDCell, "required": true, "type": 'string' },
            { "name": 'selectedAssociate', "value": selectedAssociate, "required": true, "type": 'object' },
        ]
        if (configFinancial?.useFinancialUsersBalance && configFinancial?.balanceType == 'percentageOfSalary') {
            fields.push({ "name": 'selectedSalary', "value": salary, "required": true, "type": 'number' })
        }

        if (requireRegistrationCode) {
            fields.push({ "name": 'registrationCode', "value": registrationCode, "required": true, "type": 'string' })
        }

        if (userData[0].customersData?.requireCpf) {
            fields.push({ "name": 'selectedClientCPF', "value": selectedClientCPF, "required": true, "type": 'string' })
        }

        if (validateFields(fields)?.length > 0) {
            console.log('aqui desgraça2')
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos na aba de "Dados", preencha os campos em vermelho')
        }

        setLoading(true)
        if (!validateEmail(selectedClientEmail)) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }

        if (selectedClientName?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o nome, deve ser nome inteiro')
        }

        if (selectedClientSubname?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o sobrenome, deve ser nome inteiro')
        }

        const configFinancialFields = []
        let currentTab = activeTab

        if (!configFinancial?.useDefaultAssociationConfigBalance && configFinancial.useFinancialUsersBalance) {
            configFinancialFields.push(...[
                { "name": 'autoRenewDay', "value": configFinancial?.autoRenewDay, "required": configFinancial?.useAutoRenewBalance ? true : false, "type": 'string' },
                { "name": 'balanceValue', "value": configFinancial?.balanceValue, "required": true, "type": 'string' },
                { "name": 'balanceType', "value": configFinancial?.balanceValue, "required": true, "type": 'string' },
                { "name": 'autoRenewPeriod', "value": configFinancial?.autoRenewPeriod, "required": configFinancial.useAutoRenewBalance ? true : false, "type": 'string' },
                { "name": 'salaryFinancial', "value": salary, "required": (!configFinancial.useDefaultAssociationConfigBalance && configFinancial.balanceType === "percentageOfSalary") ? true : false, "type": 'number' },
            ])
        }

        if (configFinancial?.autoRenewPeriod === "year") {
            configFinancialFields.push({ "name": 'autoRenewMonth', "value": configFinancial?.autoRenewMonth, "required": true, "type": 'string' },)
        }

        if (validateFields(configFinancialFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos da aba "Financeiro"')
        }

        try {
            const newUser = await api_auth.post('api/v1/auth/users', {
                birthday: selectedClientBirthday,
                email: selectedClientEmail,
                firstName: selectedClientName,
                lastName: selectedClientSubname,
                typeAccess_id: 4,
                cpf: selectedClientCPF !== '' ? removeEspecialChar(selectedClientCPF) : undefined,
                cell: selectedClientCell !== '' ? selectedClientCell : undefined,
                dddcell: selectedClientDDDCell !== '' ? selectedClientDDDCell : undefined,
                gender: selectedClientGender !== '' ? selectedClientGender.id : undefined,
                companies_id: selectedCompany?.id ? selectedCompany?.id : undefined,
                permissionGroups_id: undefined,
                dddphone: selectedClientDDDPhone !== '' ? selectedClientDDDPhone : undefined,
                phone: selectedClientPhone !== '' ? selectedClientPhone : undefined,
                associations_id: selectedAssociate?.id,
                registrationCode: registrationCode ? registrationCode : undefined,
                salary: salary > 0 ? salary : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            const dependents = Object.keys(userDependents)?.map(dependent => {
                let cpf = userDependents[dependent]?.cpf?.length === 0 ? undefined : removeEspecialChar(userDependents[dependent]?.cpf)
                return {
                    users_id: newUser?.data.data[0]?.id,
                    ...userDependents[dependent],
                    cpf: cpf
                }
            })

            if (dependents?.length > 0) {
                try {
                    await api_auth.post(`/api/v1/auth/usersDependents`, {
                        dependents
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`
                        }
                    })
                } catch (error) {
                    if (error.response?.status === 403) {
                        message = 'Sucesso ao cadastrar usuario, porém não foi possivel cadastrar os dependentes'
                    }
                    console.log(error)
                }
            }

            try {
                const financialUserBodyData = {
                    useDefaultAssociationConfigBalance: configFinancial?.useDefaultAssociationConfigBalance,
                    useFinancialUsersBalance: configFinancial?.useFinancialUsersBalance,
                }
                if (financialUserBodyData.useFinancialUsersBalance) {
                    financialUserBodyData.useAutoRenewBalance = configFinancial?.useAutoRenewBalance,
                        financialUserBodyData.autoRenewPeriod = configFinancial?.autoRenewPeriod || undefined
                    financialUserBodyData.autoRenewMonth = configFinancial?.autoRenewMonth ? Number(configFinancial.autoRenewMonth) : undefined
                    financialUserBodyData.autoRenewDay = configFinancial?.autoRenewDay ? Number(configFinancial.autoRenewDay) : undefined
                    financialUserBodyData.autoRenewResetBalance = configFinancial?.autoRenewResetBalance
                    financialUserBodyData.balanceType = configFinancial?.balanceType || undefined
                    financialUserBodyData.balanceValue = configFinancial?.balanceValue ? Number(configFinancial.balanceValue) : undefined
                }
                await api_financial.post(`/api/v1/financial/users/${newUser?.data.data[0]?.id}`, financialUserBodyData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            } catch (error) {
                console.log(error)
            }

            navigate('/clients')
            setShowNotificationModal(true)
            setShowNotificationModalText(message)
            setShowNotificationModalSuccess(true)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                if (error?.status === 409) {
                    return setShowNotificationModalText(responseError(error))
                }
                if (activeTab === 1) {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Financeiro".')
                } else {
                    return setShowNotificationModalText('Verifique os campos informados na aba "Dados".')
                }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)

    }
    //cria o primeiro item na lista de dependentes quando esta vazia
    function handleNewDependent() {
        setShowNotificationModal(false)
        if (Object.keys(userDependents).length === 0) {
            let id = uuid()
            setNewDependentId(id)
            setNewDependent(prev => ({
                [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            }))
            setUserDependentsKeys(prev => (Object.keys({
                [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            })))
        }
        setShowNewDependent(true)
    }
    //Exibe a edicao de dependentes
    function handleChangeShowNewDependentView() {
        setNewDependent({})
        setShowNotificationModal(false)
        setNewDependent(userDependents)
        setUserDependentsKeys(Object.keys(userDependents))
        setShowNewDependent(false)
    }
    //salva a listagem para envio na API
    function handleConfirmNewDependents() {
        if (validDependentFields()) {
            setNewDependentId('')
            setUserDependents(newDependent)
            setShowNewDependent(false)
        }
    }
    //funcao para validar os campos dos dependentes
    function validDependentFields() {
        const list = Object.keys(newDependent)
        let validFields = true

        if (list?.length > 0) {
            list.map(key => {
                if (!newDependent[key]?.fullName || newDependent[key]?.fullName?.trim() === '' || newDependent[key]?.fullName?.length <= 3) {
                    document.getElementById(`${key}-fullName`).style.border = '1px solid red'
                    validFields = false
                } else {
                    document.getElementById(`${key}-fullName`).style.border = ''
                }
                if (!newDependent[key]?.birthday) {
                    document.getElementById(`${key}-birthday`).style.border = '1px solid red'
                    validFields = false
                } else {
                    document.getElementById(`${key}-birthday`).style.border = ''
                }
                if (!newDependent[key]?.degreeOfKinship) {
                    document.getElementById(`${key}-degreeOfKinship`).style.border = '1px solid red'
                    validFields = false
                } else {
                    document.getElementById(`${key}-degreeOfKinship`).style.border = ''
                }
            })
        }

        if (!validFields) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Campos obrigatorios do dependente não preenchido')
            return false
        }
        setShowNotificationModal(false)
        return true
    }
    //adiciona dependente na listagem (em edicao)
    async function handleNewDependentInList() {

        let id = uuid()
        if (validDependentFields()) {
            setNewDependent({
                ...newDependent, [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            })
            setUserDependentsKeys(Object.keys({
                ...newDependent, [id]: {
                    fullName: '',
                    cpf: '',
                    birthday: '',
                    degreeOfKinship: ''
                }
            }))
        }
    }
    //remove o dependente da lista
    function handleRemoveDependent(key) {
        let dependentsToRemove = newDependent
        delete dependentsToRemove[key]
        setNewDependent({ ...dependentsToRemove })
        setUserDependentsKeys(Object.keys(dependentsToRemove))
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Cadastro de clientes'} />
            </TitlePage>
            <Body hasFooter={true}>
                <div className='flex flex-col items-start justify-center w-full gap-2 '>
                    {
                        userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3) &&
                        <div className='w-1/4 h-8 flex flex-row items-start justify-start border border-gray-300/50 mb-6'>
                            <a
                                onClick={() => setActiveTab(1)}
                                className={`
                                        cursor-pointer transition-all ease-in duration-200 text-sm
                                        w-1/2 h-full ${activeTab === 1 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                        font-semibold flex flex-col items-center justify-center
                                    `}
                            >
                                Dados
                            </a>
                            <a
                                onClick={() => setActiveTab(2)}
                                className={`
                                        cursor-pointer transition-all ease-out duration-200 text-sm
                                        w-1/2 h-full ${activeTab === 2 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                        font-semibold flex flex-col items-center justify-center
                                    `}
                            >
                                Financeiro
                            </a>
                        </div>
                    }
                    {
                        activeTab === 1 ?
                            <>
                                <form className='flex flex-col justify-center items-center lg:grid lg:grid-cols-2 gap-4 p-1'>
                                    <LabelInput text={'Nome *'}>
                                        <Input autoFocus={true} id='selectedClientName' value={selectedClientName} type='text' onChange={(e) => setSelectedClientName(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Sobrenome *'}>
                                        <Input id='selectedClientSubname' value={selectedClientSubname} type='text' onChange={(e) => setSelectedClientSubname(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Genêro *'}>
                                        <InputAutoComplete
                                            data={sexTypes}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            placeHolder=' - '
                                            onChange={value => setSelectedClientGender(value)}
                                            id='selectedClientGender'
                                        />
                                    </LabelInput>
                                    <LabelInput text={'E-mail *'}>
                                        <Input id='selectedClientEmail' value={selectedClientEmail} type='e-mail' onChange={(e) => setSelectedClientEmail(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={`CPF ${userData[0].customersData?.requireCpf ? '*' : ''}`}>
                                        <Input id='selectedClientCPF' charLimit={14} value={mCPF(selectedClientCPF)} type='text' onChange={(e) => setSelectedClientCPF(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Data de nascimento *'}>
                                        <Input id='selectedClientBirthday' value={selectedClientBirthday} type='date' onChange={(e) => setSelectedClientBirthday(e.target.value)}></Input>
                                    </LabelInput>
                                    <div className='flex flex-col sm:flex-row gap-4'>
                                        <LabelInput text={'DDD'}>
                                            <Input id='selectedClientDDDPhone' charLimit={2} value={selectedClientDDDPhone} width={12} type='text' onChange={(e) => setSelectedClienDDDPhone(justNumber(e.target.value))}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input
                                                id='selectedClientPhone'
                                                charLimit={9}
                                                value={selectedClientPhone ? maskPhone(String(selectedClientPhone)) : ''}
                                                width={80}
                                                type='text'
                                                onChange={(e) => setSelectedClientPhone(justNumber(e.target.value))}
                                            ></Input>
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-col sm:flex-row gap-4'>
                                        <LabelInput text={'DDD *'}>
                                            <Input charLimit={2} id='selectedClientDDDCell' value={selectedClientDDDCell} width={12} type='phone' onChange={(e) => setSelectedClientDDDCell(justNumber(e.target.value))}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Celular *'}>
                                            <Input charLimit={9} id='selectedClientCell' value={selectedClientCell} width={80} type='phone' onChange={(e) => setSelectedClientCell(justNumber(e.target.value))}></Input>
                                        </LabelInput>
                                    </div>
                                    <LabelInput text={'Tipo / Associação *'}>
                                        <InputAutoComplete
                                            data={associationsList}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            placeHolder=' - '
                                            onChange={value => {
                                                if (value.requireRegistrationCode) {
                                                    setRequireRegistrationCode(true)
                                                } else {
                                                    setRequireRegistrationCode(false)
                                                }
                                                setSelectedAssociate(value)
                                            }}
                                            id='selectedAssociate'
                                        />
                                    </LabelInput>
                                    <LabelInput text={`Matrícula ${requireRegistrationCode ? '*' : ''}`}>
                                        <Input charLimit={255} id='registrationCode' type='text' value={registrationCode} onChange={(e) => setRegistrationCode(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Salário'}>
                                        <CurrencyInput
                                            id='selectedSalary'
                                            placeHolder='R$ 0,00'
                                            onChange={(e) => {
                                                setSalary(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                            }}
                                        />
                                    </LabelInput>
                                </form>
                                <div className='flex flex-col w-full mt-8'>
                                    <h1 className='text-primaryDefaultLight text-base'>Dependentes</h1>
                                    {
                                        !showNewDependent ?
                                            <div className='flex flex-row w-full mb-4 items-center justify-center gap-2'>
                                                <div className="flex flex-row items-start justify-start w-full">
                                                    <Tippy content={<p>{Object.keys(newDependent)?.length === 0 ? 'Novo dependente' : 'Editar/adicionar'}</p>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div onClick={() => handleNewDependent()} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                            <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            </div>
                                            :
                                            <div className='flex flex-row w-full mb-4 items-center justify-center gap-2'>
                                                <div className="flex flex-row items-start justify-start w-full">
                                                    <Tippy content={<p>Salve e posterior clique em editar usuário para gravar</p>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div className="p-2 hover:bg-gray-100 rounded-md flex flex-row gap-2 items-center justify-center">
                                                            {!showNewDependent ? <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                                :
                                                                <FaEdit className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />}
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Editando</a>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            </div>
                                    }
                                    {
                                        showNewDependent &&
                                        <div className='flex flex-col gap-2 w-full items-start justify-start'>
                                            <div className='w-full flex gap-2 flex-wrap'>
                                                {
                                                    userDependentsKeys.map(key => {
                                                        return <>
                                                            <div className='hidden sm:flex w-full gap-2'>
                                                                <LabelInput text={'Nome completo'}>
                                                                    <Input id={`${key}-fullName`} width={56} value={newDependent[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                                </LabelInput>
                                                                <LabelInput text={'CPF'}>
                                                                    <Input id={`${key}-cpf`} charLimit={14} width={32} value={newDependent[key]?.cpf ? maskCpf(String(newDependent[key]?.cpf)) : ''} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                                </LabelInput>
                                                                <LabelInput text={'Data de nascimento'}>
                                                                    <Input id={`${key}-birthday`} width={32} value={newDependent[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                                </LabelInput>
                                                                <LabelInput text={'Grau de parentesco'}>
                                                                    <InputAutoComplete id={`${key}-degreeOfKinship`} width={56} preSelectedValue={newDependent[key]?.degreeOfKinship} data={[
                                                                        { id: 0, description: 'Filho/Filha' },
                                                                        { id: 1, description: 'Marido/Esposa' },
                                                                    ]} selectedLabel={'description'} optionList={['description']} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], degreeOfKinship: e.description } }))} />
                                                                </LabelInput>
                                                                <LabelInput text={'Adicionar'}>
                                                                    <div onClick={() => handleNewDependentInList()} className='cursor-pointer flex h-full items-center justify-center'>
                                                                        <FaPlus className='dark:text-titleGrayTextDark' />
                                                                    </div>
                                                                </LabelInput>
                                                                <LabelInput text={'Remover'}>
                                                                    <div onClick={() => handleRemoveDependent(key)} className='cursor-pointer flex h-full items-center justify-center'>
                                                                        <FaMinus className='dark:text-titleGrayTextDark' />
                                                                    </div>
                                                                </LabelInput>
                                                            </div>
                                                            <div className='mb-2 flex w-full flex-col sm:hidden border border-zinc-200 dark:border-thirdDefaultDark p-1 rounded-lg bg-zinc-50 dark:bg-thirdDefaultDark'>
                                                                <LabelInput text={'Nome completo'}>
                                                                    <Input value={newDependent[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                                </LabelInput>
                                                                <LabelInput text={'CPF'}>
                                                                    <Input charLimit={14} value={newDependent[key]?.cpf ? maskCpf(String(newDependent[key]?.cpf)) : ''} type={'text'} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                                </LabelInput>
                                                                <LabelInput text={'Data de nascimento'}>
                                                                    <Input value={newDependent[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                                </LabelInput>
                                                                <LabelInput text={'Grau de parentesco'}>
                                                                    <InputAutoComplete preSelectedValue={newDependent[key]?.degreeOfKinship} data={[
                                                                        { id: 0, description: 'Filho/Filha' },
                                                                        { id: 1, description: 'Marido/Esposa' },
                                                                    ]} selectedLabel={'description'} optionList={['description']} onChange={(e) => setNewDependent(prev => ({ [key]: { ...prev[key], degreeOfKinship: e.description } }))} />
                                                                </LabelInput>
                                                                <div className='my-4 sm:my-0 w-full gap-2 flex items-start justify-start'>
                                                                    <LabelInput text={'Adicionar'}>
                                                                        <div onClick={() => handleNewDependentInList()} className='p-2 border border-zinc-200 rounded-lg cursor-pointer flex h-full items-start justify-start'>
                                                                            <FaPlus />
                                                                        </div>
                                                                    </LabelInput>
                                                                    <LabelInput text={'Remover'}>
                                                                        <div onClick={() => handleRemoveDependent(key)} className='p-2 border border-zinc-200 rounded-lg cursor-pointer flex h-full items-start justify-start'>
                                                                            <FaMinus />
                                                                        </div>
                                                                    </LabelInput>
                                                                </div>
                                                            </div>
                                                        </>
                                                    })
                                                }
                                            </div>
                                            <div className='mt-4 flex gap-2'>
                                                <Button shadow={true} approval={true} onClick={() => handleConfirmNewDependents()}>Salvar</Button>
                                                <Button shadow={true} approval={false} onClick={() => handleChangeShowNewDependentView()}>Cancelar</Button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !showNewDependent ?
                                            Object.keys(userDependents).length === 0 ?
                                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Nenhum depentende cadastrado.</a>
                                                :
                                                Object.keys(userDependents)?.map(key => {
                                                    return <>
                                                        <div className='hidden sm:flex w-full gap-2'>
                                                            <LabelInput text={'Nome completo'}>
                                                                <Input disabled={true} width={56} value={newDependent[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                            </LabelInput>
                                                            <LabelInput text={'CPF'}>
                                                                <Input charLimit={14} disabled={true} width={32} value={newDependent[key]?.cpf ? maskCpf(String(newDependent[key]?.cpf)) : ''} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                            </LabelInput>
                                                            <LabelInput text={'Data de nascimento'}>
                                                                <Input disabled={true} width={32} value={newDependent[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                            </LabelInput>
                                                            <LabelInput text={'Grau de parentesco'}>
                                                                <Input width={56} disabled={true} value={newDependent[key]?.degreeOfKinship} />
                                                            </LabelInput>
                                                        </div>
                                                        <div className='mb-4 flex flex-col sm:hidden w-full gap-2 p-1 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-200 dark:border-primaryBorderDark'>
                                                            <LabelInput text={'Nome completo'}>
                                                                <Input disabled={true} value={newDependent[key]?.fullName} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], fullName: e.target.value } }))}></Input>
                                                            </LabelInput>
                                                            <LabelInput text={'CPF'}>
                                                                <Input charLimit={14} disabled={true} value={newDependent[key]?.cpf ? maskCpf(String(newDependent[key]?.cpf)) : ''} type={'text'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], cpf: e.target.value } }))}></Input>
                                                            </LabelInput>
                                                            <LabelInput text={'Data de nascimento'}>
                                                                <Input disabled={true} value={newDependent[key]?.birthday} type={'date'} onChange={(e) => setNewDependent(prev => ({ ...prev, [key]: { ...prev[key], birthday: e.target.value } }))}></Input>
                                                            </LabelInput>
                                                            <LabelInput text={'Grau de parentesco'}>
                                                                <Input disabled={true} value={newDependent[key]?.degreeOfKinship} />
                                                            </LabelInput>
                                                        </div>
                                                    </>
                                                })
                                            :
                                            <></>
                                    }
                                </div>
                                <div className='flex flex-row w-full mt-8 gap-4'>
                                    <div>
                                        <h1 className='text-primaryDefaultLight text-base'>Empresa vinculada</h1>
                                        <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                            <InputAutoComplete
                                                data={companiesList}
                                                preSelectedValue={selectedCompany?.name}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                                onChange={selectedCompany => setSelectedCompany(selectedCompany)}
                                                id='selectedCompany'
                                            />
                                        </label>
                                    </div>
                                </div>
                            </> :
                            <div className='w-full items-start justify-start flex flex-col gap-4'>
                                <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                    <div className='flex flex-row flex-wrap gap-4 w-full items-start justify-start'>
                                        <div className='flex sm:w-auto gap-1 relative'>
                                            <LabelInput text={'Usa configuração de saldo da associação: *'}>
                                                <InputAutoComplete
                                                    id={'useDefaultAssociationConfigBalance'}
                                                    preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useDefaultAssociationConfigBalance)?.label || ''}
                                                    data={boolOptions}
                                                    onChange={(e) => setConfigFinancial(prev => ({ ...prev, useDefaultAssociationConfigBalance: e?.value }))}
                                                    optionList={['label']}
                                                    selectedLabel={'label'}
                                                />
                                            </LabelInput>
                                            <Tippy content={'Informe "Não" para que a associação não possa fazer movimentação de saldo para este cliente ou "Sim" para que seja possível.'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}
                                            >
                                                <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                    <FaInfo />
                                                </div>
                                            </Tippy>
                                        </div>
                                        {
                                            !configFinancial?.useDefaultAssociationConfigBalance &&
                                            <>
                                                <div className='flex sm:w-auto gap-1 relative'>
                                                    <LabelInput text={'Utilizará saldo:'}>
                                                        <InputAutoComplete
                                                            id={'useFinancialUsersBalance'}
                                                            preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useFinancialUsersBalance)?.label || ''}
                                                            data={boolOptions}
                                                            onChange={(e) => setConfigFinancial(prev => ({ ...prev, useFinancialUsersBalance: e?.value }))}
                                                            optionList={['label']}
                                                            selectedLabel={'label'}
                                                        />
                                                    </LabelInput>
                                                    <Tippy content={'Informe "Não" para que não seja possível lançar um saldo a parte para este cliente ou "Sim" para que seja possível.'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}
                                                    >
                                                        <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                            <FaInfo />
                                                        </div>
                                                    </Tippy>
                                                </div>
                                                {
                                                    configFinancial?.useFinancialUsersBalance &&
                                                    <>
                                                        <div className='flex flex-col items-start justify-start'>
                                                            <LabelInput text={'Automatizar renovação de saldos:'}>
                                                                <InputAutoComplete
                                                                    id={'useAutoRenewBalance'}
                                                                    preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.useAutoRenewBalance)?.label || ''}
                                                                    data={boolOptions}
                                                                    onChange={(e) => setConfigFinancial(prev => ({ ...prev, useAutoRenewBalance: e?.value }))}
                                                                    optionList={['label']}
                                                                    selectedLabel={'label'}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                        {
                                                            configFinancial?.useAutoRenewBalance &&
                                                            <>
                                                                <LabelInput text={'Período de renovação: *'}>
                                                                    <InputAutoComplete
                                                                        id={'autoRenewPeriod'}
                                                                        preSelectedValue={renewPeriodOptions.find(op => op?.value === configFinancial?.autoRenewPeriod)?.label || ''}
                                                                        data={renewPeriodOptions}
                                                                        onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewPeriod: e?.value }))}
                                                                        optionList={['label']}
                                                                        selectedLabel={'label'}
                                                                    />
                                                                </LabelInput>
                                                                {
                                                                    configFinancial?.autoRenewPeriod === "year" &&
                                                                    <>
                                                                        <LabelInput text={'Informe o mês: *'}>
                                                                            <Input
                                                                                id='autoRenewMonth'
                                                                                charLimit={2}
                                                                                placeholder={'Ex: 1'}
                                                                                type={'number'}
                                                                                value={configFinancial?.autoRenewMonth || ''}
                                                                                onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewMonth: maxNumber(12, e.target.value) }))}
                                                                            ></Input>
                                                                        </LabelInput>
                                                                    </>
                                                                }
                                                                {
                                                                    configFinancial?.useAutoRenewBalance &&

                                                                    <LabelInput text={'Informe o dia: *'}>
                                                                        <Input
                                                                            id='autoRenewDay'
                                                                            charLimit={2}
                                                                            placeholder={'Ex: 1'}
                                                                            type={'number'}
                                                                            value={configFinancial?.autoRenewDay || ''}
                                                                            onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, autoRenewDay: maxNumber(31, e.target.value) }))}
                                                                        ></Input>
                                                                    </LabelInput>
                                                                }
                                                                <div className='flex sm:w-auto gap-1 relative'>
                                                                    <LabelInput text={'Resetar saldo na renovação: '}>
                                                                        <InputAutoComplete
                                                                            id={'autoRenewResetBalance'}
                                                                            preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.autoRenewResetBalance)?.label || ''}
                                                                            data={boolOptions}
                                                                            onChange={(e) => setConfigFinancial(prev => ({ ...prev, autoRenewResetBalance: e?.value }))}
                                                                            optionList={['label']}
                                                                            selectedLabel={'label'}
                                                                        />
                                                                    </LabelInput>
                                                                    <Tippy content={'Informe "Não" para que o saldo seja acumulado ou "Sim" para que na próxima renovação o saldo anterior seja zerado.'}
                                                                        arrow={true}
                                                                        animation='shift-away'
                                                                        placement='top'
                                                                        delay={100}
                                                                    >
                                                                        <div className='absolute right-0 text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                                            <FaInfo />
                                                                        </div>
                                                                    </Tippy>
                                                                </div>
                                                            </>
                                                        }

                                                        <LabelInput text={'Saldo: *'}>
                                                            <InputAutoComplete
                                                                id={'balanceType'}
                                                                preSelectedValue={balanceTypeOptions.find(op => op?.value === configFinancial?.balanceType)?.label || ''}
                                                                data={balanceTypeOptions}
                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, balanceType: e?.value }))}
                                                                optionList={['label']}
                                                                selectedLabel={'label'}
                                                            />
                                                        </LabelInput>
                                                        {
                                                            configFinancial?.balanceType !== "percentageOfSalary"
                                                                ?
                                                                <LabelInput text={'Valor do saldo: *'}>
                                                                    <CurrencyInput
                                                                        id={`balanceValue`}
                                                                        className='pl-2 border border-gray-400 border-opacity-75 
                                                                                    outline-none
                                                                                    sm:w-96 w-full
                                                                                    text-sm sm:text-sm
                                                                                    transition-all duration-200
                                                                                    focus:shadow-borderShadow
                                                                                    h-10 sm:h-8
                                                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                                        placeHolder={configFinancial.balanceValue ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(configFinancial.balanceValue) : 'R$ 0,00'}
                                                                        onChange={(e) => {
                                                                            setConfigFinancial(prev => ({ ...prev, balanceValue: parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) }))
                                                                        }}
                                                                    />
                                                                </LabelInput> :
                                                                <LabelInput text={'Valor do saldo: (%) *'}>
                                                                    <Input
                                                                        id='balanceValue'
                                                                        charLimit={4}
                                                                        placeholder={'Ex: 1'}
                                                                        type={'number'}
                                                                        value={configFinancial?.balanceValue || ''}
                                                                        onChange={(e) => (e.target.value > 0 || !e.target.value) && setConfigFinancial(prev => ({ ...prev, balanceValue: maxNumber(100, e.target.value) }))}
                                                                    ></Input>
                                                                </LabelInput>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </Body>
            <Footer>
                <div className='px-2 sm:px-0 flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        onClick={() => navigate('/clients')}
                        shadow={true}
                        approval={false}
                    >Voltar
                    </Button>
                    <Button
                        shadow={true}
                        onClick={() => handleSubmit()}
                    >Confirmar
                    </Button>
                </div>
            </Footer>
        </Container>
    )
}
