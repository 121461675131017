
import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
//components
import { CalendarMain } from '../../../../components/calendar/calendar.main'
import { Body } from '../../../../components/container/Body'
import { Container } from '../../../../components/container/container'
import { Input } from '../../../../components/input/input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { LabelInput } from '../../../../components/label/label.input'
import { Stepper } from '../../../../components/stepper/stepper'
import { Title } from '../../../../components/titlePages/title'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import { TitlePage } from '../../../../components/titlePages/title.page'
import CurrencyInput from '../../../../components/input/inputCurrencyPtBr'
import { ScreenModal } from '../../../../components/modals/notification/screenModal'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import { Quillmodules } from '../../../../components/richText/quill.modules'
//context
import { useThemeContext } from '../../../../contexts/themeContext'
import { useAuth } from '../../../../contexts/useAuth'
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import { Button } from '../../../../components/buttons/button.default'
import { FaAngleDown, FaCalendar, FaCheck, FaCircleNotch, FaClock, FaCopy, FaListUl, FaMapMarkerAlt } from 'react-icons/fa'
import { HiBellAlert, HiBell } from "react-icons/hi2"
import { InputCheckBox } from '../../../../components/input/input.checkBox'
//imgs
import credit1 from '../../../../assets/logos/credit-1.webp'
import credit2 from '../../../../assets/logos/credit-2.webp'
import credit3 from '../../../../assets/logos/credit-3.webp'
import credit4 from '../../../../assets/logos/credit-4.webp'
import credit5 from '../../../../assets/logos/credit-5.webp'
import cardChip from '../../../../assets/reservation/chip.png'
import servicesImg from '../../../../assets/default_assets/soccer.jpeg'
//services
import { formattTimeToShow } from '../../../../services/api/callAPIsFunctions/defaultCalls.api'
import { SwiperContainer } from '../../../../components/swiper/swiper'
import { useNavigate, useParams } from 'react-router-dom'
import { Footer } from '../../../../components/footer/Footer'
import { currencyPtBr } from '../../../../utils/formatCurrency/currencyTextPTBR'
import { responseError } from '../../../../utils/responsesFunctions/error.response'
import { getCardToken } from '../../../../services/paymentAPIs/stone/stoneAPI'
import { justNumber } from '../../../../utils/validators/justNumber'
import { copyToClipboard } from '../../../../utils/copyClipboard'
import Timer from '../../../../components/timer/timer'
import { cpfIsValid } from '../../../../utils/validators/cpfIsValid'
//utils
import { maskCardNumber } from '../../../../utils/mask/maskCardNumber'
import { TableDefault } from '../../../../components/table/table.default'
import { TableMobile } from '../../../../components/table/table.mobile'
import { useSearchParams } from 'react-router-dom'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

export function ReservationClient() {

    const { defaultCallsSchedules, defaultCallsAuth, api } = useApiContext()
    const navigate = useNavigate()
    const paramsReservationPerLogin = useParams()
    const [preSelectedHours] = useSearchParams()
    const paramsPaymentId = useParams()
    const [value, setValue] = useState(moment().locale('pt-br'))
    const [loading, setLoading] = useState(true)
    const [loadingHours, setLoadingHours] = useState(true)
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [startPaymentSearch, setStartPaymentSearch] = useState(false)
    const [startFindPaymentStatus, setStartFindPaymentStatus] = useState(false)
    const [typeShowModal, setTypeShowModal] = useState('')
    const [showTermModal, setShowTermModal] = useState(false)

    const spaceOneRef = useRef()
    const spaceTwoRef = useRef()
    const spaceTreeRef = useRef()
    const spaceCategorieRef = useRef()

    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const { userData } = useAuth()
    const [typeModal, setTypeModal] = useState('')
    const [dontShowFooter, setDontShowFooter] = useState(false)
    const [myBalance, setMyBalance] = useState(0)
    const [myBalanceHistory, setMyBalanceHistory] = useState(0)
    const [filterdHours, setFilteredHours] = useState([])
    const [categories, setCategories] = useState([])
    const [services, setServices] = useState([])
    const [filteredServices, setFilteredServices] = useState([])
    const [servicePhotos, setServicesPhotos] = useState([])
    const [dontHavePaymentMethods, setDontHavePaymentMethods] = useState(false)
    const [showSpaceList, setShowSpaceList] = useState(false)
    const [userPermitted, setUserPermiited] = useState(false)
    const spaceState = useRef(showSpaceList)
    const [defaultTableOpenHours, setDefaultTableOpenHours] = useState([])
    const setMyState = data => {
        spaceState.current = data;
        setShowSpaceList(data);
    }
    const [showTableHours, setShowTableHours] = useState(false)
    const [animateSpace, setAnimateSpace] = useState({ one: false, two: false, tree: false })
    const [discount, setDiscount] = useState(0)
    const [totalValue, setTotalValue] = useState(0)
    const [termOfUseDescription, setTermsOfUseDescription] = useState('')

    const [selectedCategorie, setSelectedCategorie] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [serviceDaysToOpen, setServiceDaysToOpen] = useState('')
    const [selectedValue, setSelectedValue] = useState(0)
    const [selectedValueHistory, setSelectedValueHistory] = useState(0)
    const [selectedHour, setSelectedHour] = useState([])
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedObservation, setSelectedObservation] = useState('')
    const [selectedModality, setSelectedModality] = useState('')
    const [selectedMonth, setSelectedMonth] = useState('')
    const [serviceBlockedWeekdays, setServiceBlockedWeekdays] = useState([])
    const [selectedPayment, setSelectedPayment] = useState('')
    const [selectedTermsOfUse, setSelectedTermsfUse] = useState(false)
    const [areaCode, setAreaCode] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const [selectedCardNumber, setSelectedCardNumber] = useState('')
    const [selectedCardDueDateMonth, setSelectedCardDueDateMonth] = useState('')
    const [selectedCardDueDateYear, setSelectedCardDueDateYear] = useState('')
    const [selectedCardPersonName, setSelectedCardPersonName] = useState('')
    const [selectedCardCVVNumber, setSelectedCardCVVNumber] = useState('')
    const [userSelectedCPF, setUserSelectedCPF] = useState('')
    const [copied, setCopied] = useState(false)

    const [useBalance, setUseBalance] = useState(false)
    const [usedBalance, setUsedBalance] = useState(0)
    const [thisCustomerHasGateway, setThisCustomerHasGateway] = useState(userData[0].customersData.hasGateway)
    const [userDataDetailed, setUserDataDetailed] = useState('')
    const [paymentStatus, setPaymentStatus] = useState(3)
    const [dueDate, setDueDate] = useState('')

    const [schedulesId, setSchedulesId] = useState('')
    const [payment, setPayment] = useState('')
    const [paymentId, setPaymentId] = useState('')
    const [gatewayData, setGatewayData] = useState([])
    const [steps, setSteps] = useState([
        {
            id: 0, text: 'Reserva', valid: false
        },
        {
            id: 1, text: 'Pagamento', valid: false
        },
        {
            id: 2, text: 'Concluído', valid: false
        }
    ])

    const [isLocalPayment, setIsLocalPayment] = useState(false)
    const [paymentOption, setPaymentOption] = useState([])
    const [filteredSteps, setFilteredSteps] = useState(steps)
    const [activeStep, setActiveStep] = useState({ id: 0 })
    const [createdSchedules, setCreatedSchedules] = useState('')
    const [seconds, setSeconds] = useState(900);
    const [remaingSeconds, setRemaingSeconts] = useState(900)
    const [startTimer, setStartTimer] = useState(false)
    const [custtomerAttendanceDetails, setCusttomerAttendanceDetails] = useState('')
    const tableThead = [
        {
            "name": 'Dia',
            "original_name": 'dayDescription'
        },
        {
            "name": 'Hora',
            "original_name": 'openHourDescription'
        }
    ]
    const [askedHoursLoading, setAskedHoursLoading] = useState(false)
    const [showBlockDescription, setShowBlockDescription] = useState(false)
    const [schedulesNotPermitted, setSchedulesNotPermitted] = useState('')

    useEffect(() => {
        let now = moment(Date.now())
        let timer

        if (startTimer) {

            now = moment(Date.now())

            timer = setInterval(() => {
                let diff = parseInt(Math.round(now.diff(moment(Date.now()), 'secons')?.toString().slice(0, 1)))
                setSeconds(seconds => seconds > 0 ? remaingSeconds + now.diff(moment(Date.now()), 'seconds') : 0);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        } else {
            clearInterval(timer)
        }
    }, [startTimer]);

    const minutesDisplay = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secondsDisplay = (seconds % 60).toString().padStart(2, '0');
    const progress = (seconds / (15 * 60)) * 100;
    if (progress === 0) {
        handleTimeIsOver(true)
    }

    async function formatAPIPhoto(apiData, serviceId) {

        if (apiData.pictures?.length > 0) {

            let photosArray = []

            try {
                await Promise.all(apiData.pictures.map(async (picture) => {
                    const file = await api.get(`api/v1/servicesPictures/services_id/${serviceId}/picture/${picture}`, {
                        responseType: 'blob',
                        responseEncoding: 'base64',
                        headers: {
                            Authorization: `Bearer ${userData[0].token}`
                        }
                    })
                    const fileObj = URL.createObjectURL(file.data)
                    photosArray.push(fileObj)
                }))
                setServicesPhotos(photosArray)
            } catch (error) {
                setServicesPhotos([servicesImg])
            }
        }
    }

    async function getServiceDetails(serviceId) {
        setLoading(true)
        await Promise.all(custtomerAttendanceDetails?.associationsParameters?.map(param => {
            if ((param.associations_id === userData[0].associations_id) && param.categories_id === selectedCategorie?.id) {
                setServiceDaysToOpen(param.daysToOpen)
            }
        }))

        const getServices = await defaultCallsSchedules.getServicesEspecific(userData[0]?.token, serviceId)

        let termId = getServices.data[0].termsOfUse_id
        if (termId > 0) {
            const servicesTerms = await defaultCallsSchedules.getTermsOfUseEspecific(userData[0]?.token, termId)

            setTermsOfUseDescription(
                servicesTerms.data[0].termsOfUse.ops.length > 0 ?
                    servicesTerms.data[0].termsOfUse : false
            )
        }
        if (getServices.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(getServices.data)
        }

        await formatAPIPhoto(getServices.data[0], serviceId)
        setLoading(false)
    }

    async function getServiceHoursFree(day, service_id) {

        try {
            setLoadingHours(true)
            const hours = await defaultCallsSchedules.getSchedulesHours(userData[0].token, day.clone().format("YYYY-MM-DD"), service_id ?? selectedService.id)
            setSelectedDay(day)
            const ordernedHours = hours?.data?.sort((a, b) => a.startHour - b.startHour);
            const reponseAskReleasedSubs = await api.get(`/api/v1/alertReleasedScheduleHour/services_id/${service_id ?? selectedService.id}/startDate/${day.clone().format("YYYY-MM-DD")}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            const askReleasedSubscription = reponseAskReleasedSubs.data.data

            if (askReleasedSubscription?.length > 0) {
                setFilteredHours(ordernedHours.map(hour => {
                    const existsSubscription = askReleasedSubscription.filter((sub) => {
                        return sub.startHour == hour.startHour && sub.finishHour == hour.finishHour
                    })
                    if (existsSubscription?.length > 0) {
                        return {
                            ...hour,
                            askedRelasedHour: true,
                            askedRelasedHour_id: existsSubscription[0].id
                        }
                    }
                    return hour
                }))
            } else {
                setFilteredHours(ordernedHours)
            }
            setSelectedValue('')
            setSelectedHour([])
            setLoadingHours(false)
            closeSpaces()

            return ordernedHours
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                animateLoading(true)
                setLoadingHours(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setLoadingHours(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function closeSpaces() {

        setShowSpaceList('')
        setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
    }

    function checkSpaceActiveKeyboard(e) {

        if (spaceState.current > 0 && e.code === 'Escape') {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }
    }

    function checkSpaceActive(element) {
        if (spaceState.current === 1 && !spaceOneRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }

        if (spaceState.current === 2 && !spaceTwoRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }

        if (spaceState.current === 3 && !spaceTreeRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }

        if (spaceState.current === 4 && !spaceCategorieRef.current?.contains(element.target)) {
            setShowSpaceList('')
            setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        }
    }

    function filterActiveServices(servicesList) {

        return servicesList.filter(service => service.active)
    }

    useEffect(() => {

        const fetchData = async () => {
            const result = await defaultCallsSchedules.getExternalPaymentStatus(userData[0]?.token, paramsPaymentId?.paramsPaymentId)
            setPaymentStatus(result?.data[0]?.status)
            if (result?.data[0]?.status !== 3) {
                setStartTimer(false)
                clearInterval(interval)
                setActiveStep({ id: 2 })
            }
        }
        let interval
        if (startFindPaymentStatus) {
            interval = setInterval(() => {
                fetchData();
            }, 15000);

            return () => clearInterval(interval);
        } else {
            clearInterval(interval)
        }
    }, [startFindPaymentStatus]);

    useEffect(() => {
        setShowNotificationModal(false)
        setShowUniversalModal(false)
        async function getData() {


            if (userData[0]?.customersData?.blockedByFinancialRules) {
                setSchedulesNotPermitted('financialRules')
            }
            // const servicesData = await defaultCallsSchedules.getServices(userData[0].token)
            const myBalance = await defaultCallsSchedules.getMyBalance(userData[0].token)
            const customerAttendanceAPI = await defaultCallsSchedules.getCustomersAttendance(userData[0].token)
            setCusttomerAttendanceDetails(customerAttendanceAPI.data[0])
            if (customerAttendanceAPI.data[0]?.isOpenBySystemTable) {
                const defaultTableData = await defaultCallsSchedules.getDefaultTableHours(userData[0].token)
                setDefaultTableOpenHours(defaultTableData?.data)
            }

            const customersData = await defaultCallsSchedules.getPaymentPublicGateways(userData[0]?.token)
            setThisCustomerHasGateway(customersData?.data?.data[0]?.gateway_id ? true : false)

            if (myBalance.data?.length > 0) {
                setMyBalance(myBalance.data[0]?.balance)
            }

            const userDataAPI = await defaultCallsAuth.getUserEspecific(userData[0]?.token, userData[0]?.id)
            if (userDataAPI?.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
            }
            setUserDataDetailed(userDataAPI?.data[0])

            const categoriesData = await defaultCallsSchedules.getCategories(userData[0].token, 1)
            if (categoriesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
            }

            const paymentsDataList = await defaultCallsSchedules.getPaymentMethods(userData[0].token)
            if (paymentsDataList.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(paymentsDataList.data)
                return
            }

            setCategories(categoriesData.data.filter(categ => {
                if (categ.associations?.map(association => association).includes(userData[0].associations_id)) {
                    return categ
                }
                if (categ.forAll == true) {
                    return categ
                }
            }))

            if (paymentsDataList.data?.length > 0) {
                const paymentMethodsForClient = paymentsDataList.data.filter(paymentOption => paymentOption.forClient && paymentOption?.modules?.includes(1))
                let methodIsF = false

                const filteredMethodsF = paymentMethodsForClient.filter(method => {
                    if (method.methodKey === "F" && method.associations_id === userData[0].associations_id && !userData[0]?.customersData?.useBalance) {
                        methodIsF = true
                        return method
                    }
                })

                const filteredMethods = paymentMethodsForClient.filter(method => {
                    if (method.methodKey !== "F") {
                        return method
                    }
                })

                if (methodIsF) {
                    setPaymentOption(filteredMethodsF.map(payment => { return { ...payment, description: payment?.methodKey === "D" ? "Presencial/Dinheiro" : payment?.description } }))
                } else {
                    setPaymentOption(filteredMethods.map(payment => { return { ...payment, description: payment?.methodKey === "D" ? "Presencial/Dinheiro" : payment?.description } }))
                }
            } else {
                setDontHavePaymentMethods(true)
            }
            setUserPermiited(
                (userData[0]?.permissions?.indexOf('schedule:create') > -1 || userData[0]?.typeAccess_id < 3) ? true : false
            )
            const mySchedulesPendings = await defaultCallsSchedules.getSchedulesClientsMyPendings(userData[0]?.token)

            if (mySchedulesPendings?.data?.payment?.length > 0 || paramsPaymentId?.paramsPaymentId !== undefined) {
                if (paramsPaymentId?.paramsPaymentId) {
                    //busca dados do pagamento caso usuario esteja dando f5 com o paymentId no params
                    const result = await defaultCallsSchedules.getExternalPaymentStatus(userData[0]?.token, paramsPaymentId?.paramsPaymentId)
                    //se a API retornar pago entra como status 3 (terceira etapa)
                    if (result.data[0]?.status === 1) {
                        setSelectedPayment({ methodKey: result?.data[0]?.paymentMethod })
                        setPaymentStatus(result.data[0]?.status)
                        setActiveStep({ id: 2 })
                        setTypeModal(result?.data[0]?.paymentMethod)
                        setLoading(false)
                        return
                    }
                    if (result.data[0]?.status !== 3) {
                        setSelectedPayment({ methodKey: result?.data[0]?.paymentMethod })
                        setPaymentStatus(result.data[0]?.status)
                        setActiveStep({ id: 1 })
                        setTypeModal(result?.data[0]?.paymentMethod)
                        setLoading(false)
                        return
                    }

                }
                //pega dados do pagamento em um F5
                let payment = mySchedulesPendings?.data.payment[0]
                let hours = mySchedulesPendings?.data?.schedules.map(schedule => {
                    return { startHour: schedule?.startHour, finishHour: schedule?.finishHour }
                })
                let service = mySchedulesPendings?.data?.schedules[0]?.services_name
                setActiveStep({ id: 1 })
                //prencher as variaveis
                setSchedulesId(mySchedulesPendings?.data?.schedules[0]?.id)
                setPaymentStatus(payment?.status)
                setIsLocalPayment(mySchedulesPendings?.data?.schedules[0]?.categories?.isLocalPayment)
                setTotalValue(payment?.totalValue)
                setSelectedDay(moment(mySchedulesPendings?.data?.schedules[0]?.startDate))
                setSelectedMonth(moment(mySchedulesPendings?.data?.schedules[0]?.startDate))
                setSelectedHour(hours)
                setRemaingSeconts(parseInt(payment?.expires_in))
                setStartTimer(true)
                setSelectedService({ name: service, reservationType: 'H' })
                setPaymentId(payment?.id)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(true)
                setShowNotificationModalText('Voce tem uma reserva em andamento, caso ja tenha efetuado o pagamento, aguarde que o mesmo será processado')
                //se for pagamento PIX
                if (payment?.paymentMethod === 'P' && payment?.status === 3) {
                    setSelectedPayment({ methodKey: "P" })
                    setLoadingPayment(true)
                    setLoading(false)
                    setTypeModal('P')
                    handlePaymentPixCheckout(payment?.id)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(true)
                    return setShowNotificationModalText('Voce tem uma reserva em andamento, caso ja tenha efetuado o pagamento, aguarde que o mesmo será processado')
                }
                //se pagamento for cartao de credito
                if (payment?.paymentMethod === 'C' && payment?.status === 3) {
                    setSelectedPayment({ methodKey: "C" })
                    setLoadingPayment(true)
                    setTypeModal('C')
                    setLoading(false)
                    navigate(`/reservation/${payment?.id ? payment?.id : paymentId}`)
                    setStartFindPaymentStatus(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(true)
                    return setShowNotificationModalText('Voce tem uma reserva em andamento, caso ja tenha efetuado o pagamento, aguarde que o mesmo será processado')
                }
            }
            setLoading(false)
        }
        getData()
    }, [])

    useEffect(() => {

        window.addEventListener('click', (e) => checkSpaceActive(e))
        window.addEventListener('keydown', (e) => checkSpaceActiveKeyboard(e))

        window.removeEventListener('keydown', (e) => checkSpaceActiveKeyboard(e))
        return window.removeEventListener('click', (e) => checkSpaceActive(e))

    }, [])

    useEffect(() => {

        async function alterAnimateSpace() {

            await new Promise(resolve => setTimeout(resolve, 100))
            if (showSpaceList === 1) {
                setAnimateSpace((prev) => ({ one: true, two: false, tree: false, four: false }))
            }
            if (showSpaceList === 2) {
                setAnimateSpace((prev) => ({ one: false, two: true, tree: false, four: false }))
            }
            if (showSpaceList === 3) {
                setAnimateSpace((prev) => ({ one: false, two: false, tree: true, four: false }))
            }

            if (showSpaceList === 4) {
                setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: true }))
            }
        }

        alterAnimateSpace()

    }, [showSpaceList])

    async function handleWithParams() {
        try {
            if (!!paramsReservationPerLogin && paramsReservationPerLogin?.categories_id && categories?.length > 0) {
                const foundCategorieParams = categories.find(cat => Number(cat?.id) === Number(paramsReservationPerLogin?.categories_id))

                let hours = preSelectedHours.size > 0 ? preSelectedHours.get('hour').split(',') : []
                if (foundCategorieParams) {

                    setSelectedCategorie(foundCategorieParams)
                    await selectCategorie(foundCategorieParams, Number(paramsReservationPerLogin?.services_id))

                    if (paramsReservationPerLogin?.date) {
                        setSelectedDay(moment(`${paramsReservationPerLogin.date}`))
                        const serviceHours = await getServiceHoursFree(moment(`${paramsReservationPerLogin.date}`), paramsReservationPerLogin.services_id)
                        //seleciona os horarios que vem do modo CARD
                        hours?.map(hour => {

                            let thishour = serviceHours.find(filteredHour => filteredHour.id === Number(hour))

                            if (selectedHour.map(selHour => selHour.id).includes(thishour?.id)) {
                                setSelectedHour(selectedHour.filter(hourToFilter => hourToFilter.id !== thishour.id))
                                let historyValues = selectedHour.filter(hourToFilter => hourToFilter.id !== thishour.id).map(hourValue => hourValue.serviceValue)
                                let values = historyValues?.length > 0 ? historyValues.reduce((acumulator, currentValue) => acumulator + currentValue) : ''
                                setSelectedValue(values)
                                setTotalValue(values)

                            } else {
                                setSelectedHour((prev) => ([...prev, thishour]))
                                let historyValues = selectedHour.map(hourValue => hourValue.serviceValue)
                                let values = selectedHour?.length > 0 ? historyValues.reduce((acumulator, currentValue) => acumulator + currentValue) + thishour.serviceValue : thishour.serviceValue
                                setSelectedValue(values)
                                setTotalValue(values)
                            }
                        })
                    }
                } else {
                    setSelectedCategorie([])
                }
            }
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                return { error: true }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText('Erro inesperado')
                return { error: true }
            }
        }
    }

    //para visão calendário (trazer os dados preenchidos em tela)
    useEffect(() => {
        handleWithParams()
    }, [paramsReservationPerLogin, categories])

    function extractNumber(name) {
        const match = name.match(/\d+/);
        return match ? parseInt(match[0]) : Infinity;
    }

    function formatHours(hour) {

        try {

            let thisHour = hour

            if (thisHour < 10) {
                thisHour = `0${hour?.toString()}`
            }
            if (thisHour?.toString()?.indexOf('.5') > -1) {
                let newHour = thisHour?.toString()?.replace('.5', ':30')
                return newHour
            }
            return `${thisHour}:00`
        } catch (error) {
            return hour
        }
    }

    function handleTimeIsOver(over) {
        if (over && activeStep?.id !== 2) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Tempo esgotado, efetue a escolha do espaço novamente')

            localStorage.setItem('@reservationFailed', paymentId)
            navigate(`/reservationFailed/${paymentId}`)
        }
    }

    function validateCardFields() {

        if (selectedCardNumber === '' || selectedCardNumber?.length < 16) {
            alert('Prencha todos os numeros do cartao')
            return false
        }
        if (selectedCardCVVNumber === '' || selectedCardCVVNumber?.length < 3) {
            alert('Preencha o codigo CVV, são no mínimo 3 digitos')
            return false
        }
        if (selectedCardPersonName === '' || selectedCardPersonName?.length < 4) {
            alert('Preencha o nome como está gravado no cartão')
            return false
        }
        if (selectedCardDueDateMonth === '') {
            alert('Preencha o mês de vencimento do cartão')
            return false
        }
        if (selectedCardDueDateYear === '') {
            alert('Preencha o ano de vencimento do cartão')
            return false
        }
        return true
    }

    function validateRequiredFields() {

        if (selectedCategorie === '') {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Selecione o tipo')
            return { error: true }
        }
        if (selectedService?.length === 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Selecione o local')
            return { error: true }
        }
        if (selectedDay === '') {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Selecione o dia')
            return { error: true }
        }
        if (selectedHour === '') {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Selecione o horário')
            return { error: true }
        }
        if (selectedHour?.length === 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Selecione o horário')
            return { error: true }
        }
        if (selectedPayment.methodKey === 'P' && !userSelectedCPF && thisCustomerHasGateway) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Obrigatório informar CPF para pagamentos em PIX')
            return { error: true }
        }

        // if (activeStep?.id === 1 && !selectedTermsOfUse) {
        //     setShowNotificationModalSuccess(false)
        //     setShowNotificationModal(true)
        //     document.getElementById('termOfUse').scroll()
        //     setShowNotificationModalText('Necessário aceitar o termo de uso')
        //     return
        // }
        return { error: false }
    }

    function borderToCardFields(border) {
        const elementsCard = document.querySelectorAll('.card_object')
        if (border) {
            Array.from(elementsCard)?.map(el => el.style.border = '1px solid red')
        } else {
            Array.from(elementsCard)?.map(el => el.style.border = '1px solid #fff')
        }
    }

    async function handleDrawn() {

        setLoading(true)
        try {
            const drawn = await api.post(`/api/v1/schedulesDraw/client`, {
                services_id: selectedService?.id,
                servicesHours_id: selectedHour[0]?.id,
                scheduleDate: selectedDay
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Você foi inscrito com sucesso, verifique o status do sorteio na tela minhas reservas')
            setActiveStep({ id: 3 })
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                return { error: true }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText('Erro inesperado')
                return { error: true }
            }
        }

    }
    //stone Payment functions
    async function handlePaymentCardCheckout(payId) {

        if (!cpfIsValid(userSelectedCPF)) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('CPF digitado é inválido, verifique os dados informados')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return { error: true }
        }
        if (!userSelectedCPF) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Obrigatório informar CPF para pagamentos em Cartão')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return { error: true }
        }

        if (thisCustomerHasGateway && (!userDataDetailed?.phone && !userDataDetailed?.cell) && (!areaCode || !phoneNumber)) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Obrigatório informar um telefone válido para pagamento no cartão de crédito')
            const areaCodeElement = document.getElementById('userSelectedAreaCode')
            areaCodeElement.style.border = '1px solid red'
            const phoneNumberElement = document.getElementById('userSelectedPhoneNumber')
            phoneNumberElement.style.border = '1px solid red'
            return { error: true }
        }
        setLoadingPayment(true)
        setTypeModal('C')
        setDontShowFooter(true)
        let cardToken = ''
        try {
            const tokenPayment = await getCardToken({
                card: {
                    number: selectedCardNumber?.replace(/\s/g, ''),
                    holder_name: selectedCardPersonName,
                    exp_month: selectedCardDueDateMonth,
                    exp_year: selectedCardDueDateYear,
                    cvv: selectedCardCVVNumber,
                    "billing_address": {
                        "line_1": "-",
                        "zip_code": "00000000",
                        "city": "XX",
                        "state": "XX",
                        "country": "BR"
                    }
                },
                appId: userData[0].customersData?.paymentGateway?.client_public
            })
            cardToken = tokenPayment?.data
        } catch (error) {
            console.log(error)
            setTypeModal('E')
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Erro com os dados do cartão, verifique os dados informados!')
            borderToCardFields(true)
            return { error: true }
        }

        if (!cardToken) {
            setTypeModal('E')
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Erro com os dados do cartão, verifique os dados informados!')
            borderToCardFields(true)
            return { error: true }
        }

        try {
            setLoadingPayment(true)
            setTypeModal('C')
            setStartPaymentSearch(true)
            const newCardPayment = await api.post(`/api/v1/payments/${paymentId}`, {
                paymentMethod: "C",
                useBalance,
                areaCode: areaCode ? areaCode?.toString() : undefined,
                phoneNumber: phoneNumber ? phoneNumber?.toString() : undefined,
                cpf: userSelectedCPF,
                cardToken: cardToken?.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            if (newCardPayment?.data?.data[0]?.status_descripton === 'failed') {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setTypeModal('E')
                return setShowNotificationModalText('Ocorreu um erro ao tentar se comunicar com o serviço de pagamento')
            }
            setCopied(false)
            setGatewayData(newCardPayment?.data?.data[0]?.gatewayData)
            navigate(`/reservation/${payId ? payId : paymentId}`)
            setPaymentStatus(newCardPayment?.data?.data[0]?.status)
            //pagamentos aprovados
            if (newCardPayment?.data?.data[0]?.status === 1) {
                setActiveStep((prev) => ({ id: prev.id + 1 }))
            }
            setLoadingPayment(false)
            setLoading(false)
            setStartFindPaymentStatus(true)
            return { error: false }

        } catch (error) {
            setTypeModal('E')
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                return { error: true }
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText('Erro inesperado')
                return { error: true }
            }
        }
    }

    async function handlePaymentPixCheckout(payId) {

        if (seconds <= 10) {
            alert('O tempo para realizar o pagamento por pix é muito curto')
            return handleTimeIsOver(true)
        }

        if (!payId && !cpfIsValid(userSelectedCPF)) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('CPF digitado é inválido, verifique os dados informados')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return { error: true }
        }

        //se nao veio id de pagamento (quando usuario pressiona F5) e ele nao digitou o CPF
        //CPF é obrigatorio no primeiro envio
        if (!payId && !userSelectedCPF) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Obrigatório informar CPF para pagamentos em Cartão')
            const cpfElement = document.getElementById('userSelectedCPF')
            cpfElement.style.border = '1px solid red'
            return
        }
        setShowNotificationModal(false)
        try {

            setLoadingPayment(true)
            setTypeModal('P')
            setStartPaymentSearch(true)
            const newPixPayment = await api.post(`/api/v1/payments/${payId ? payId : paymentId}`, {
                paymentMethod: "P",
                useBalance,
                cpf: payId ? '82233730097' : userSelectedCPF,
                pix_expires_in: seconds
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setPaymentStatus(newPixPayment?.data?.data[0]?.status)
            if (newPixPayment?.data?.data[0]?.status_descripton === 'failed') {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setTypeModal('E')
                return setShowNotificationModalText('Ocorreu um erro ao tentar se comunicar com o serviço de pagamento')
            }
            setCopied(false)
            setGatewayData(newPixPayment?.data?.data[0]?.gatewayData)
            navigate(`/reservation/${payId ? payId : paymentId}`)
            setLoadingPayment(false)
            setLoading(false)
            setStartFindPaymentStatus(true)

        } catch (error) {
            setShowUniversalModal(false)
            setLoadingPayment(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Ocorreu um erro ao solicitar o codigo PIX, tente clicar novamente em pagar com pix, se o problema persistir, acessa a aba minhas reservas, anote o id da reserva e contate a secretaria')
            console.log(error)
        }
    }

    async function handlePreBookSchedule() {

        if ((totalValue === 0 && termOfUseDescription && !selectedTermsOfUse) ||
            (isLocalPayment && termOfUseDescription && !selectedTermsOfUse)) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Aceite o termo para continuar')
            setShowTermModal(true)
            return

        }

        setLoading(true)

        try {
            //api confirm schedules para clientes
            const newSchedule = await api.post(`/api/v1/schedules/client`, {
                scheduleDate: selectedDay.clone().format('YYYY-MM-DD'),
                servicesHours: selectedHour.map(hour => hour.id),
                services_id: selectedService.id,
                observation: selectedObservation?.length > 0 ? selectedObservation : undefined,
                modality: selectedModality ? selectedModality : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setDueDate(newSchedule.data?.data?.payment[0]?.dueDate)
            setPaymentStatus(newSchedule?.data?.data.payment[0]?.status)
            //se a categoria for pagamento somente no local
            //irá entrar como pagamento pendente
            if (newSchedule.data.data?.payment[0]?.status === 3 && newSchedule.data.data?.payment[0]?.origin === 0) {
                setPayment(newSchedule.data?.data?.payment[0])
                setPaymentId(newSchedule?.data?.data?.payment[0]?.id)
                setCreatedSchedules(newSchedule.data?.data?.schedules[0])
                setStartTimer(false)
                setActiveStep((prev) => ({ id: prev.id + 2 }))
                setLoading(false)
                return
            }

            //se o servico foi parametrizado sem valor
            //irá confirmar o pagamento automaticamente
            if (totalValue === 0) {
                setPayment(newSchedule.data?.data?.payment[0])
                setPaymentId(newSchedule?.data?.data?.payment[0]?.id)
                setCreatedSchedules(newSchedule.data?.data?.schedules[0])
                setStartTimer(false)
                setActiveStep((prev) => ({ id: prev.id + 2 }))
                setLoading(false)
                return
            }
            setSchedulesId(newSchedule?.data?.data?.schedules[0]?.id)
            setPaymentStatus(newSchedule.data?.data?.payment[0]?.status)
            setPayment(newSchedule.data?.data?.payment[0])
            setPaymentId(newSchedule?.data?.data?.payment[0]?.id)
            setCreatedSchedules(newSchedule.data?.data?.schedules[0])
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Este horário ja é seu, siga para o pagamento')
            setActiveStep((prev) => ({ id: prev.id + 1 }))
            setLoading(false)

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {

        if (selectedService?.modalities?.length > 0 && !selectedModality) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Selecione uma modalidade para continuar')
        }
        setTypeShowModal('')
        setShowNotificationModal(false)
        if (validateRequiredFields().error) {
            return
        }

        const drawnHours = selectedHour?.filter(hour => hour.isDrawn)
        if (drawnHours?.length > 0) {
            setTypeShowModal('confirmDrawnSchedule')
            setShowUniversalModal(true)
            return
        }

        //se pagamento for somente local, entra com status de pagamento pendente
        if (activeStep.id === 1 && isLocalPayment) {
            setTypeModal('')
            return setShowUniversalModal(true)
        }

        //efetua pre-reserva deste horario
        if (activeStep.id === 0) {
            setStartTimer(true)
            //regra especifica ADE
            if (userData[0]?.customers_code === 'ade' && (selectedCategorie?.id === 1 || selectedCategorie?.id === 2)) {
                //valida se ja tem dinheiro incluso
                if (!paymentOption.map(method => { return method.methodKey }).includes("D")) {
                    setPaymentOption(prev => prev.concat(paymentMethods.map(method => {
                        if (method.methodKey === 'D') {
                            return {
                                ...method,
                                description: 'Presencial'
                            }
                        }
                    }).filter(Boolean)))
                }
            }
            return await handlePreBookSchedule()
        }

        if (activeStep.id > 0 && termOfUseDescription && !selectedTermsOfUse) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Aceite o termo para continuar')
            return
        }

        //servicos com valor total zero - confirma pagamento automaticamente
        if (activeStep.id === 1 && totalValue === 0) {
            return await handleConfirmScheduleWoPayment()
        }

        //se selecioando folha de pagamento ira entrar como pago
        if (activeStep.id === 1 && selectedPayment?.methodKey === 'F') {
            return await handleConfirmScheduleWoPayment()
        }
        //se selecionado Pix e o customer não tem gateway de pagamentos, cai a reserva solicitando aprovacao da secretaria
        if (activeStep.id === 1 && selectedPayment?.methodKey === 'P' && !thisCustomerHasGateway) {
            setTypeModal('')
            return setShowUniversalModal(true)
        }
        //se selecionado dinheiro, cai a reserva solicitando aprovacao da secretaria
        if (activeStep.id === 1 && selectedPayment?.methodKey === 'D') {
            setTypeModal('')
            return setShowUniversalModal(true)
        }
        //se selecionado Cartao de credito e o customer não tem gateway de pagamentos, cai a reserva solicitando aprovacao da secretaria
        if (activeStep.id === 1 && selectedPayment?.methodKey === 'C' && !thisCustomerHasGateway) {
            setTypeModal('')
            return setShowUniversalModal(true)
        }
        //se selecionado cartao de credito e o customer tem gateway, valida os dados do cartao e chama a funcao para pagamento com cartao (pagarme)
        if (activeStep.id === 1 && selectedPayment?.methodKey === 'C' && thisCustomerHasGateway && !isLocalPayment) {
            if (!validateCardFields()) {
                return
            }

            //REGRA ESPECIFICA ADE para quiosques exibir modal
            if (userData[0].customers_code === 'ade' && selectedCategorie.id === 1) {
                setTypeShowModal('confirmADEDialog')
                setShowUniversalModal(true)
                return
            }
            const payment = await handlePaymentCardCheckout()
            if (payment?.error) {
                return
            }

            return
        }

        if (activeStep.id === 1 && selectedPayment?.methodKey === 'P' && thisCustomerHasGateway && !isLocalPayment) {
            if (!validateCardFields()) {
                return
            }
            await handlePaymentPixCheckout()
        }
        setActiveStep((prev) => ({ id: prev.id + 1 }))
    }

    async function selectCategorie(categorie, services_id) {
        setLoading(true)
        if (selectedCategorie.id !== categorie.id) {
            setSelectedCategorie(categorie)
            setSelectedService([])
            const customerAttendanceForThisAssociation = custtomerAttendanceDetails.associationsParameters.filter(associationParam => associationParam.associations_id === userData[0].associations_id && associationParam.categories_id === categorie.id)
            setServiceDaysToOpen(customerAttendanceForThisAssociation[0]?.daysToOpen)

            const filteredServices = await defaultCallsSchedules.getServicesByCategoriesId(userData[0]?.token, categorie.id)
            if (filteredServices?.data?.length > 0) {
                setFilteredServices(filteredServices?.data?.sort((a, b) => {
                    const numA = extractNumber(a.name);
                    const numB = extractNumber(b.name);

                    if (numA !== numB) {
                        return numA - numB;
                    } else {
                        return a.name.localeCompare(b.name);
                    }
                }))
            }
            if (!!services_id) {
                const foundServiceParams = filteredServices?.data?.find(ser => ser?.id === services_id)
                await selectService(foundServiceParams)
            }
        }
        setShowSpaceList('')
        setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
        setLoading(false)
    }

    async function selectService(service) {
        setLoading(true)
        await getServiceDetails(service.id)
        await new Promise(resolve => setTimeout(resolve, 60))
        setSelectedService(service)
        // setLoading(false)
    }

    async function handleSelectedHour(hour) {

        const drawnHours = selectedHour.filter(hour => hour.isDrawn)
        const normalHorus = selectedHour.filter(hour => !hour.isDrawn)

        if (hour.isDrawn && normalHorus.length > 0) {
            return alert('Você não pode selecionar horas normais e de sorteio juntas')
        }

        if (!hour.isDrawn && drawnHours.length > 0) {
            return alert('Você não pode selecionar horas normais e de sorteio juntas')
        }

        if (drawnHours?.length > 0) {
            if (drawnHours.filter(drawnHour => drawnHour.id !== hour.id).length > 0) {
                return alert('Só é permitido selecionar um horário por vez')
            }
        }

        if (selectedHour.map(selHour => selHour.id).includes(hour.id)) {
            setSelectedHour(selectedHour.filter(hourToFilter => hourToFilter.id !== hour.id))
            let historyValues = selectedHour.filter(hourToFilter => hourToFilter.id !== hour.id).map(hourValue => hourValue.serviceValue)
            let values = historyValues?.length > 0 ? historyValues.reduce((acumulator, currentValue) => acumulator + currentValue) : ''
            setSelectedValue(values)
            setTotalValue(values)
        } else {
            setSelectedHour((prev) => ([...prev, hour]))

            let historyValues = selectedHour.map(hourValue => hourValue.serviceValue)
            let values = selectedHour?.length > 0 ? historyValues.reduce((acumulator, currentValue) => acumulator + currentValue) + hour.serviceValue : hour.serviceValue
            setSelectedValue(values)
            setTotalValue(values)
        }
    }

    async function handleConfirmScheduleWoPayment(paymentMethod) {

        setLoading(true)

        try {
            //confirm schedule
            const scheduleCreate = await api.post(`/api/v1/payments/${paymentId}`, {
                paymentMethod: selectedPayment.methodKey,
                useBalance
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setPaymentStatus(scheduleCreate?.data?.data[0]?.status)
            setLoading(false)
            setShowUniversalModal(false)
            setStartTimer(false)
            setActiveStep((prev) => ({ id: 2 }))
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function confirmationContainer() {

        {
            return selectedService.reservationType === 'H' &&
                (
                    <div className='mt-3 w-full flex flex-col gap-4 mb-6 rounded-lg p-3 sm:p-0 items-start'>
                        <h1 className='text-base font-bold text-primaryDefaultLight'>Confirmação</h1>
                        <div className='w-full bg-zinc-50 p-2 items-start justify-center rounded-lg border border-primaryDefaultLight bg-bgPrimaryLight dark:bg-secondaryDefaultDark'>
                            <div className='h-full flex flex-col sm:flex-row text-sm gap-2 items-center justify-center w-full'>
                                <div className='flex flex-row gap-2 w-full sm:w-[50%] items-center justify-center'>
                                    <FaMapMarkerAlt className='text-secondaryDefaultLight text-2xl' />
                                    <h2 className='text-2xl text-primaryDefaultLight font-bold '>{selectedService.name}</h2>
                                </div>
                                <div className='w-[50%] sm:border-l sm:border-primaryDefaultLight h-full text-sm flex flex-row gap-2 items-center justify-start'>
                                    <FaCalendar className='ml-2 text-secondaryDefaultLight text-xl' />
                                    <div className='flex flex-row items-center justify-center gap-4 w-full'>
                                        <div className='flex flex-row gap-2 items-center justify-center border-r border-primaryDefaultLight px-2'>
                                            <p className='ml-2 text-primaryDefaultLight font-bold text-xl dark:text-titleGrayTextDark'>{selectedDay?.format("DD/MM/YYYY")?.toString()}</p>
                                        </div>
                                        {
                                            selectedHour?.length > 1 ?
                                                <div className='flex flex-row gap-1 flex-wrap items-center justify-start w-full'>
                                                    {
                                                        selectedHour.map(hour => {
                                                            return <div className='flex flex-row gap-1'>
                                                                <p className='text-titleBlackTextLight font-bold dark:text-titleGrayTextDark'>{formatHours(hour?.startHour)}</p>
                                                                <p className='text-titleBlackTextLight font-bold dark:text-titleGrayTextDark'>as</p>
                                                                <p className='text-titleBlackTextLight font-bold dark:text-titleGrayTextDark'>{formatHours(hour?.finishHour)}</p>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                                :
                                                <div className='flex flex-row gap-1 items-center justify-start w-full'>
                                                    <p className='text-primaryDefaultLight font-bold dark:text-titleGrayTextDark'>{formatHours(selectedHour[0]?.startHour)}</p>
                                                    <p className='text-primaryDefaultLight font-bold dark:text-titleGrayTextDark'>as</p>
                                                    <p className='text-primaryDefaultLight font-bold dark:text-titleGrayTextDark'>{formatHours(selectedHour[0]?.finishHour)}</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !termOfUseDescription ?
                                <>
                                </>
                                :
                                <div className='mt-3 w-full flex flex-col items-start gap-4'>
                                    <h2 className='text-base text-primaryDefaultLight font-bold'>Termos e condiçoes</h2>

                                    <div className='w-full bg-zinc-50 rounded-lg shadow-inner border-zinc-200 overflow-auto dark:scrollbar-thin dark:scrollbar-track-zinc-700 dark:scrollbar-thumb-zinc-500 text-base break-words whitespace-pre-line sm:text-sm h-80 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>
                                        <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark sm:min-w-[200px]  min-h-96' readOnly={true} theme={"bubble"} value={termOfUseDescription} />
                                    </div>
                                    <p className='text-sm text-primaryDefaultLight'>Aceito o termo</p>
                                    <InputCheckBox id='termOfUse' onClick={() => setSelectedTermsfUse(!selectedTermsOfUse)} value={selectedTermsOfUse ? true : false}></InputCheckBox>
                                </div>
                        }
                        {/* <div className='w-full mt-2 mb-4 flex items-start flex-col justify-start'>
                            <a className='text-sm text-inputPlaceholderLight dark:text-titleGrayTextDark'>Observação</a>
                            <textarea wrap="hard" maxLength={40}
                                onChange={(e) => setSelectedObservation(e.target.value)}
                                className='p-2 border border-gray-400 border-opacity-75 
                                    h-48
                                    w-full                                    
                                    text-lg sm:text-sm
                                    outline-none transition-all duration-200 focus:shadow-borderShadow
                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark '>
                            </textarea>
                            <a className='text-xt text-inputPlaceholderLight'>{selectedObservation?.length} de 40</a>
                        </div> */}
                    </div>
                )
        }
    }

    function handleDiscount(discountValue) {
        if (discountValue?.length > 0) {
            let thisDiscountValue = parseFloat(discountValue?.replace('R$', '')?.replace('.', '')?.replace(',', '.'))
            let totalValue = selectedValue - thisDiscountValue
            setTotalValue(totalValue)
            setDiscount(thisDiscountValue)
        } else {
            setTotalValue(selectedValue)

        }
    }

    function handleUseBalance() {

        if (!useBalance) {
            if (myBalanceHistory === 0) {
                setMyBalanceHistory(myBalance)
            }
            if (selectedValueHistory === 0) {
                setSelectedValueHistory(totalValue)
            }
            if (myBalance > 0 && myBalance < totalValue) {
                setTotalValue((prev) => (prev - myBalance))
                setUsedBalance(myBalance)
            }

            if (myBalance > 0 && myBalance >= totalValue) {
                // setMyBalance((prev) => (prev - selectedValue))
                setTotalValue(0)
                setUsedBalance(totalValue)

            }
            setUseBalance(true)
        } else {
            setUseBalance(false)
            setUsedBalance(0)
            setMyBalance(myBalanceHistory)
            setTotalValue(selectedValueHistory)
        }
    }

    function valueConfirmation() {

        return <div className='w-full flex flex-col gap-4'>
            {
                userData[0]?.customersData?.useBalance &&
                <LabelInput text={'Usar saldo'}>
                    <InputCheckBox value={useBalance} onClick={() => handleUseBalance()}></InputCheckBox>
                </LabelInput>
            }
            <h2 className='text-primaryDefaultLight font-bold text-base'>Valores</h2>
            <div className='flex flex-col item-start justify-center w-full'>
                <div className='w-full  text-titleBlackTextLight dark:text-titleGrayTextDark text-sm sm:w-auto bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-primaryDefaultLight rounded-t-lg'>
                    {
                        userPermitted &&
                        <div className='flex flex-row gap-2 items-center justify-between w-full pl-2 h-10 border-b border-primaryDefaultLight'>
                            <p className='text-primaryDefaultLight font-bold'>Valor de desconto: {discount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </div>
                    }
                    {
                        userData[0]?.customersData?.useBalance &&
                        <>
                            <div className='flex flex-row gap-2 items-center justify-between w-full pl-2 h-10 border-b border-primaryDefaultLight'>
                                <p className='text-sm font-bold'>Valor de saldo: {currencyPtBr(myBalance)}</p>
                            </div>
                            <div className='flex flex-row gap-2 items-center justify-between w-full pl-2 h-10 border-b border-primaryDefaultLight'>
                                <p className='text-sm font-bold'>Valor deduzido do saldo: {currencyPtBr(usedBalance)}</p>
                            </div>
                        </>
                    }
                    <div className='flex flex-row gap-2 items-center justify-between w-full pl-2 h-10 border-b border-primaryDefaultLight'>
                        <p className='text-sm font-bold'>Valor da reserva: {(totalValue || totalValue === 0) ? totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</p>
                    </div>
                </div>
                <div className='mb-4 bg-primaryDefaultLight w-full py-2 rounded-b-lg text-bgPrimaryLight dark:text-titleGrayTextDark flex flex-col items-center justify-center'>
                    <p className='text-base'>Valor a pagar: {(totalValue || totalValue === 0) ? totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</p>
                </div>
            </div>
        </div>
    }

    function paymentSuccessWithGateway() {

        return (
            <div className='flex flex-col gap-2 items-center justify-center h-80'>
                <div className='flex flex-row gap-2'>
                    <FaCheck className='text-lg text-green-600' />
                    <a className='text-base text-green-600 font-semibold'>Pagamento confirmado</a>
                </div>
                <p className='text-titleBlackTextLight text-center sm:text-left'>Sua reserva foi confirmada, acesse o menu minhas reservas para mais detalhes</p>
                <Button shadow={true} onClick={() => navigate('/mySchedules')}>Minhas reservas</Button>
            </div>
        )
    }
    //falha de pagamento com gateway
    function paymentFailedWithGateway() {

        return (
            <div className='flex flex-col gap-2 items-center justify-center h-80'>
                <div className='flex flex-row gap-2'>
                    <FaCircleNotch className='text-lg text-red-600' />
                    <a className='text-base text-red-600 font-semibold'>Pagamento Cancelado</a>
                </div>
                <p className='text-titleBlackTextLight'>Seu pagamento foi recusado</p>
                <Button shadow={true} onClick={() => navigate('/reservation')}>Voltar</Button>
            </div>
        )
    }
    //sucesso de pagamento sem gateway
    function paymentSuccesWithoutGateway() {

        return (
            <div className='flex flex-col items-center sm:items-center mt-2 w-full h-80 gap-4 justify-center'>
                <h1 className='p-2 bg-green-500 rounded-md text-gray-100 font-bold animate-pulse'>Agendado com sucesso!</h1>
                <div className='flex flex-col items-start justify-start gap-2 w-full p-2 rounded-md'>
                    {
                        totalValue === 0 || selectedPayment?.methodKey === 'F' ?
                            <>
                                <a className='text-titleBlackTextLight text-center md:text-left text-sm'><strong>Protocolo:</strong> {paymentId}</a>
                                <a className='text-sm mb-4 text-titleBlackTextLight'><strong>Status: </strong>Agendado</a>
                            </>
                            :
                            <>
                                <a className='text-titleBlackTextLight text-sm'><strong>Protocolo:</strong> {paymentId}</a>
                                <a className='text-sm text-titleBlackTextLight'><strong>Status: </strong>Pagamento pendente, sua reserva será confirmada somente <strong>após o pagamento na secretaria.</strong></a>
                                {
                                    dueDate &&
                                    <a className='text-sm mb-4 text-titleBlackTextLight'><strong>Prazo de pagamento: </strong>{formattTimeToShow(dueDate)}</a>

                                }
                            </>
                    }
                </div>
            </div>
        )
    }
    //abre modal para confirmar o pix
    function confirmPixPayment() {

        if (activeStep.id > 0 && termOfUseDescription && !selectedTermsOfUse) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Aceite o termo para continuar')
            return
        }

        //REGRA ESPECIFICA ADE para quiosques exibir modal
        if (userData[0].customers_code === 'ade' && selectedCategorie.id === 1) {
            setTypeShowModal('confirmADEDialog')
            setShowUniversalModal(true)
            return
        }

        setTypeShowModal('confirmPix')
        setShowUniversalModal(true)
    }
    //fecha modal de horas
    async function closeSpace() {
        await new Promise(resolve => setTimeout(resolve, 100))
        setShowSpaceList('')
        setAnimateSpace((prev) => ({ one: false, two: false, tree: false, four: false }))
    }
    //funcao para cancelar a preBooking
    async function handleCancelPreSchedule() {
        try {
            setLoading(true)
            await api.put(`/api/v1/schedules/client/cancel/schedules_id/${schedulesId}`, {

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowUniversalModal(false)
            setTypeShowModal('preScheduleCancelled')
            setStartFindPaymentStatus(false)
            setStartPaymentSearch(false)
            setStartTimer(false)
            setDontShowFooter(true)
        } catch (error) {

        }
    }

    async function handleAskReleasedHour(hour) {
        try {
            setAskedHoursLoading(true)
            const response = await api.post(`/api/v1/alertReleasedScheduleHour`, {
                startDate: selectedDay.clone().format('YYYY-MM-DD'),
                servicesHours_id: hour.id,
                services_id: selectedService.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const newHours = filterdHours.map(oldHour => {
                if (oldHour.id === hour.id) {
                    oldHour.askedRelasedHour = true
                    oldHour.askedRelasedHour_id = response?.data?.data[0]?.id
                }
                return oldHour
            })

            setFilteredHours(newHours)
            setAskedHoursLoading(false)
        } catch (error) {
            setAskedHoursLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(error.response.data.message || 'Erro ao se inscrever para ser alertado caso horário libere')
        }
    }

    async function handleRemoveAskReleasedHour(askedRelasedHour_id) {
        try {
            setAskedHoursLoading(true)
            await api.delete(`/api/v1/alertReleasedScheduleHour/${askedRelasedHour_id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const newHours = filterdHours.map(oldHour => {
                if (oldHour?.askedRelasedHour_id === askedRelasedHour_id) {
                    oldHour.askedRelasedHour = false
                    oldHour.askedRelasedHour_id = null
                }
                return oldHour
            })
            setFilteredHours(newHours)
            setAskedHoursLoading(false)
        } catch (error) {
            setAskedHoursLoading(false)
        }
    }

    return <>
        <ScreenModal>
            {
                typeShowModal === 'cancelPreSchedule' ? //cancelamento de pre-reservas
                    <div className='flex p-6 flex-col gap-8 items-center justify-center'>
                        <div className='flex flex-col gap-2 w-full items-center'>
                            <p className='text-sm text-titleBlackTextLight'>Deseja cancelar esta pré-reserva?</p>
                            <p className='text-sm text-titleBlackTextLight'>Se prosseguir, o horário será liberado para outra pessoa.</p>
                        </div>
                        <div className='flex flex-row gap-2 justify-center items-center'>
                            <Button shadow={true} onClick={() => handleCancelPreSchedule()}>Sim</Button>
                            <Button approval={false} shadow={true} onClick={() => { setShowUniversalModal(false); setTypeModal(''); }}>Não</Button>
                        </div>
                    </div>
                    :
                    typeShowModal === 'confirmDrawnSchedule' ?
                        <div className='flex p-6 flex-col gap-8 items-center justify-center'>
                            <div className='flex flex-col gap-2 w-full items-center'>
                                <p className='text-sm text-titleBlackTextLight'>Você participará de um sorteio. Se for contemplado</p>
                                <p className='text-sm text-titleBlackTextLight'>receberá uma notificação por e-mail, deseja continuar?</p>
                            </div>
                            <div className='flex flex-row gap-2 justify-center items-center'>
                                <Button shadow={true} onClick={() => { handleDrawn(); setShowUniversalModal(false); setTypeModal('') }}>Sim</Button>
                                <Button approval={false} shadow={true} onClick={() => { setShowUniversalModal(false); setTypeModal(''); }}>Não</Button>
                            </div>
                        </div>
                        :
                        typeShowModal === 'confirmPix' ?
                            <div className='flex p-6 flex-col gap-8 items-center justify-center'>
                                <div className='flex flex-col gap-2 w-full items-center'>
                                    <p className='text-sm text-titleBlackTextLight'>Deseja pagar com PIX?</p>
                                    <p className='text-sm text-titleBlackTextLight'>Após confirmar, o método de pagamento não poderá mais ser alterado</p>
                                </div>
                                <div className='flex flex-row gap-2 justify-center items-center'>
                                    <Button shadow={true} onClick={() => { handlePaymentPixCheckout(); setShowUniversalModal(false); }}>Sim</Button>
                                    <Button approval={false} shadow={true} onClick={() => { setTypeModal(''); setShowUniversalModal(false) }}>Não</Button>
                                </div>
                            </div>
                            :
                            typeShowModal === 'confirmADEDialog' ?
                                <div className='flex p-6 flex-col gap-8 items-center justify-center'>
                                    <div className='flex flex-col gap-2 w-full items-center'>
                                        <p className='text-sm text-titleBlackTextLight text-red-600 font-semibold'>ATENÇÃO!</p>
                                        <p className='text-sm text-titleBlackTextLight'>
                                            Lembramos que <strong>todas as bebidas devem ser obrigatoriamente adquiridas com a empresa responsável pela administração do quiosque.</strong> <br />O não cumprimento desta regra resultará em uma <strong>multa (rolha) por bebida </strong>trazida de fora.
                                            <br />Quiosques 1, 2, 3, 4, 5, 8 e Costelódromo: Majestoso (47) 99241-5837
                                            <br />Quiosques 6, 7, 9, 10 e Multiuso: MilleBier (47) 99722-0638

                                            <br />
                                            <br />
                                            <strong>Importante:</strong> Você deverá baixar o termo de compromisso em PDF (após o pagamento em "Minhas reservas - Recibo") <br />e apresentá-lo à empresa responsável para solicitar bebidas e retirar as chaves no dia do evento. <br />A retirada das chaves deve ser feita pelo titular da reserva que deve permanecer durante todo evento.

                                            <br />Toda a decoração e outros utensílios pessoais devem ser retirados após o evento, no mesmo dia. Não será permitido retirar no dia seguinte.<br /> Não nos responsabilizamos por qualquer decoração ou utensílios deixados no local.
                                        </p>
                                        <a>Continuar?</a>
                                    </div>
                                    <div className='flex flex-row gap-2 justify-center items-center'>
                                        <Button shadow={true} onClick={() => { selectedPayment?.methodKey === 'P' ? setTypeShowModal('confirmPix') : handlePaymentCardCheckout() && setShowUniversalModal(false); }}>Sim</Button>
                                        <Button approval={false} shadow={true} onClick={() => { setTypeModal(''); setShowUniversalModal(false) }}>Não</Button>
                                    </div>
                                </div>
                                :
                                <div className='flex p-6 flex-col gap-8 items-center justify-center'>
                                    <div className='flex flex-col gap-2 w-full items-center'>
                                        <p className='text-sm text-titleBlackTextLight'>Deseja confirmar a reserva?</p>
                                        <p className='text-sm text-titleBlackTextLight'>O pagamento deverá ocorrer na secretaria para confirmar sua reserva</p>
                                    </div>
                                    <div className='flex flex-row gap-2 justify-center items-center'>
                                        <Button shadow={true} onClick={() => handleConfirmScheduleWoPayment()}>Sim</Button>
                                        <Button approval={false} onClick={() => setShowUniversalModal(false)} shadow={true}>Não</Button>
                                    </div>
                                </div>
            }
        </ScreenModal>
        <Container>
            <div className='w-full flex'>
                <TitlePage>
                    <div className='flex flex-row justify-between w-full items-center'>
                        <Title text={activeStep.id === 0 ? 'Reserva' : 'Pagamento'} />
                    </div>
                </TitlePage>
            </div>
            <Body hasFooter={true} padding={false}>
                {
                    schedulesNotPermitted == 'financialRules' ?
                        <div className='p-2 flex flex-col items-center justify-center my-8 dark:text-titleGrayTextLight text-titleBlackTextDark bg-zinc-100 dark:bg-thirdDefaultDark dark:border-secondaryDefaultLight rounded-lg border border-zinc-100'>
                            <p>Reservas não liberadas ainda</p>
                            <p>Entre em contato com a associação</p>
                        </div>
                        :
                        <>
                            {
                                showTermModal &&
                                <div className='absolute bottom-0 z-40 flex flex-col items-center justify-center h-full w-full'>
                                    <div className='bg-zinc-600 opacity-75 w-full h-full'>

                                    </div>
                                    <div className='absolute z-50 flex flex-col items-center justify-center w-full h-full'>
                                        <div className='mt-3 w-full sm:w-[600px] animate-[wiggle_1s_ease-in-out] bg-white p-6 rounded-lg shadow-lg flex flex-col items-start gap-4'>
                                            <h2 className='text-base text-primaryDefaultLight font-bold'>Termos e condiçoes</h2>

                                            <div className='w-full bg-zinc-50 rounded-lg shadow-inner border-zinc-200 overflow-auto dark:scrollbar-thin dark:scrollbar-track-zinc-700 dark:scrollbar-thumb-zinc-500 text-base break-words whitespace-pre-line sm:text-sm h-80 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>
                                                <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark sm:min-w-[200px]  min-h-96' readOnly={true} theme={"bubble"} value={termOfUseDescription} />
                                            </div>
                                            <p className='text-sm text-primaryDefaultLight'>Aceito o termo</p>
                                            <InputCheckBox id='termOfUse' onClick={() => { setSelectedTermsfUse(!selectedTermsOfUse); setShowTermModal(false); setShowNotificationModal(false) }} value={selectedTermsOfUse ? true : false}></InputCheckBox>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                loading ?
                                    <div className='flex flex-col items-center justify-center h-96'>
                                        <DefaultLoader />
                                    </div>
                                    :
                                    categories?.length === 0 ?
                                        <div className='flex flex-col items-center justify-center py-8'>
                                            <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>
                                                Cliente não possui categorias/serviços cadastrados
                                            </p>
                                        </div>
                                        :
                                        dontHavePaymentMethods ?
                                            <div className='flex flex-col items-center justify-center py-8'>
                                                <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>
                                                    Serviços não encontrados
                                                </p>
                                            </div>
                                            :
                                            categories?.length === 0 ?
                                                <div className='flex flex-col items-center justify-center py-8'>
                                                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>
                                                        Não existem locais disponíveis para reservas
                                                    </p>
                                                </div>
                                                :
                                                typeShowModal === 'preScheduleCancelled' ?
                                                    <div className='flex flex-col items-center justify-center py-8'>
                                                        <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>
                                                            Pré reserva cancelada
                                                        </p>
                                                    </div>
                                                    :
                                                    <div className='flex flex-col relative items-center'>
                                                        <div className='w-[89%] max-w-[1950px] bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center h-16'>
                                                            <div className='w-full lg:max-w-[1200px] 2xl:max-w-[70%] flex flex-col items-center justify-center dark:bg-secondaryDefaultDark h-16 px-4'>
                                                                <Stepper steps={filteredSteps} activeStep={activeStep} changeStep={(e) => setActiveStep(e)} />
                                                            </div>
                                                            <div className='absolute w-full top-20 sm:top-8'>
                                                                {
                                                                    activeStep.id === 0 && custtomerAttendanceDetails.isOpenBySystemTable &&
                                                                    <>
                                                                        {
                                                                            showTableHours ?
                                                                                <div className='flex flex-col w-full items-center justify-center sm:items-start sm:justify-start'>
                                                                                    <a onClick={() => setShowTableHours(false)} className='underline cursor-pointer text-primaryDefaultLight'>Voltar a reserva</a>
                                                                                </div>
                                                                                :
                                                                                <div className='flex flex-col w-full items-center justify-center sm:items-start sm:justify-start'>
                                                                                    <a onClick={() => setShowTableHours(true)} className='underline cursor-pointer text-primaryDefaultLight'>Ver tabela de horários</a>
                                                                                </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            showTableHours ?
                                                                <div className='w-full gap-4 sm:w-[500px] md:w-[650px] lg:w-[700px] xl:w-[950px] 2xl:w-[1200px] py-10 flex flex-col items-center justify-center'>
                                                                    <a className='text-titleBlackTextLight text-sm dark:text-titleGrayTextDark'>Tabela de horários de abertura das reservas</a>
                                                                    <div id='defaultTableHours' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                                        <TableDefault data={defaultTableOpenHours} title={tableThead} collumns={['dayDescription', 'openHourDescription']} />
                                                                    </div>
                                                                    <div id='defaultTableHoursMobile' className='flex sm:hidden flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                                        <TableMobile data={defaultTableOpenHours} title={tableThead} collumns={['dayDescription', 'openHourDescription']} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                activeStep.id === 0 ?

                                                                    <div className='select-none flex flex-col gap-2 items-center justify-center mb-12 mt-2 w-full'>
                                                                        {/* hour modal */}
                                                                        {
                                                                            showSpaceList === 3 &&
                                                                            <div className='flex md:hidden top-0 left-0 z-30 opacity-75 absolute w-[100vw] h-[100vh]  bg-zinc-400'>

                                                                            </div>
                                                                        }
                                                                        <div className='px-4 sm:px-0 flex flex-col mt-12 gap-4 w-full'>
                                                                            <div className='flex flex-col w-full items-center gap-4'>
                                                                                {
                                                                                    isLocalPayment &&
                                                                                    <p className='text-sm font-bold md:text-left text-center rounded-lg p-1 border-b border-primaryDefaultLight'>Este tipo de reserva aceita apenas pagamento no local, ao clicar em próximo passo será criada a <a className='font-semibold text-red-600'>pré-reserva</a> com pagamento pendente que deverá ser efetuado na secretaria.</p>
                                                                                }
                                                                                <div className={`relative md:shadow-lg md:shadow-zinc-300 dark:md:shadow-zinc-900 md:border md:border-zinc-200 dark:md:border-thirdDefaultDark md:max-w-[700px] lg:max-w-[1090px] xl:max-w-[1200px] 2xl:max-w-[1300px] gap-4 md:gap-0 rounded-lg md:h-12 flex flex-col md:flex-row items-center justify-start pl-4 rounded-r-lg`}>
                                                                                    <div ref={spaceCategorieRef} id='spaceOne' onClick={() => { showSpaceList !== 4 && setMyState(4) }} className='relative  h-14 md:h-auto border border-zinc-200 dark:border-primaryDefaultLight md:border-none dark:text-titleGrayTextDark hover:text-primaryDefaultLight transition-all duration-200 cursor-pointer px-6 flex flex-row justify-center md:justify-between items-center w-[325px] md:border-r md:border-x-secondaryBorderDark'>
                                                                                        <div className='flex flex-row justify-between items-center w-full'>
                                                                                            <div className='flex flex-row gap-2 items-center justify-center font-semibold'>
                                                                                                <FaListUl className='text-2xl text-primaryDefaultLight' />
                                                                                                {
                                                                                                    selectedCategorie?.length === 0 ?
                                                                                                        <a className='text-sm'>Selecione o tipo</a>
                                                                                                        :
                                                                                                        <a className='text-sm'>{selectedCategorie?.name}</a>
                                                                                                }
                                                                                            </div>
                                                                                            <FaAngleDown className={`text-base ${showSpaceList === 4 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                                        </div>
                                                                                        <div className={`${showSpaceList === 4 ? 'flex' : 'hidden'} w-full lg:w-[200px] 2xl:w-[250px] dark:bg-secondaryDefaultDark bg-bgPrimaryLight translate-y-2 md:translate-y-1 roundedb-lg top-12 md:top-8 absolute z-30`}>
                                                                                            <div className={`${animateSpace.four ? 'opacity-1' : 'opacity-0'} duration-200 md:dark:border-b border dark:border-primaryDefaultDark border-zinc-200 transition-all flex flex-col w-full max-h-64 overflow-y-auto dark:bg-primaryDefaultDark dark:shadow-lg dark:shadow-zinc-900 bg-bgPrimaryLight shadow-lg`}>
                                                                                                {
                                                                                                    categories.map((categorie) => {
                                                                                                        return <div className='p-4 text-base dark:hover:bg-secondaryDefaultDark hover:bg-bgSecondaryLight w-full cursor-pointer ' onClick={() => { selectCategorie(categorie); setSelectedValue(''); setSelectedHour([]); setSelectedDay(''); setIsLocalPayment(categorie?.isLocalPayment) }}>
                                                                                                            <a className='text-sm'>{categorie.name}</a>
                                                                                                        </div>
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div ref={spaceOneRef} id='spaceOne' onClick={() => setMyState(1)} className='relative  h-14 md:h-auto border border-zinc-200 dark:border-primaryDefaultLight md:border-none dark:text-titleGrayTextDark hover:text-primaryDefaultLight transition-all duration-200 cursor-pointer px-6 flex flex-row justify-center md:justify-between items-center w-[325px] md:border-r md:border-x-secondaryBorderDark'>
                                                                                        <div className='flex flex-row justify-between items-center w-full'>
                                                                                            <div className='flex flex-row gap-2 items-center justify-center font-semibold whitespace-nowrap truncate overflow-hidden'>
                                                                                                <FaMapMarkerAlt className='text-2xl text-primaryDefaultLight' />
                                                                                                {
                                                                                                    selectedService?.length === 0 ?
                                                                                                        <a className='text-sm'>Selecione o local</a>
                                                                                                        :
                                                                                                        <a className='text-sm'>{selectedService?.name}</a>
                                                                                                }
                                                                                            </div>
                                                                                            <FaAngleDown className={`text-base ${showSpaceList === 1 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                                        </div>
                                                                                        <div className={`${showSpaceList === 1 ? 'flex' : 'hidden'} w-full lg:w-[200px] 2xl:w-[250px] dark:bg-secondaryDefaultDark bg-bgPrimaryLight translate-y-2 md:translate-y-1 roundedb-lg top-12 md:top-8 absolute z-30`}>
                                                                                            <div className={`${animateSpace.one ? 'opacity-1' : 'opacity-0'} duration-200 md:dark:border-b border dark:border-primaryDefaultDark border-zinc-200 transition-all flex flex-col w-full max-h-64 overflow-y-auto dark:scrollbar-thin dark:scrollbar-track-zinc-900 dark:scrollbar-thumb-zinc-700 dark:bg-primaryDefaultDark dark:shadow-lg dark:shadow-zinc-900 bg-bgPrimaryLight shadow-lg`}>
                                                                                                {
                                                                                                    filteredServices?.length > 0 ?
                                                                                                        filteredServices.map((service) => {
                                                                                                            return <div className='p-4 text-base dark:hover:bg-secondaryDefaultDark hover:bg-bgSecondaryLight w-full cursor-pointer ' onClick={() => { selectService(service); setSelectedValue(''); setSelectedHour([]); setSelectedDay('') }}>
                                                                                                                <a className='text-sm'>{service.name}</a>
                                                                                                            </div>

                                                                                                        })
                                                                                                        :
                                                                                                        <div className='p-4 w-full cursor-not-allowed '>
                                                                                                            <a className='text-sm'>Selecione um tipo</a>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div ref={spaceTwoRef} id='spaceTwo' onClick={() => setMyState(2)} className='dark:text-titleGrayTextDark relative h-14 md:h-auto border border-zinc-200 dark:border-primaryDefaultLight md:border-none hover:text-primaryDefaultLight transition-all duration-200 cursor-pointer px-6 flex flex-row justify-center md:justify-between items-center w-[325px] md:border-r md:border-x-secondaryBorderDark'>
                                                                                        <div className='flex flex-row items-center justify-between w-full'>
                                                                                            <div className='flex flex-row gap-2 items-center justify-center font-semibold'>
                                                                                                <FaCalendar className='text-2xl text-primaryDefaultLight' />
                                                                                                {!selectedDay ?
                                                                                                    <a className='text-sm'>Selecione o dia</a>
                                                                                                    :
                                                                                                    selectedDay.format("DD/MM/YYYY")
                                                                                                }
                                                                                            </div>
                                                                                            <FaAngleDown className={`text-base ${showSpaceList === 2 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                                        </div>
                                                                                        <div className={`${showSpaceList === 2 ? 'flex' : 'hidden'} w-full lg:w-[200px] 2xl:w-[250px] translate-y-4 md:translate-y-1 top-10 md:top-8 z-30 absolute shadow-lg`}>
                                                                                            <div className='flex flex-col w-full cursor-default '>
                                                                                                {
                                                                                                    selectedService?.length !== 0 && !loading ?
                                                                                                        selectedService.reservationType === 'H' &&
                                                                                                        <CalendarMain externalSelectedDay={selectedDay}
                                                                                                            onClick={(day) => {
                                                                                                                getServiceHoursFree(day);
                                                                                                                closeSpaces()
                                                                                                            }}
                                                                                                            blockedDays={serviceBlockedWeekdays}
                                                                                                            daysToOpen={serviceDaysToOpen}
                                                                                                        />
                                                                                                        :
                                                                                                        <div className='bg-bgPrimaryLight py-4 flex flex-col items-center justify-center'>
                                                                                                            <a className='text-sm'>Selecione o local primeiro</a>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div ref={spaceTreeRef} id='spaceTwo' onClick={() => setMyState(3)}
                                                                                        className='dark:text-titleGrayTextDark h-14 md:h-auto border border-zinc-200 md:border-none 
                                                                hover:text-primaryDefaultLight transition-all duration-200
                                                                px-6 flex flex-row justify-center md:justify-between items-center w-[325px]'>
                                                                                        <div className='flex flex-row justify-between items-center w-full'>
                                                                                            <div className='flex flex-row gap-2 items-center justify-center font-semibold'>
                                                                                                <FaClock className='text-2xl text-primaryDefaultLight' />
                                                                                                {
                                                                                                    selectedHour?.length === 0 ?
                                                                                                        <a className='text-sm'>Selecione o horário</a>
                                                                                                        :
                                                                                                        <>
                                                                                                            {

                                                                                                                selectedHour?.length > 1 ?
                                                                                                                    <a className='text-sm'>+ de 1 horário</a>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <a className='text-sm'>{formatHours(selectedHour[0].startHour)}</a>
                                                                                                                        <a className='text-sm'>as</a>
                                                                                                                        <a className='text-sm'>{formatHours(selectedHour[0].finishHour)}</a>
                                                                                                                    </>

                                                                                                            }
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                            <FaAngleDown className={`text-base ${showSpaceList === 3 ? 'text-primaryDefaultLight' : 'text-titleBlackTextLight'}`} />
                                                                                        </div>

                                                                                        {/* new */}
                                                                                        <div className={`${showSpaceList === 3 ? 'flex' : 'hidden'} items-center flex-row border 
                                                                    gap-4 p-2 translate-y-2 -translate-x-2 md:translate-y-4 md:right-0 
                                                                    animate-[wiggle_.2s_ease-in-out]
                                                                    bottom-20 w-full md:w-auto md:inset-auto md:top-8 fixed md:absolute z-30 dark:bg-primaryDefaultDark dark:shadow-lg dark:shadow-zinc-900 
                                                                    bg-bgPrimaryLight shadow-lg `}>

                                                                                            <div className='w-full flex flex-row items-start justify-center gap-4 '>
                                                                                                <div className='w-full flex flex-col items-center justify-center'>
                                                                                                    <div className='flex md:hidden flex-row items-center justify-between fixed top-0 border-b px-4 py-1 border-zinc-100 bg-white w-full'>
                                                                                                        <a className='text-sm opacity-0'>+ de 1 horário</a>
                                                                                                        {
                                                                                                            selectedHour?.length > 0 ?
                                                                                                                selectedHour?.length > 1 ?
                                                                                                                    <a className='text-sm'>+ de 1 horário</a>
                                                                                                                    :
                                                                                                                    <div className='flex gap-1'>
                                                                                                                        <a className='text-sm'>{formatHours(selectedHour[0]?.startHour)}</a>
                                                                                                                        <a className='text-sm'>as</a>
                                                                                                                        <a className='text-sm'>{formatHours(selectedHour[0]?.finishHour)}</a>
                                                                                                                    </div>
                                                                                                                :
                                                                                                                <a className='text-sm opacity-0'>+ de 1 horário</a>
                                                                                                        }
                                                                                                        <div className='flex items-center justify-center'>
                                                                                                            <button onClick={() => closeSpace()} className={`z-50 bg-white shadow-md rounded-full flex flex-col items-center justify-center w-12 h-12 text-titleBlackTextLight border border-zinc-100 p-2 ${showSpaceList === 3 ? 'flex' : 'hidden'} md:hidden `}>x</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='w-full flex gap-4 justify-center'>
                                                                                                        <div className='w-full mt-16 sm:mt-0 md:w-auto flex flex-col'>
                                                                                                            <div className='w-full flex flex-col gap-1 relative'>
                                                                                                                <div className='flex flex-col items-center justify-center py-4'>
                                                                                                                    <a className='text-sm text-primaryDefaultLight'>Horários disponíveis</a>
                                                                                                                    {
                                                                                                                        askedHoursLoading &&
                                                                                                                        <a className='text-sm absolute top-0 text-titleBlackTextLight animate-pulse'>Salvando...</a>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className={`flex flex-col w-full items-center justify-start py-3 md:grid md:grid-cols-2 gap-1 ${askedHoursLoading ? 'blur-md overflow-hidden' : 'overflow-y-scroll blur-0'} transition-all duration-150 ease-in-out max-h-[40vh]`}>
                                                                                                                    {
                                                                                                                        filterdHours?.length > 0 &&
                                                                                                                        filterdHours.map((hour) => {
                                                                                                                            if (!hour.isDrawn && hour.blockedDescription?.length > 0) {
                                                                                                                                return <Tippy content={hour?.blockedDescription}
                                                                                                                                    disabled={showBlockDescription}
                                                                                                                                    arrow={true}
                                                                                                                                    animation='shift-away'
                                                                                                                                    placement='top'
                                                                                                                                    ignoreAttributes={true}
                                                                                                                                    delay={100}
                                                                                                                                >
                                                                                                                                    <div onClick={() => !hour.isBlocked && handleSelectedHour(hour)}
                                                                                                                                        className={`${selectedHour.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight'
                                                                                                                                            : hour.isBlocked && hour?.askedRelasedHour ? `bg-blue-50 text-zinc-800 border border-zinc-100`
                                                                                                                                                : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100`
                                                                                                                                                    : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight`
                                                                                                                                            } flex flex-row h-12 items-center justify-center gap-1 relative rounded font-light border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                                        {
                                                                                                                                            hour.isSchedule &&
                                                                                                                                            <Tippy content={hour.isBlocked && !hour?.askedRelasedHour
                                                                                                                                                ? 'Clique para ser avisado quando liberar.'
                                                                                                                                                : 'Clique para não ser avisado quando liberar.'
                                                                                                                                            }
                                                                                                                                                arrow={true}
                                                                                                                                                animation='shift-away'
                                                                                                                                                placement='top'
                                                                                                                                                delay={100}
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    onMouseEnter={(e) => setShowBlockDescription(true)}
                                                                                                                                                    onMouseLeave={(e) => setShowBlockDescription(false)}
                                                                                                                                                    onClick={() => {
                                                                                                                                                        hour.isBlocked && !hour?.askedRelasedHour
                                                                                                                                                            ? handleAskReleasedHour(hour)
                                                                                                                                                            : handleRemoveAskReleasedHour(hour?.askedRelasedHour_id)
                                                                                                                                                    }}
                                                                                                                                                    className='absolute sm:-top-1 -top-3 -right-10 sm:-right-0 cursor-pointer'
                                                                                                                                                >

                                                                                                                                                    {/* <a className='text-xs'>Avise-me se liberar</a> */}
                                                                                                                                                    {
                                                                                                                                                        !hour?.askedRelasedHour
                                                                                                                                                            ? <>
                                                                                                                                                                <HiBell size={18} className={`hidden sm:flex text-lg sm:text-sm hover:text-primaryDefaultLight text-zinc-400 cursor-pointer`} />
                                                                                                                                                                <div className='sm:hidden text-xs p-1 bg-primaryDefaultLight flex justify-center items-center shadow-md rounded-lg'>
                                                                                                                                                                    <a className='text-white text-center'>Avisar <br></br> se liberar</a>
                                                                                                                                                                </div>
                                                                                                                                                            </>
                                                                                                                                                            : <>
                                                                                                                                                                <div className='flex text-xs p-1 bg-primaryDefaultLight sm:hidden justify-center items-center shadow-md rounded-lg'>
                                                                                                                                                                    <a className='text-white text-center'>Cancelar <br></br> aviso</a>
                                                                                                                                                                </div>
                                                                                                                                                                <HiBellAlert size={18} className={`hidden sm:flex text-lg sm:text-sm text-primaryDefaultLight z-20 cursor-pointer`} />
                                                                                                                                                            </>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </Tippy>
                                                                                                                                        }
                                                                                                                                        <a className={`${hour.askedRelasedHour ? 'opacity-1' : 'opacity-0'} absolute bottom-0 text-xt text-primaryDefaultLight font-semibold`}>Avise-me se liberar</a>
                                                                                                                                        <p className='text-xs'>{formatHours(hour.startHour)}</p>
                                                                                                                                        <p className='text-xs'>às</p>
                                                                                                                                        <p className='text-xs'>{formatHours(hour.finishHour)}</p>
                                                                                                                                    </div>
                                                                                                                                </Tippy>
                                                                                                                            } else if (!hour.isDrawn) {
                                                                                                                                return <div onClick={() => { !askedHoursLoading && !hour.isBlocked && handleSelectedHour(hour) }} className={`${selectedHour.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100 cursor-not-allowed hover:text-zinc-200` : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24 h-12`}>
                                                                                                                                    <p>{formatHours(hour.startHour)}</p>
                                                                                                                                    <p>às</p>
                                                                                                                                    <p>{formatHours(hour.finishHour)}</p>
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            filterdHours?.length > 0 && filterdHours?.filter(hour => hour.isDrawn).length > 0 &&
                                                                                                            <div className='mt-16 sm:mt-0 w-[50%] md:w-auto flex flex-col '>
                                                                                                                <div className='flex flex-col gap-1 overflow-y-auto'>
                                                                                                                    <div className='flex flex-col items-center justify-center py-4'>
                                                                                                                        <a className='text-sm text-primaryDefaultLight'>Horários para sorteio</a>
                                                                                                                    </div>
                                                                                                                    <div className='flex flex-row flex-wrap items-center justify-start md:grid md:grid-cols-2 gap-1'>
                                                                                                                        {
                                                                                                                            filterdHours?.length > 0 &&
                                                                                                                            filterdHours.map((hour) => {
                                                                                                                                if (hour.isDrawn && hour.blockedDescription?.length > 0 && !hour.finishedDraw) {
                                                                                                                                    return <Tippy content={hour?.blockedDescription}
                                                                                                                                        arrow={true}
                                                                                                                                        animation='shift-away'
                                                                                                                                        placement='top'
                                                                                                                                        delay={100}>
                                                                                                                                        <div onClick={() => { !hour.isBlocked && handleSelectedHour(hour) }} className={`${selectedHour.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100 cursor-not-allowed hover:text-zinc-200` : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} p-1 flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                                            <p>{formatHours(hour.startHour)}</p>
                                                                                                                                            <p>as</p>
                                                                                                                                            <p>{formatHours(hour.finishHour)}</p>
                                                                                                                                        </div>
                                                                                                                                    </Tippy>
                                                                                                                                } else if (hour.isDrawn && !hour.finishedDraw) {
                                                                                                                                    return <div onClick={() => { !hour.isBlocked && handleSelectedHour(hour) }} className={`${selectedHour.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : hour.isBlocked ? `bg-zinc-100 text-zinc-300 border border-zinc-100 hover:bg-zinc-100 cursor-not-allowed hover:text-zinc-200` : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} p-1 flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                                        <p>{formatHours(hour.startHour)}</p>
                                                                                                                                        <p>as</p>
                                                                                                                                        <p>{formatHours(hour.finishHour)}</p>
                                                                                                                                    </div>
                                                                                                                                } else if (hour.isDrawn && hour.finishedDraw) {
                                                                                                                                    return <Tippy content={'Sorteio encerrado'}
                                                                                                                                        arrow={true}
                                                                                                                                        animation='shift-away'
                                                                                                                                        placement='top'
                                                                                                                                        delay={100}>
                                                                                                                                        <div onClick={() => { handleSelectedHour(hour) }} className={`${selectedHour.map(selHour => selHour.id).includes(hour.id) ? 'border border-primaryDefaultLight bg-primaryDefaultLight text-bgPrimaryLight' : `border border-primaryDefaultLight md:hover:bg-secondaryDefaultLight hover:text-bgPrimaryLight `} p-1 flex flex-row items-center justify-center gap-1 rounded text-xs font-light cursor-pointer border-b border-zinc-200 p-6 md:p-1 md:w-24`}>
                                                                                                                                            <p>{formatHours(hour?.startHour)}</p>
                                                                                                                                            <p>as</p>
                                                                                                                                            <p>{formatHours(hour?.finishHour)}</p>
                                                                                                                                        </div>
                                                                                                                                    </Tippy>
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-4 w-full flex flex-col md:flex-row gap-8 justify-center'>
                                                                            {
                                                                                selectedService?.length === 0 ?
                                                                                    <div className='flex flex-col items-center justify-start w-full h-[100vh] sm:h-auto'>
                                                                                        <a className='text-sm ext-titleBlackTextLight dark:text-titleGrayTextDark'>Nenhum local selecionado</a>
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        <div className='flex flex-col items-center md:items-start justify-center md:justify-start'>
                                                                                            <div className='mb-6 w-full flex flex-row items-center justify-center md:justify-start'>
                                                                                                <a className='text-primaryDefaultLight text-base'>Fotos do local</a>
                                                                                            </div>
                                                                                            {
                                                                                                servicePhotos.length > 0
                                                                                                    ? <SwiperContainer imgs={servicePhotos}></SwiperContainer>
                                                                                                    : <p className='w-[300px] h-[350px] text-titleBlackTextLight'>Nenhuma imagem foi encontrada</p>
                                                                                            }
                                                                                        </div>
                                                                                        <div className='flex md:hidden flex-col w-full mt-6 md:mt-0  items-center'>
                                                                                            <h1 className='text-base text-primaryDefaultLight text-bold'>{selectedService?.name}</h1>
                                                                                            <textarea disabled={true} minLength='600px' wrap='hard' className='w-full max-w-[1090px] md:w-auto text-base sm:text-sm 2xl:text-base h-96 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>{selectedService?.description}</textarea>
                                                                                        </div>

                                                                                        <div className='hidden md:flex flex-col w-full mt-5 md:mt-0'>
                                                                                            <h1 className='mb-6 text-base text-primaryDefaultLight text-bold'>{selectedService?.name}</h1>
                                                                                            <div className='sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-[600px] 2xl:max-w-[700px] bg-zinc-50 rounded-lg shadow-inner border-zinc-200 overflow-auto dark:scrollbar-thin dark:scrollbar-track-zinc-700 dark:scrollbar-thumb-zinc-500 md:w-auto text-base break-words whitespace-pre-line sm:text-sm h-96 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>
                                                                                                {selectedService.description}
                                                                                            </div>
                                                                                            {/* <textarea disabled={true} minLength='600px' wrap='hard' className='w-full md:w-auto text-base sm:text-sm 2xl:text-base h-96 p-3 dark:bg-secondaryDefaultDark dark:text-titleGrayTextDark dark:border dark:border-thirdDefaultDark'>{selectedService?.description}</textarea> */}
                                                                                        </div>
                                                                                        <div className='hidden md:flex flex-col gap-4 items-center justify-start'>
                                                                                            <h1 className='mb-3 text-base text-primaryDefaultLight text-bold'>Valores</h1>
                                                                                            {
                                                                                                userData[0]?.customersData?.useBalance &&
                                                                                                <div className='bg-bgPrimaryLight dark:bg-secondaryDefaultDark py-6 border-2 rounded-lg w-[200px] border-primaryDefaultLight flex flex-col items-center justify-center' >
                                                                                                    <p className='text-primaryDefaultLight text-base'><strong>Seu saldo:</strong></p>
                                                                                                    <p className='text-primaryDefaultLight text-base'>{currencyPtBr(myBalance)}</p>
                                                                                                </div>
                                                                                            }
                                                                                            <div className={`${selectedValue !== '' ? 'bg-primaryDefaultLight text-bgPrimaryLight' : 'bg-primaryLight text-primaryDefaultLight'} transition-all duration-300 dark:bg-secondaryDefaultDark py-6 border-2 rounded-lg w-[200px] text-center border-primaryDefaultLight flex flex-col items-center justify-center`}>
                                                                                                <p className='text-base'><strong>Valor da reserva:</strong></p>
                                                                                                <p className='text-base'>{`${(selectedValue || selectedValue === 0) ? selectedValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}`}</p>
                                                                                            </div>
                                                                                            {
                                                                                                selectedService?.modalities?.length > 0 &&
                                                                                                <div className='text-sm flex flex-col gap-1 items-start justify-start'>
                                                                                                    <a className='text-primaryDefaultLight mb-2 font-semibold'>Selecione a modalidade</a>
                                                                                                    {
                                                                                                        selectedService?.modalities?.map(modality =>
                                                                                                            <div className='flex gap-1'>
                                                                                                                <InputCheckBox onClick={() => setSelectedModality(modality)} value={selectedModality === modality ? true : false}></InputCheckBox>
                                                                                                                <a>{modality}</a>
                                                                                                            </div>

                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            selectedService?.modalities?.length > 0 &&
                                                                                            <div className='text-sm flex sm:hidden w-full px-2 flex-col gap-1 items-center justify-start'>
                                                                                                <a className='text-primaryDefaultLight mb-2 font-semibold'>Selecione a modalidade</a>
                                                                                                {
                                                                                                    selectedService?.modalities?.map(modality =>
                                                                                                        <div className='flex gap-1'>
                                                                                                            <InputCheckBox onClick={() => setSelectedModality(modality)} value={selectedModality === modality ? true : false}></InputCheckBox>
                                                                                                            <a>{modality}</a>
                                                                                                        </div>

                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            userData[0]?.customersData?.useBalance &&
                                                                                            <div className='mt-6 flex md:hidden flex-col gap-4 items-center justify-center'>

                                                                                                <div className='bg-bgPrimaryLight dark:bg-secondaryDefaultDark py-4 border-2 rounded-lg w-72 border-primaryDefaultLight flex flex-col items-center justify-center' >
                                                                                                    <p className='text-primaryDefaultLight text-base'><strong>Seu saldo:</strong>{currencyPtBr(myBalance)}</p>
                                                                                                </div>
                                                                                                <div className={`${selectedValue > 0 ? 'bg-primaryDefaultLight text-bgPrimaryLight' : 'bg-primaryLight text-primaryDefaultLight'} transition-all duration-300  dark:bg-secondaryDefaultDark py-4 border-2 rounded-lg w-72 border-primaryDefaultLight flex flex-col items-center justify-center`}>
                                                                                                    <p className='text-base'><strong>Valor da reserva:</strong></p>
                                                                                                    <p className='text-base'>{`${(selectedValue || selectedValue === 0) ? selectedValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}`}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    activeStep.id === 1 ?
                                                                        typeModal === 'E' ?
                                                                            <div className='flex p-6 flex-col h-96 items-center justify-center gap-4 animate-pulse'>
                                                                                <a className='text-sm mb-4 text-titleBlackTextLight'>Verifique se os dados do cartão foram digitados corretamente</a>
                                                                                <a className='text-sm mb-4 text-titleBlackTextLight'>Tente novamente e se o problema persistir contate a secretaria/administração</a>
                                                                                <a className='text-sm text-titleBlackTextLight'>Ou escolha outra forma de pagamento atualizando esta página</a>
                                                                            </div>
                                                                            :
                                                                            typeModal === 'P' ?
                                                                                loadingPayment ?
                                                                                    <div className='flex p-6 flex-col h-96 items-center justify-center gap-4 animate-pulse'>
                                                                                        <div className='p-4 text-transparent opacity-0 flex flex-col items-start justify-center -mt-12 border border-gray-200 bg-gray-100 rounded-lg'>
                                                                                            <a className='text-sm mb-4 text-titleBlackTextLight'>Efetue o <strong>pagamento do PIX</strong> com o QrCode abaixo para confirmar sua reserva</a>
                                                                                        </div>
                                                                                        <a className='text-sm text-titleBlackTextLight'>Solicitando codigo pix a operadora...</a>
                                                                                        <DefaultLoader />
                                                                                    </div>
                                                                                    :
                                                                                    <div className='flex p-6 flex-col gap-4 items-center justify-center min-h-[600px]'>
                                                                                        {
                                                                                            (paymentStatus === 3 || paymentStatus === 0) &&
                                                                                            <>
                                                                                                <div className='p-4 flex flex-col items-start justify-center border border-gray-200 bg-gray-100 rounded-lg'>
                                                                                                    <a className='text-sm mb-4 text-titleBlackTextLight'>Efetue o <strong>pagamento do PIX</strong> com o QrCode abaixo para confirmar sua reserva</a>
                                                                                                    <a className='text-sm text-titleBlackTextLight'><strong>Valor: </strong>{totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                                                                                    <a className='text-sm text-titleBlackTextLight'><strong>Nome: </strong>{gatewayData?.charges[0]?.customer?.name}</a>
                                                                                                    <a className='text-sm text-titleBlackTextLight'><strong>E-mail: </strong>{gatewayData?.charges[0]?.customer?.email}</a>
                                                                                                    <a className='text-sm text-titleBlackTextLight'><strong>Referente a reserva de: </strong>{selectedService?.name}</a>
                                                                                                </div>
                                                                                                <div className='w-48 h-48'>
                                                                                                    <img src={gatewayData?.charges[0]?.last_transaction?.qr_code_url}></img>
                                                                                                </div>
                                                                                                <div className='flex flex-row gap-2 max-w-80'>
                                                                                                    <a className="text-sm max-w-80">Código: {gatewayData?.charges[0]?.last_transaction?.qr_code?.slice(0, 30)?.concat('...')}</a>
                                                                                                    {
                                                                                                        copied ?
                                                                                                            <FaCheck />
                                                                                                            :
                                                                                                            <FaCopy className='text-lg cursor-pointer' onClick={() => { copyToClipboard(gatewayData?.charges[0]?.last_transaction?.qr_code); setCopied(true) }} />
                                                                                                    }
                                                                                                </div>
                                                                                                <div
                                                                                                    onClick={() => { copyToClipboard(gatewayData?.charges[0]?.last_transaction?.qr_code); setCopied(true) }}
                                                                                                    className={`sm:hidden w-full p-2 gap-4 rounded-lg shadow-lg bg-primaryDefaultLight flex flex-row items-center justify-center text-white`}>Copiar código
                                                                                                    {
                                                                                                        copied ?
                                                                                                            <FaCheck />
                                                                                                            :
                                                                                                            <FaCopy className='text-lg' />
                                                                                                    }
                                                                                                </div>
                                                                                                <a className='text-bold text-sm'>Expira em: {formattTimeToShow(gatewayData?.charges[0]?.last_transaction?.expires_at)}</a>
                                                                                                <a className='text-red-600 font-semibold'>Atenção: Após efetuar o pagamento, aguarde até 10 minutos para o pagamento ser processado</a>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                :
                                                                                typeModal === 'C' ?
                                                                                    (paymentStatus === 3 || paymentStatus === 0) &&
                                                                                    <div className='flex p-6 flex-col h-96 items-center justify-center gap-4 animate-pulse'>
                                                                                        <a className='text-sm text-titleBlackTextLight'>Aguardando confirmaçao da operadora de cartão</a>
                                                                                    </div>
                                                                                    :
                                                                                    <div div className='flex flex-col items-center justify-center sm:items-center sm:flex-row flex-wrap mt-12 w-[100%] mb-12 gap-8'>
                                                                                        <LabelInput text={'Forma de pagamento'}>
                                                                                            <InputAutoComplete
                                                                                                data={paymentOption}
                                                                                                selectedLabel={'description'}
                                                                                                optionList={['description']}
                                                                                                width={72}
                                                                                                onChange={(e) => setSelectedPayment(e)}>
                                                                                            </InputAutoComplete>
                                                                                        </LabelInput>
                                                                                        <LabelInput text={'Nome'}>
                                                                                            <Input disabled={true} value={userData[0].firstName}></Input>
                                                                                        </LabelInput>
                                                                                        <LabelInput text={'E-mail'}>
                                                                                            <Input disabled={true} value={userData[0].email}></Input>
                                                                                        </LabelInput>
                                                                                        {
                                                                                            userPermitted &&
                                                                                            <LabelInput text={'Desconto'}>
                                                                                                <CurrencyInput placeHolder={`R$`} id='discountForReservation' onChange={(e) => {
                                                                                                    if (e.target.value?.length > 0 && parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.')) <= selectedValue) {
                                                                                                        handleDiscount(e.target.value)
                                                                                                    } else {
                                                                                                        let resetDiscount = discount
                                                                                                        resetDiscount = resetDiscount?.length > 0 ? parseFloat(discount?.replace('R$', '')?.replace('.', '')?.replace(',', '.')).toString() : ''
                                                                                                        document.getElementById('discountForReservation').value = resetDiscount
                                                                                                        setDiscount(resetDiscount)
                                                                                                        setTotalValue(selectedValue)
                                                                                                    }
                                                                                                }}></CurrencyInput>
                                                                                            </LabelInput>
                                                                                        }
                                                                                        {
                                                                                            ((selectedPayment?.methodKey === 'C' && !thisCustomerHasGateway) || (selectedPayment?.methodKey === 'C' && thisCustomerHasGateway && isLocalPayment)) ?
                                                                                                <div className='flex flex-col items-center justify-center gap-4 sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                                                    {confirmationContainer()}
                                                                                                    {valueConfirmation()}
                                                                                                </div>
                                                                                                :
                                                                                                selectedPayment?.methodKey === 'C' && thisCustomerHasGateway && !isLocalPayment ?
                                                                                                    <div className='flex flex-col w-full items-center'>
                                                                                                        <div className='w-full flex flex-col-reverse xl:flex-row flex-wrap items-start justify-center gap-8'>
                                                                                                            {
                                                                                                                totalValue > 0 &&
                                                                                                                <div className='mt-3 flex flex-col sm:flex-row items-center sm:items-start justify-center gap-4'>
                                                                                                                    <div className='w-full items-start flex flex-col gap-4 mb-6 rounded-lg'>
                                                                                                                        <h2 className='text-primaryDefaultLight font-bold text-base'>Dados do cartão</h2>
                                                                                                                        <div className='flex flex-row gap-1 items-center justify-start'>
                                                                                                                            <Tippy content={'Mastercard'}
                                                                                                                                arrow={true}
                                                                                                                                animation='shift-away'
                                                                                                                                placement='top'
                                                                                                                                delay={100}>
                                                                                                                                <img src={credit1} width={50}></img>
                                                                                                                            </Tippy>
                                                                                                                            <Tippy content={'Visa'}
                                                                                                                                arrow={true}
                                                                                                                                animation='shift-away'
                                                                                                                                placement='top'
                                                                                                                                delay={100}>
                                                                                                                                <img src={credit2} width={50}></img>
                                                                                                                            </Tippy>
                                                                                                                            <Tippy content={'Elo'}
                                                                                                                                arrow={true}
                                                                                                                                animation='shift-away'
                                                                                                                                placement='top'
                                                                                                                                delay={100}>
                                                                                                                                <img src={credit3} width={50}></img>
                                                                                                                            </Tippy>
                                                                                                                            <Tippy content={'American Express'}
                                                                                                                                arrow={true}
                                                                                                                                animation='shift-away'
                                                                                                                                placement='top'
                                                                                                                                delay={100}>
                                                                                                                                <img src={credit4} width={50}></img>
                                                                                                                            </Tippy>
                                                                                                                            <Tippy content={'Hipercard'}
                                                                                                                                arrow={true}
                                                                                                                                animation='shift-away'
                                                                                                                                placement='top'
                                                                                                                                delay={100}>
                                                                                                                                <img src={credit5} width={50}></img>
                                                                                                                            </Tippy>
                                                                                                                        </div>
                                                                                                                        <div id='creditCardVirtual' className='mb-4 shadow-lg shadow-zinc-400 dark:shadow-zinc-900 bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight rounded-lg w-72 h-40 flex flex-col items-center justify-center gap-2 p-4'>
                                                                                                                            <div className='flex flex-col w-full items-start justify-start'>
                                                                                                                                <img width={30} height={25} src={cardChip} />
                                                                                                                                <p className='mb-2 text-titleGrayTextLight text-sm'>{selectedCardNumber?.length > 0 ? maskCardNumber(selectedCardNumber) : 'XXXX XXXX XXXX XXXX'}</p>
                                                                                                                                <div className='flex flex-col w-full items-start justify-center gap-1'>
                                                                                                                                    <p className='text-[0.60rem] text-titleGrayTextLight font-bold'>Vencimento</p>
                                                                                                                                    <div className='flex flex-row items-start justify-center'>
                                                                                                                                        <p className='text-[0.60rem] text-titleGrayTextLight'>{selectedCardDueDateMonth.length > 0 ? selectedCardDueDateMonth : '00'}</p>
                                                                                                                                        <p className='text-[0.60rem] text-titleGrayTextLight'>{"/"}</p>
                                                                                                                                        <p className='text-[0.60rem] text-titleGrayTextLight'>{selectedCardDueDateYear?.length > 0 ? selectedCardDueDateYear : '0000'}</p>
                                                                                                                                    </div>
                                                                                                                                    <p className='text-[0.60rem] text-titleGrayTextLight '>{selectedCardPersonName?.length > 0 ? selectedCardPersonName : 'Seu nome como no cartão'}</p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <LabelInput text={'Numero do cartao'}>
                                                                                                                            <div className='card_object border border-white'>
                                                                                                                                <Input id='number' charLimit={20} width={72} value={selectedCardNumber} onChange={(e) => { setSelectedCardNumber(justNumber(e.target.value)); borderToCardFields(false) }} type={'text'}></Input>
                                                                                                                            </div>
                                                                                                                        </LabelInput>
                                                                                                                        <div className='flex flex-col sm:flex-row gap-2 items-center justify-start'>
                                                                                                                            <LabelInput text={'Vencimento - Mes'}>
                                                                                                                                <div className='card_object border border-white'>
                                                                                                                                    <Input className='card_object border border-white' id='exp_month' width={32} type={'text'}
                                                                                                                                        value={selectedCardDueDateMonth}
                                                                                                                                        onChange={(e) => { setSelectedCardDueDateMonth(e.target.value) }}
                                                                                                                                        charLimit={2}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </LabelInput>
                                                                                                                            <LabelInput text={'Vencimento - Ano'}>
                                                                                                                                <div className='card_object border border-white'>
                                                                                                                                    <Input className='card_object border border-white' id='exp_year' width={32} type={'text'}
                                                                                                                                        value={selectedCardDueDateYear}
                                                                                                                                        onChange={(e) => setSelectedCardDueDateYear(e.target.value)}
                                                                                                                                        charLimit={2}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </LabelInput>
                                                                                                                        </div>
                                                                                                                        <LabelInput text={'Nome no cartao'}>
                                                                                                                            <div className='card_object border border-white'>
                                                                                                                                <Input className='card_object border border-white' id="holder_name" width={72} value={selectedCardPersonName} onChange={(e) => setSelectedCardPersonName(e.target.value)} type={'text'}></Input>
                                                                                                                            </div>
                                                                                                                        </LabelInput>
                                                                                                                        <div className='flex flex-row gap-2 items-center justify-start'>
                                                                                                                            <LabelInput text={'CVV'}>
                                                                                                                                <div className='card_object border border-white'>
                                                                                                                                    <Input className='card_object border border-white' id="cvv" charLimit={3} value={selectedCardCVVNumber} onChange={(e) => setSelectedCardCVVNumber(e.target.value)} width={20} type={'text'}></Input>
                                                                                                                                </div>
                                                                                                                            </LabelInput>
                                                                                                                            <Tippy content={'Cód de segurança atrás do seu cartão'}
                                                                                                                                arrow={true}
                                                                                                                                animation='shift-away'
                                                                                                                                placement='top'
                                                                                                                                delay={100}>
                                                                                                                                <div className='mt-4 flex flex-col items-center justify-center w-24 h-16 px-2 gap-2 bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight rounded-lg'>
                                                                                                                                    <div className='flex flex-col w-[90%] h-3 bg-black'></div>
                                                                                                                                    <div className=' w-[80%] h-5 bg-bgPrimaryLight'>
                                                                                                                                        <p className='text-xs text-titleBlackTextLight'>**** 989</p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Tippy>
                                                                                                                        </div>
                                                                                                                        <LabelInput text={'CPF *'}>
                                                                                                                            <Input width={72} id='userSelectedCPF' value={userSelectedCPF} onChange={(e) => setUserSelectedCPF(justNumber(e?.target?.value))} />
                                                                                                                        </LabelInput>
                                                                                                                        {
                                                                                                                            !userDataDetailed?.phone && !userDataDetailed?.cell &&
                                                                                                                            <div className='mt-6 flex flex-col items-start justify-start'>
                                                                                                                                <a className='text-sm text-titleBlackTextLight'>Dados de contato para pagamento online</a>
                                                                                                                                <LabelInput text={'DDD *'}>
                                                                                                                                    <Input width={72} id="userSelectedAreaCode" value={areaCode} charLimit={2} onChange={(e) => justNumber(setAreaCode(e.target.value))} />
                                                                                                                                </LabelInput>
                                                                                                                                <LabelInput text={'Telefone *'}>
                                                                                                                                    <Input width={72} id="userSelectedPhoneNumber" value={phoneNumber} charLimit={9} onChange={(e) => setPhoneNumber(justNumber(e.target.value))} />
                                                                                                                                </LabelInput>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            <div className='flex flex-col items-center justify-center gap-4 sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                                                                {confirmationContainer()}
                                                                                                                {valueConfirmation()}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    ((selectedPayment?.methodKey === 'P' && !thisCustomerHasGateway) || (selectedPayment?.methodKey === 'P' && thisCustomerHasGateway && isLocalPayment)) ?
                                                                                                        <div className='flex flex-col items-center justify-center gap-4 sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                                                            {confirmationContainer()}
                                                                                                            {valueConfirmation()}
                                                                                                            {
                                                                                                                (activeStep.id === 1 && totalValue === 0) ?
                                                                                                                    <div onClick={() => handleSubmit()} className='cursor-pointer sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px] flex flex-col items-center justify-center bg-primaryDefaultLight hover:bg-secondaryDefaultLight transition-all duration-200 rounded-lg h-12 px-8 shadow-lg'>
                                                                                                                        <a className='text-white'>Pagar</a>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div onClick={() => handleSubmit()} className='cursor-pointer sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px] flex flex-col items-center justify-center bg-primaryDefaultLight hover:bg-secondaryDefaultLight transition-all duration-200 rounded-lg h-12 px-8 shadow-lg'>
                                                                                                                        <a className='text-white'>Pagar com Pix</a>
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        :
                                                                                                        selectedPayment?.methodKey === 'P' && thisCustomerHasGateway && !isLocalPayment ?
                                                                                                            <div className='w-full flex flex-col-reverse xl:flex-row flex-wrap items-center justify-center gap-8'>
                                                                                                                <div className='flex flex-col items-center justify-center gap-4 sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                                                                    {confirmationContainer()}
                                                                                                                    {valueConfirmation()}
                                                                                                                    <div className='flex flex-col items-center gap-4 mt-3 mb-6 rounded-lg'>
                                                                                                                        <h2 className='text-primaryDefaultLight font-bold text-base'>Clique abaixo para pagamento</h2>
                                                                                                                        <LabelInput text={'CPF *:'}>
                                                                                                                            <Input id='userSelectedCPF' value={userSelectedCPF} onChange={(e) => setUserSelectedCPF(justNumber(e.target.value))} charLimit={11}></Input>
                                                                                                                        </LabelInput>
                                                                                                                        {
                                                                                                                            (activeStep.id === 1 && totalValue === 0) ?
                                                                                                                                <div onClick={() => handleSubmit()} className='cursor-pointer sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px] flex flex-col items-center justify-center bg-primaryDefaultLight hover:bg-secondaryDefaultLight transition-all duration-200 rounded-lg h-12 px-8 shadow-lg'>
                                                                                                                                    <a className='text-white'>Pagar</a>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <div onClick={() => confirmPixPayment()} className='cursor-pointer sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px] flex flex-col items-center justify-center bg-primaryDefaultLight hover:bg-secondaryDefaultLight transition-all duration-200 rounded-lg h-12 px-8 shadow-lg'>
                                                                                                                                    <a className='text-white'>Pagar com Pix</a>
                                                                                                                                </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            selectedPayment?.methodKey === 'F' ?
                                                                                                                <div className='w-full flex flex-col-reverse xl:flex-row flex-wrap items-center justify-center gap-8'>
                                                                                                                    <div className='flex flex-col items-center justify-center gap-4 sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                                                                        {confirmationContainer()}
                                                                                                                        {valueConfirmation()}
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                :
                                                                                                                selectedPayment?.methodKey === 'D' &&
                                                                                                                <div className='flex flex-col items-center justify-center gap-4 sm:w-[400px] sm:max-w-[400px] md:w-[600px] md:max-w-[600px]  lg:w-[700px] lg:max-w-[700px] xl:w-[800px] xl:max-w-[800px]'>
                                                                                                                    {confirmationContainer()}
                                                                                                                    {valueConfirmation()}
                                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                        :
                                                                        activeStep.id === 2 && thisCustomerHasGateway ? //clientes com gateway         

                                                                            paymentStatus === 1 ?
                                                                                paymentSuccessWithGateway()
                                                                                :
                                                                                paymentStatus === 2 ?
                                                                                    paymentFailedWithGateway()
                                                                                    :
                                                                                    (selectedPayment?.methodKey === 'D' || selectedPayment?.methodKey === 'F' || isLocalPayment) && //sucesso para pagamentos em folha e dinheiro
                                                                                    paymentSuccesWithoutGateway()
                                                                            :
                                                                            activeStep.id === 2 && !thisCustomerHasGateway ? //clientes sem gateway
                                                                                paymentSuccesWithoutGateway()
                                                                                :
                                                                                activeStep?.id === 3 && //sorteios
                                                                                <div className='flex flex-col items-center justify-center h-96 text-center sm:text-left'>
                                                                                    <a className='text-sm'>Sucesso ao se inscrever no sorteio</a>
                                                                                    <strong className='text-sm text-center sm:text-left'>Você receberá um e-mail caso seja o ganhador, pode acompanhar na tela de sorteios</strong>
                                                                                </div>
                                                        }
                                                    </div>
                            }
                        </>
                }

            </Body>
            <Footer>
                <div className='flex flex-row gap-4 w-full h-96 items-center justify-between'>
                    {
                        activeStep?.id > 0 && !isLocalPayment && totalValue > 0 &&

                        <>
                            {
                                activeStep?.id === 1 && paymentStatus === 0 &&
                                <div className='flex sm:hidden'>
                                    <Button approval={false} shadow={true} onClick={() => { setShowUniversalModal(true); setTypeShowModal('cancelPreSchedule') }}>Cancelar</Button>
                                </div>
                            }
                            <div className='flex gap-1 items-center justify-center'>
                                <Timer minutesDisplay={minutesDisplay} secondsDisplay={secondsDisplay} progress={progress} />
                            </div>
                        </>

                    }
                    {
                        activeStep.id === 0 ?
                            <div className='flex w-full flex-col items-end justify-center'>
                                <Button shadow={true} onClick={() => handleSubmit()}>Próximo passo</Button>
                            </div>
                            :
                            <div className='flex gap-1'>
                                {
                                    activeStep?.id === 1 && paymentStatus === 0 && !dontShowFooter &&
                                    <div className='sm:flex hidden'>
                                        <Button approval={false} shadow={true} onClick={() => { setShowUniversalModal(true); setTypeShowModal('cancelPreSchedule') }}>Cancelar</Button>
                                    </div>
                                }
                                {
                                    activeStep.id < 2 &&
                                    (totalValue || totalValue === 0) && selectedPayment?.methodKey !== "P" && selectedPayment && !dontShowFooter &&
                                    <Button shadow={true} onClick={() => handleSubmit()}>{
                                        activeStep.id < 1 ? 'Próximo passo' :
                                            activeStep.id === 1 && 'Confirmar'
                                    }</Button>
                                }
                            </div>
                    }
                </div>
            </Footer>
        </Container>
    </>

}
