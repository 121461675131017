import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { useAuth } from "../../../contexts/useAuth";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { FaArrowDown, FaArrowUp, FaExternalLinkAlt } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { UsersToFind } from "../../../components/table/usersToFind.table";

export function FinancialBalances() {
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowUniversalModal } = useThemeContext()
    const [filter, setFilter] = useState({})
    const [filterUsers, setFilterUsers] = useState({ name: '' })
    const [detectModal, setDetectModal] = useState('')

    function handleSelectedUser(e) {
        setDetectModal('')
        setShowUniversalModal(false)
        navigate(`/financial/balances/${e?.id}`)
    }

    return (
        <>
            <ScreenModal>
            {
                detectModal === 'usersToFind' &&
                    <UsersToFind handleSelect={(e) => handleSelectedUser(e)} />
            }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full items-center">
                        <Title text={"Saldos"} />
                    </div>
                </TitlePage>
                <Body>
                    <div className="w-full flex flex-col gap-4">
                        <div className="flex flex-col items-start">
                            <h2 className="text-titleBlackTextDark">Selecione o usuário para o qual deseja realizar movimentações</h2>
                            <a 
                                onClick={() => { 
                                    setDetectModal('usersToFind');
                                    setShowUniversalModal(true);
                                }} 
                                className="cursor-pointer text-sm text-zinc-500 underline hover:text-zinc-700 transition-all duration-200"
                            >
                                Selecionar usuário
                            </a>
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <h2 className="text-base text-primaryDefaultLight">Movimentações mais recentes</h2>
                            <NewTableDefault
                                key={'table-financialBalances'}
                                endpoint="/api/v1/financial/usersBalance"
                                token={userData[0]?.token}
                                module="financial"
                                columns={[
                                    {
                                        description: 'ID',
                                        field: 'id',
                                        type: 'number'
                                    },
                                    {
                                        description: 'Tipo',
                                        field: 'entryType',
                                        type: 'string',
                                        newFieldValue: (item) => {
                                            return (
                                                item?.entryType === "S" ?
                                                    <div className="w-full flex flex-row items-center justify-center gap-2">
                                                        <h2>Saída</h2>
                                                        <h2><FaArrowDown size={14} className="text-red-500" /></h2>
                                                    </div>
                                                    : item?.entryType === "E" ?
                                                        <div className="w-full flex flex-row items-center justify-center gap-2">
                                                            <h2>Entrada</h2>
                                                            <h2><FaArrowUp size={14} className="text-green-500" /></h2>
                                                        </div>
                                                        : "-"
                                            )
                                        }
                                    },
                                    {
                                        description: 'Descrição',
                                        field: 'description',
                                        type: 'string',
                                    },
                                    {
                                        description: 'Valor',
                                        field: 'value',
                                        type: 'money',
                                    },
                                    {
                                        description: 'Associado',
                                        field: 'firstName',
                                        type: 'string',
                                    },
                                    {
                                        description: 'CPF',
                                        field: 'cpf',
                                        type: 'string',
                                    },
                                    {
                                        description: 'Associação',
                                        field: 'associationName',
                                        type: 'string',
                                    },
                                    {
                                        description: 'Criado em',
                                        field: 'created_at',
                                        type: 'date',
                                    },
                                    {
                                        description: 'Ações',
                                        field: '',
                                        type: 'string',
                                        newFieldValue: (item) => {
                                            return <div className="flex flex-row items-center justify-center">
                                                <Tippy content={<p>Configurar saldo</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div>
                                                        <span 
                                                            onClick={() => navigate(`/financial/balances/${item?.users_id}`)} 
                                                            className="cursor-pointer"
                                                        >
                                                            <FaExternalLinkAlt size={14} className="text-primaryDefaultLight" />
                                                        </span>
                                                    </div>
                                                </Tippy>
                                            </div>
                                        }
                                    }
                                ]}
                                mapApiData={['data']}
                                filters={filter}
                                usePagination={true}
                                mapQuantityOfRegisters={['quantity']}
                                mapTotalPages={['totalPages']}
                            />
                        </div>
                    </div>
                </Body>
            </Container>
        </>
    )
}
