import React, { useEffect, useState } from 'react'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { FaArrowCircleLeft, FaArrowAltCircleRight, FaPlus, FaInfo, FaMinus } from 'react-icons/fa'
import { Button } from '../../../components/buttons/button.default'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
//UTILS
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
import { Footer } from '../../../components/footer/Footer'
import { validateFields } from '../../../utils/form.validator'
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { UrlToImage } from '../../../utils/formatImages/urlToImgFile'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { verifyFileSize } from '../../../utils/validators/verifyFileSize'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Toogle } from '../../../components/toogle/Toogle'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { validateCNPJ } from '../../../utils/validators/validateCNPJ'

export function AgreementsEdit({ data, setShowAgreementEdit }) {
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const { api, api_financial, defaultCallsSchedules, defaultCallsFinancial } = useApiContext()
    const [activeTab, setActiveTab] = useState(1)
    const [loading, setLoading] = useState(false)
    const [showCategorieCreate, setShowCategorieCreate] = useState(false)
    const [isFeatured, setIsFeatured] = useState(data?.isFeatured)
    const [newCategorieTitle, setNewCategorieTitle] = useState('')
    const [categoriesList, setCategoriesList] = useState([])
    const [loadingFile, setLoadingFile] = useState(false)
    const [title, setTitle] = useState(data?.title)
    const [description, setDescription] = useState(data?.description)
    const [phone, setPhone] = useState(data?.contacts?.phone)
    const [address, setAddress] = useState(data?.contacts?.address)
    const [link, setLink] = useState(data?.contacts.link)
    const [email, setEmail] = useState(data?.contacts?.email)
    const [selectedCategorie, setSelectedCategorie] = useState({ categories: data?.categories })

    //photos
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [agreementAppendPhotos, setAgreementAppendPhotos] = useState([])
    const [pictureToRemove, setPictureToRemove] = useState([])

    // config financial
    const [configFinancial, setConfigFinancial] = useState({
        canSendIncome: '',
        typeSend: '',
        percentage: '',
        email: '',
        userAccess: '',
    })
    const [apportionmentsConfig, setApportionmentsConfig] = useState([{
        financialCategories_id: undefined,
        financialCostCenter_id: undefined,
        percentage: undefined,
        isLinked: false,
        linked: undefined,
        linked_id: undefined,
    }])
    const [costCenter, setCostCenter] = useState([])
    const [moduleLinks, setModuleLinks] = useState([])
    const [categories, setCategories] = useState([])
    const boolOptions = [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
    ]
    const typeValueOptions = [
        { value: "allValue", label: "Valor cheio" },
        { value: "percentage", label: "Porcentagem" },
    ]
    const [configAccess, setConfigAccess] = useState(false)
    const [newAccessData, setNewAccessData] = useState({
        email: '',
        userAccess: '',
    })

    //pictures
    function handleClickInputFile() {
        let inputEl = document.getElementById('pictureAddInput')
        inputEl.click()
    }

    async function handleNewPicture(e) {

        const isValid = verifyFileSize(e.target.files[0])
        if (!isValid) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Arquivo maior do que o permitido (20MB)')
        }
        setShowNotificationModal(false)

        const validFile = permittedFiles(e.target.files[0], 'image')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validFile.response)
        }

        const formattedImg = await new Promise((resolve, reject) => {
            resizeImageTest(e, (err, resizedFile) => {
                if (err) {
                    console.error(err);
                    return;
                }
                resolve(resizedFile)
            })
        })
        const photosAppendArray = URL.createObjectURL(formattedImg)
        setAgreementAppendPhotos([{ photosAppendArray, file: formattedImg }, ...agreementAppendPhotos])
    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = agreementAppendPhotos[showPhoto - 1].photosAppendArray
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = agreementAppendPhotos[showPhoto + 1].photosAppendArray
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)

        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = photo
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo.photosAppendArray + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))

        setAgreementAppendPhotos(agreementAppendPhotos.filter(photoToRemove => photoToRemove.photosAppendArray !== photo.photosAppendArray))

        document.getElementById(photo.photosAppendArray + '-' + indexPhoto).style.transform = 'scale(1)'
    }

    async function handleSubmit() {

        let categorie = ''
        if (typeof (selectedCategorie) === 'string' && selectedCategorie?.trim() !== '') {
            categorie = selectedCategorie
        } else if (selectedCategorie?.categories) {
            categorie = selectedCategorie.categories
        }

        const fields = [
            { "name": 'title', "value": title, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' },
            { "name": 'categories', "value": categorie, "required": true, "type": 'string' },
        ]
        const invalidFields = fields.filter(fd => !fd?.value)
        if (invalidFields?.length > 0 && activeTab === 2) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Preencha os campos com * na aba Dados.')
        }
        if (activeTab === 1) {
            if (validateFields(fields)?.length > 0) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setLoading(false)
                return setShowNotificationModalText('Campos Obrigatórios não preenchidos, verifique os campos em vermelho')
            }
        }

        if (configFinancial?.canSendIncome) {
            const financialFields = [
                { "name": 'canSendIncome', "value": String(configFinancial?.canSendIncome), "required": true, "type": 'string' },
                { "name": 'percentage', "value": configFinancial?.percentage, "required": true, "type": 'string' },
                { "name": 'email', "value": newAccessData?.email ? newAccessData?.email : configFinancial?.email, "required": true, "type": 'string' },
                { "name": 'userAccess', "value": newAccessData?.userAccess ? newAccessData?.userAccess : configFinancial?.userAccess, "required": true, "type": 'string' },
            ]
            if (apportionmentsConfig?.length > 0) {
                apportionmentsConfig.map((app, i) => {
                    financialFields.push({ "name": `apportionmentFinancialCategories_id-${i}`, "value": app?.financialCategories_id, "required": true, "type": 'string' })
                    financialFields.push({ "name": `apportionmentPercentage-${i}`, "value": app?.percentage, "required": true, "type": 'string' })
                })
            }
            const invalidFinancialFields = financialFields.filter(fd => !fd?.value)
            if (invalidFinancialFields?.length > 0 && activeTab === 1) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setLoading(false)
                return setShowNotificationModalText('Preencha os campos com * na aba Financeiro.')
            }
            if (activeTab === 2) {
                if (validateFields(financialFields)?.length > 0) {
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setLoading(false)
                    return setShowNotificationModalText('Campos Obrigatórios não preenchidos, verifique os campos em vermelho')
                }
            }
        }

        setLoading(true)
        try {
            await api.put(`/api/v1/agreements/agreements_id/${data.id}`, {
                title: title,
                description: description,
                categories: categorie,
                isFeatured,
                contacts: {
                    phone: phone,
                    address: address,
                    link: link,
                    email: email
                }
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            if (agreementAppendPhotos?.length > 0) {
                const formData = new FormData();
                await Promise.all(agreementAppendPhotos.map(async (photo) => {
                    const file = await UrlToImage(photo.photosAppendArray, photo.file)
                    formData.append('images', file)
                }))
                await api.post(`/api/v1/agreementsPictures/agreements_id/${data.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })
            }

            if (agreementAppendPhotos.length === 0 && pictureToRemove.length > 0) {
                await Promise.all(pictureToRemove.map(async photo => {
                    await api.delete(`/api/v1/agreementsPictures/agreements_id/${data.id}/picture/${photo.file.name}`, {
                        headers: {
                            Authorization: `Bearer ${userData[0].token}`
                        }
                    })
                }))
            }
            console.log(newAccessData)
            await api_financial.put(`/api/v1/financial/agreements/${data.id}`, {
                canSendIncome: [true, false].includes(configFinancial?.canSendIncome) ? configFinancial?.canSendIncome : undefined,
                incomeApportionmentConfig: JSON.stringify(apportionmentsConfig),
                typeSend: configFinancial?.typeSend ? configFinancial?.typeSend : undefined,
                percentage: configFinancial?.percentage ? configFinancial?.percentage : undefined,
                email: newAccessData?.email && newAccessData?.userAccess ? newAccessData?.email : undefined,
                userAccess: newAccessData?.email && newAccessData?.userAccess ? newAccessData?.userAccess.replace(/\D/g, "") : undefined,
                balanceReduction: configFinancial?.balanceReduction ? configFinancial?.balanceReduction : undefined,
                sellInInstallments: configFinancial?.sellInInstallments ? configFinancial?.sellInInstallments : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            if (configAccess) {
                await api_financial.patch(`/api/v1/financial/agreements/${data.id}/resendAccess`, {
                    email: newAccessData?.email ? newAccessData?.email : (configFinancial?.email || ''),
                    userAccess: newAccessData?.userAccess ? newAccessData?.userAccess : (configFinancial?.userAccess || ''),
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            }

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            await getData()
            setLoading(false)
            setShowAgreementEdit(false)
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function formatAPIPhoto(apiData) {
        if (apiData.picture?.length > 0) {
            let photosArray = []
            let photosAppendArray = []
            await Promise.all(apiData.picture.map(async (picture) => {
                const file = await api.get(`api/v1/agreementsPictures/agreements_id/${picture.agreements_id}/picture/${picture.picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const fileObj = URL.createObjectURL(file.data)
                photosArray.push(picture.picture)
                photosAppendArray.push({ photosAppendArray: fileObj, photosArray: picture.picture, file: { name: picture.picture } })
            }))
            setAgreementAppendPhotos(photosAppendArray)
            setPictureToRemove(photosAppendArray)
        }
    }

    async function getData() {
        setLoading(true)
        const categoriesData = await defaultCallsSchedules.getAgreementsCategories(userData[0]?.token)

        setCategoriesList(categoriesData.data)
        if (categoriesData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(categoriesData.data)
        }

        const thisAgreement = await defaultCallsSchedules.getAgreementsById(userData[0].token, data.id)
        if (thisAgreement.data?.picture?.length > 0) {
            formatAPIPhoto(thisAgreement.data)
        }

        // config financial calls
        const thisFinancialAgreement = await defaultCallsFinancial.getFinancialAgreementPerId(userData[0].token, data.id)
        setConfigFinancial({
            canSendIncome: thisFinancialAgreement.data[0]?.canSendIncome,
            typeSend: thisFinancialAgreement.data[0]?.typeSend,
            percentage: thisFinancialAgreement.data[0]?.percentage,
            email: thisFinancialAgreement.data[0]?.email,
            userAccess: thisFinancialAgreement.data[0]?.userAccess,
            balanceReduction: thisFinancialAgreement.data[0]?.balanceReduction,
            sellInInstallments: thisFinancialAgreement.data[0]?.sellInInstallments,
        })
        // setNewAccessData({
        //     email: thisFinancialAgreement.data[0]?.email,
        //     userAccess: thisFinancialAgreement.data[0]?.userAccess,
        // })

        if (typeof thisFinancialAgreement.data[0]?.incomeApportionmentConfig === "string") {
            setApportionmentsConfig(JSON.parse(thisFinancialAgreement.data[0]?.incomeApportionmentConfig))
        } else if (!!thisFinancialAgreement.data[0]?.incomeApportionmentConfig) {
            setApportionmentsConfig(thisFinancialAgreement.data[0]?.incomeApportionmentConfig)
        }

        const costCenterData = await defaultCallsFinancial.getCostCenter(userData[0]?.token, true)
        if (costCenterData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(costCenterData.data)
        }
        setCostCenter(costCenterData?.data?.map(cost => {
            return {
                ...cost,
                valueInput: `${cost?.code ? cost?.code : cost?.id} - ${cost?.name}`
            }
        }))

        const linksApi = await defaultCallsFinancial.getFinancialListLinks(userData[0]?.token)
        if (linksApi?.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(linksApi.data)
        }
        setModuleLinks(linksApi.data)

        const categoriesApi = await defaultCallsFinancial.getFinancialCategories(userData[0]?.token)
        if (categoriesApi?.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(categoriesApi.data)
        }
        setCategories(categoriesApi.data)

        setLoading(false)
    }

    async function handleNewCategorie() {

        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 30))
        let newCategories = []
        newCategories = categoriesList
        newCategories = newCategories.concat({ categories: newCategorieTitle, id: 0 })
        setCategoriesList(newCategories)
        setSelectedCategorie(newCategories[newCategories?.length - 1])
        await new Promise(resolve => setTimeout(resolve, 30))
        setShowCategorieCreate(false)
        setLoading(false)
    }

    function createNewCategorie() {

        setCategoriesList(categoriesList.filter(categorie => categorie.id !== 0))
        setNewCategorieTitle('')
        setShowCategorieCreate(true)
    }

    useEffect(() => {
        setShowUniversalModal(false)
        getData()
    }, [])

    async function handleDelete() {
        setLoading(true)
        try {
            await api.delete(`/api/v1/agreements/agreements_id/${data.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Removido com sucesso!')
            setLoading(false)
            setShowAgreementEdit(false)


        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className='p-4 flex flex-col items-center justify-start gap-4'>
                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja excluir este convênio?</p>
                    <p className='text-sm text-red-700'>Esse processo é irreversível</p>
                    <div className='flex flex-row gap-4'>
                        <Button onClick={() => handleDelete()} shadow={true}>Sim</Button>
                        <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar convênio'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col h-96 w-full items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='w-full h-full'>
                                {
                                    userData[0]?.customersData?.modules?.map(mod => mod?.id).includes(3) &&
                                    <div className='sm:w-1/4 h-8 flex flex-row items-start justify-start border border-gray-300/50 mb-6'>
                                        <a
                                            onClick={() => setActiveTab(1)}
                                            className={`
                                                cursor-pointer transition-all ease-in duration-200 text-sm
                                                w-1/2 h-full ${activeTab === 1 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                font-semibold flex flex-col items-center justify-center
                                            `}
                                        >
                                            Dados
                                        </a>
                                        <a
                                            onClick={() => setActiveTab(2)}
                                            className={`
                                                cursor-pointer transition-all ease-out duration-200 text-sm
                                                w-1/2 h-full ${activeTab === 2 ? 'bg-primaryDefaultLight text-white hover:brightness-110' : 'bg-transparent text-titleBlackTextLight hover:brightness-75'} 
                                                font-semibold flex flex-col items-center justify-center
                                            `}
                                        >
                                            Financeiro
                                        </a>
                                    </div>
                                }
                                {
                                    activeTab === 1 ?
                                        <>
                                            <div className='w-full items-start justify-start flex flex-col gap-4'>
                                                <div className='flex flex-row gap-4 flex-wrap w-full'>
                                                    {
                                                        showCategorieCreate ?
                                                            <div className='flex flex-col gap-2'>
                                                                <LabelInput text={'Titulo da nova categoria'}>
                                                                    <Input onChange={(e) => setNewCategorieTitle(e.target.value)} value={newCategorieTitle} />
                                                                </LabelInput>
                                                                <div className='w-96 flex flex-row gap-2'>
                                                                    <Button onClick={() => handleNewCategorie()} shadow={true}>Salvar</Button>
                                                                    <Button approval={false} onClick={() => { setNewCategorieTitle(''); setShowCategorieCreate(false) }} shadow={true}>Cancelar</Button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='flex flex-col items-start justify-start'>
                                                                <LabelInput text={'Categoria'}>
                                                                    <InputAutoComplete id={'categories'} preSelectedValue={selectedCategorie?.categories} data={categoriesList} onChange={(e) => setSelectedCategorie(e)} optionList={['categories']} selectedLabel={'categories'} />
                                                                </LabelInput>
                                                                <Tippy content={<p>Criar nova categoria</p>}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='top'
                                                                    delay={100}>
                                                                    <div onClick={() => createNewCategorie()} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                                        <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Nova</a>
                                                                    </div>
                                                                </Tippy>
                                                            </div>
                                                    }
                                                    <div className='flex flex-col items-start justify-start gap-2'>
                                                        <LabelInput text={'Titulo'}>
                                                            <Input charLimit={80} id='title' value={title} onChange={(e) => setTitle(e.target.value)} />
                                                        </LabelInput>
                                                        <a className='text-xt text-inputPlaceholderLight'>{title?.length} de 80</a>
                                                    </div>

                                                    <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                                        <Tippy content={'Ative caso esta convênio seja destaque (para integração com site).'}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='top'
                                                        >
                                                            <div>
                                                                <LabelInput text={'Destaque?'}>
                                                                    <Toogle value={isFeatured} status={isFeatured} onClick={() => setIsFeatured(!isFeatured)} />
                                                                </LabelInput>
                                                            </div>
                                                        </Tippy>
                                                        <a className='text-inputPlaceholderLight text-sm'>Descrição</a>
                                                        <textarea
                                                            maxLength={1000}
                                                            id='description'
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)}
                                                            wrap="hard"
                                                            className="p-2 border border-gray-400 border-opacity-75 h-64 w-full text-sm
                                                                outline-none transition-all duration-200 focus:shadow-borderShadow
                                                                dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark"
                                                        />
                                                        <a className='text-xt text-inputPlaceholderLight'>{description?.length} de 1000</a>
                                                    </div>
                                                </div>
                                                <div className='flex flex-row flex-wrap gap-4 items-start justify-start'>
                                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                                        <LabelInput text={'Telefone'}>
                                                            <Input
                                                                charLimit={20}
                                                                value={phone ? maskPhone(String(phone)) : ''}
                                                                onChange={(e) => setPhone(e.target.value)}
                                                            />
                                                        </LabelInput>
                                                        <a className='text-xt text-inputPlaceholderLight'>{phone?.length} de 20</a>
                                                    </div>
                                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                                        <LabelInput text={'Endereço'}>
                                                            <Input charLimit={100} value={address} onChange={(e) => setAddress(e.target.value)} />
                                                        </LabelInput>
                                                        <a className='text-xt text-inputPlaceholderLight'>{address?.length} de 100</a>
                                                    </div>
                                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                                        <LabelInput text={'Site'}>
                                                            <Input charLimit={100} value={link} onChange={(e) => setLink(e.target.value)} />
                                                        </LabelInput>
                                                        <a className='text-xt text-inputPlaceholderLight'>{link?.length} de 100</a>
                                                    </div>
                                                    <div className='flex flex-col gap-1 items-start justify-start'>
                                                        <LabelInput text={'E-mail'}>
                                                            <Input charLimit={100} value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        </LabelInput>
                                                        <a className='text-xt text-inputPlaceholderLight'>{email?.length} de 100</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='agreementCreatePictures' className='w-full flex flex-col  items-start justify-start gap-4 user-select-none'>
                                                <div className="flex flex-col gap-4">
                                                    <div className='flex flex-col '>
                                                        <h1 className='text-primaryDefaultLight text-base'>Foto</h1>
                                                        <a className='text-xt text-inputPlaceholderLight'>{agreementAppendPhotos.length} de 3</a>
                                                    </div>
                                                    <div className='flex flex-row gap-2 items-start justify-start'>
                                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-50`}>
                                                            <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-4 flex-row items-center justify-center w-full h-full`}>
                                                                {
                                                                    showPhoto > 0 ?
                                                                        <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                                        :
                                                                        <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                                }
                                                                <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                                                    <div className='flex flex-row justify-end w-full'>
                                                                        <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                                    </div>
                                                                    <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    showPhoto < agreementAppendPhotos.length - 1 ?
                                                                        <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                                        :
                                                                        <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-40 left-0 top-0`}></div>

                                                        <h1 className='text-sm text-inputPlaceholderLight'>Foto</h1>
                                                        {
                                                            agreementAppendPhotos?.length < 3 &&
                                                            <Tippy content={'Adicionar foto'}
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='right'
                                                                delay={0}>
                                                                <p className='font-semibold text-base cursor-pointer' onClick={() => handleClickInputFile()}>+</p>
                                                            </Tippy>
                                                        }
                                                    </div>
                                                    {
                                                        !loadingFile &&
                                                        <input id='pictureAddInput' accept='.png, .jpeg, .jpg' type='file' className='hidden' onChange={(e) => handleNewPicture(e)}></input>
                                                    }
                                                    <div className='flex flex-row gap-2 items-center justify-start'>
                                                        {
                                                            agreementAppendPhotos.map((photo, indexPhoto) => {
                                                                return <div id={photo.photosAppendArray + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                                    <Tippy content={'Remover foto'}
                                                                        arrow={true}
                                                                        animation='shift-away'
                                                                        placement='right'
                                                                        delay={300}>
                                                                        <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                                    </Tippy>

                                                                    <img onClick={() => showThisPhoto(photo.photosAppendArray, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={photo.photosAppendArray}></img>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <div className='w-full items-start justify-start flex flex-col gap-4'>
                                            <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                                <div className='flex flex-col gap-4 w-full items-start justify-start'>
                                                    <div className='flex flex-col sm:flex-row items-start justify-start gap-4'>
                                                        <LabelInput text={'Envia lançamentos ao financeiro: *'}>
                                                            <InputAutoComplete
                                                                id={'canSendIncome'}
                                                                preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.canSendIncome)?.label || ''}
                                                                data={boolOptions}
                                                                onChange={(e) => setConfigFinancial(prev => ({ ...prev, canSendIncome: e?.value }))}
                                                                optionList={['label']}
                                                                selectedLabel={'label'}
                                                            />
                                                        </LabelInput>
                                                        {
                                                            configFinancial?.canSendIncome && (
                                                                <LabelInput text={'Porcentagem: *'}>
                                                                    <Input
                                                                        id={'percentage'}
                                                                        name={'percentage'}
                                                                        type={'number'}
                                                                        onChange={(e) => setConfigFinancial(prev => ({ ...prev, percentage: e.target.value }))}
                                                                        value={configFinancial?.percentage || ''}
                                                                    />
                                                                </LabelInput>
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        configFinancial?.canSendIncome &&
                                                        <div className='flex flex-col items-start justify-start gap-2'>
                                                            <div className='flex flex-row items-end gap-2'>
                                                                <LabelInput>Configurar e reenviar acesso</LabelInput>
                                                                <InputCheckBox
                                                                    onClick={(e) => {
                                                                        if (!e) {
                                                                            setNewAccessData({
                                                                                email: configFinancial?.email,
                                                                                userAccess: configFinancial?.userAccess,
                                                                            })
                                                                        }
                                                                        setConfigAccess(e)
                                                                    }}
                                                                    value={configAccess}
                                                                />
                                                            </div>
                                                            <div className='flex flex-row flex-wrap gap-4 w-full items-start justify-start'>
                                                                <div className='flex flex-col items-start justify-start'>
                                                                    <LabelInput text={'E-mail: *'}>
                                                                        <Input
                                                                            disabled={!configAccess}
                                                                            id={'email'}
                                                                            name={'email'}
                                                                            onChange={(e) => setNewAccessData(prev => ({ ...prev, email: e.target.value }))}
                                                                            value={!configAccess ? (configFinancial?.email || '') : newAccessData?.email}
                                                                        />
                                                                    </LabelInput>
                                                                </div>
                                                                <div className='flex flex-col items-start justify-start'>
                                                                    <LabelInput text={'CNPJ: *'}>
                                                                        <Input
                                                                            disabled={!configAccess}
                                                                            id={'userAccess'}
                                                                            name={'userAccess'}
                                                                            onBlur={() => {
                                                                                if (!validateCNPJ(configFinancial?.userAccess.replace(/\D/g, ""))) {
                                                                                    alert("CNPJ invalido")
                                                                                    setConfigFinancial({ ...configFinancial, userAccess: '' })
                                                                                }
                                                                            }}
                                                                            onChange={(e) => setNewAccessData(prev => ({ ...prev, userAccess: e.target.value }))}
                                                                            value={!configAccess ? (configFinancial?.userAccess || '') : newAccessData?.userAccess}
                                                                        />
                                                                    </LabelInput>
                                                                </div>
                                                                <div className='flex flex-col items-start justify-start'>
                                                                    <LabelInput text={'Abate do saldo: *'}>
                                                                        <InputAutoComplete
                                                                            id={'balanceReduction'}
                                                                            preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.balanceReduction)?.label || ''}
                                                                            data={boolOptions}
                                                                            onChange={(e) => setConfigFinancial(prev => ({ ...prev, balanceReduction: e?.value }))}
                                                                            optionList={['label']}
                                                                            selectedLabel={'label'}
                                                                        />
                                                                    </LabelInput>
                                                                </div>

                                                                <div className='flex flex-col items-start justify-start'>
                                                                    <LabelInput text={'Pode vender parcelado: *'}>
                                                                        <InputAutoComplete
                                                                            id={'sellInInstallments'}
                                                                            preSelectedValue={boolOptions.find(op => op?.value === configFinancial?.sellInInstallments)?.label || ''}
                                                                            data={boolOptions}
                                                                            onChange={(e) => setConfigFinancial(prev => ({ ...prev, sellInInstallments: e?.value }))}
                                                                            optionList={['label']}
                                                                            selectedLabel={'label'}
                                                                        />
                                                                    </LabelInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <hr
                                                    hidden={!configFinancial?.canSendIncome ? true : false}
                                                    className='my-6 border w-full'
                                                />
                                                {/* Rateio */}
                                                {
                                                    configFinancial?.canSendIncome &&
                                                    <div className='flex flex-col gap-2 items-start justify-start w-full border border-gray-300 bg-[#f5f5f5] dark:bg-primaryDefaultDark dark:border-primaryBorderDark rounded-md p-4'>
                                                        <h3 className='text-primaryDefaultLight text-xl'>Rateios</h3>
                                                        <div className='flex flex-row justify-start items-end mt-4 border-b border-gray-300 w-full'>
                                                            <div className='flex flex-col items-start gap-2'>
                                                                <div className='flex flex-row items-center gap-2'>
                                                                    <p className='text-titleGrayTextDark'>Restante do rateio</p>
                                                                    <Tippy content={'É o restante que não foi distribuído a partir do valor total desta compra. Para completar o rateio, este restante precisa estar zerado.'}
                                                                        arrow={true}
                                                                        animation='shift-away'
                                                                        placement='top'
                                                                        delay={100}>
                                                                        <div className='cursor-help text-xs text-primaryDefaultLight hover:brightness-150'>
                                                                            <FaInfo />
                                                                        </div>
                                                                    </Tippy>
                                                                </div>
                                                                <div className='flex flex-row justify-start items-end gap-2'>
                                                                    <p className='text-xl text-gray-500 font-semibold tracking-wider'>
                                                                        {100 - apportionmentsConfig?.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0)}
                                                                        %
                                                                    </p>
                                                                    {
                                                                        apportionmentsConfig?.reduce((accumulator, currentValue) => Number(accumulator) + (currentValue?.percentage ? Number(currentValue?.percentage) : 0), 0) !== 100
                                                                        &&
                                                                        <p className='text-red-400 text-xs'>Para prosseguir com a compra o rateio precisa estar zerado.</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            apportionmentsConfig?.length > 0 &&
                                                            apportionmentsConfig.map((app, index) => {
                                                                return (
                                                                    <>
                                                                        <div className={`flex flex-col gap-2 mt-3s`}>
                                                                            <div className={`flex flex-wrap gap-2  ${index == 0 ? '' : 'border-t pt-4'} `}>
                                                                                <LabelInput text={'Categoria: *'}>
                                                                                    <InputAutoComplete
                                                                                        data={categories}
                                                                                        id={`apportionmentFinancialCategories_id-${index}`}
                                                                                        name={`apportionmentFinancialCategories_id-${index}`}
                                                                                        preSelectedValue={categories.find(categorie => categorie.id === app.financialCategories_id)?.description || ''}
                                                                                        value={categories.find(categorie => categorie.id === app.financialCategories_id)?.description || ''}
                                                                                        onChange={(e) => {
                                                                                            app.financialCategories_id = e.id
                                                                                            setApportionmentsConfig([...apportionmentsConfig])
                                                                                        }}
                                                                                        selectedLabel={'description'}
                                                                                        optionList={['id', 'description']}
                                                                                    />
                                                                                </LabelInput>
                                                                                <LabelInput text={'Centro de custo:'}>
                                                                                    <InputAutoComplete
                                                                                        data={costCenter}
                                                                                        id={`apportionmentFinancialCostCenter_id-${index}`}
                                                                                        preSelectedValue={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                                        value={costCenter.find(cost => cost.id === app?.financialCostCenter_id)?.valueInput}
                                                                                        onChange={(e) => {
                                                                                            app.financialCostCenter_id = e.id
                                                                                            setApportionmentsConfig([...apportionmentsConfig])
                                                                                        }}
                                                                                        selectedLabel={'valueInput'}
                                                                                        optionList={['valueInput']}
                                                                                    />
                                                                                </LabelInput>
                                                                                <LabelInput text={'Vinculado:'}>
                                                                                    <InputAutoComplete
                                                                                        data={boolOptions}
                                                                                        id={`isLinked-${index}`}
                                                                                        preSelectedValue={boolOptions?.find(item => item?.value == app?.isLinked)?.label}
                                                                                        value={boolOptions?.find(item => item?.value == app?.isLinked)?.label}
                                                                                        onChange={(e) => {
                                                                                            app.isLinked = e.value
                                                                                            setApportionmentsConfig([...apportionmentsConfig])
                                                                                        }}
                                                                                        selectedLabel={'label'}
                                                                                        optionList={['label']}
                                                                                    />
                                                                                </LabelInput>
                                                                                {
                                                                                    app?.isLinked ?
                                                                                        <LabelInput text={'Vinculado com: *'}>
                                                                                            <InputAutoComplete
                                                                                                data={moduleLinks}
                                                                                                id={`apportionmentLinked-${index}`}
                                                                                                preSelectedValue={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                                                value={moduleLinks?.find(item => item?.linked_id == app?.linked_id && item?.linked == app?.linked)?.linked_name}
                                                                                                onChange={(e) => {
                                                                                                    app.linked_id = e.linked_id
                                                                                                    app.linked = e.linked
                                                                                                    setApportionmentsConfig([...apportionmentsConfig])
                                                                                                }}
                                                                                                selectedLabel={'linked_name'}
                                                                                                optionList={['linked_id', 'linked_name']}
                                                                                            />
                                                                                        </LabelInput>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <LabelInput text={'Porcentagem (%): *'}>
                                                                                    <Input
                                                                                        id={`apportionmentPercentage-${index}`}
                                                                                        name={`apportionmentPercentage-${index}`}
                                                                                        type={'number'}
                                                                                        value={app.percentage || ''}
                                                                                        onChange={(e) => {
                                                                                            app.percentage = parseFloat(e.target.value)
                                                                                            setApportionmentsConfig([...apportionmentsConfig])
                                                                                        }}
                                                                                    />
                                                                                </LabelInput>
                                                                            </div>
                                                                            <div className='flex items-center my-2 gap-2'>
                                                                                <span onClick={() => {
                                                                                    setApportionmentsConfig((prev) => ([...prev, {
                                                                                        financialCategories_id: undefined,
                                                                                        financialCostCenter_id: undefined,
                                                                                        percentage: undefined,
                                                                                        isLinked: false,
                                                                                        linked: undefined,
                                                                                        linked_id: undefined,
                                                                                    }]))
                                                                                }}>
                                                                                    <FaPlus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                                                </span>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        apportionmentsConfig.splice(index, 1)
                                                                                        setApportionmentsConfig([...apportionmentsConfig])
                                                                                    }}>
                                                                                    <FaMinus size={14} className='text-primaryDefaultLight hover:cursor-pointer' />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                    }

                </Body>
                <Footer>
                    {
                        !loading &&
                        <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                            <Button
                                id='cancelAgreementCreate'
                                approval={false}
                                shadow={true}
                                onClick={() => { setShowAgreementEdit(false) }}
                            >Voltar
                            </Button>
                            <div className='flex flex-row gap-4'>
                                <Button
                                    module={"agreements:delete"}
                                    userData={userData}
                                    id='approvalAgreementsCreate'
                                    approval={false}
                                    shadow={true}
                                    onClick={() => setShowUniversalModal(true)}
                                >Excluir
                                </Button>
                                <Button
                                    module={"agreements:update"}
                                    userData={userData}
                                    id='approvalAgreementsCreate'
                                    approval={true}
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Salvar
                                </Button>
                            </div>
                        </div>
                    }
                </Footer>
            </Container>
        </>
    )
}
