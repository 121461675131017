import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "../../components/container/container";
import { TitlePage } from "../../components/titlePages/title.page";
import { Title } from "../../components/titlePages/title";
import { Body } from "../../components/container/Body";
import { FaArrowDown, FaArrowUp, FaCalendar, FaMoneyBill, FaReply } from "react-icons/fa";
import { useThemeContext } from "../../contexts/themeContext";
import { useAuth } from "../../contexts/useAuth";
import moment from "moment";
import { LabelInput } from "../../components/label/label.input";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { FinancialAreaGraph } from "../../components/financialDashboard/financial_area";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { useApiContext } from "../../contexts/ApiInterceptorContext";
import agendaRapidoLogo from '../../assets/logos/loginLogo.svg'

export function FinancialDashboard() {

    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loadingDashView, setLoadingDashView] = useState(false)
    const [loadingDash, setLoadingDash] = useState({ generalData: true, resultOnYear: true, balanceAccounts: true })
    const { userData } = useAuth()
    const { defaultCallsFinancial } = useApiContext()

    const navigate = useNavigate()
    const [years, setYears] = useState([])
    const [selectedYear, setSelectedYear] = useState({ id: moment()?.format("YYYY") })
    const [generalData, setGeneralData] = useState('')
    const [paymentsOnYear, setPaymentsOnYear] = useState([])
    const [receivesOnYear, setReceivesOnYear] = useState([])
    const [accountsData, setAccountsData] = useState('')

    function getYears() {

        const years = [];
        const currentYear = moment().year();
        setSelectedYear({ id: currentYear?.toString(), description: currentYear?.toString() })

        for (let i = currentYear - 5; i <= currentYear + 5; i++) {
            years.push({
                id: i?.toString(),
                description: i?.toString()
            });
        }

        setYears(years)
    }

    useEffect(() => {
        async function delay() {
            await new Promise(resolve => setTimeout(resolve, 300))
        }
        userData[0].token.length > 0 && delay()
        getData()
    }, [userData])

    async function getData() {

        getYears()

        const [
            paymentsLateAPI,              // pagamentos em atraso
            receiveLateAPI,               // Recebimentos em atraso
            billsToPayAPI,                // A pagar no mês - anterior
            billsToReceiveAPI,            // A receber no mês - anterior
            billsPaidAPI,                 // Pagas no mês - anterior
            billsReceivedAPI,             // Recebidas no mês - anterior
            paymentsAndReceivesOnYearAPI, // Recebimentos e pagamentos no ano
            balanceAccountsAPI,           // Saldo em contas
        ] = await Promise.all([
            await defaultCallsFinancial.getFinancialPaymentsLate(userData[0]?.token),
            await defaultCallsFinancial.getFinancialReceiveLate(userData[0]?.token),
            await defaultCallsFinancial.getFinancialBillsToPay(userData[0]?.token),
            await defaultCallsFinancial.getFinancialBillsToReceive(userData[0]?.token),
            await defaultCallsFinancial.getFinancialBillsPaid(userData[0]?.token),
            await defaultCallsFinancial.getFinancialBillsReceived(userData[0]?.token),
            await defaultCallsFinancial.getFinancialBillsOnYear(userData[0]?.token, moment().format("YYYY")),
            await defaultCallsFinancial.getFinancialBalanceAccounts(userData[0]?.token)
        ])

        if (paymentsLateAPI.error) { // pagamentos em atraso
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(paymentsLateAPI.data)
        }
        if (receiveLateAPI.error) { // Recebimentos em atraso
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(receiveLateAPI.data)
        }
        if (billsToPayAPI.error) { // A pagar no mês - anterior
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(billsToPayAPI.data)
        }
        if (billsToReceiveAPI.error) { // A receber no mês - anterior
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(billsToReceiveAPI.data)
        }
        if (billsPaidAPI.error) { // Pagas no mês - anterior
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(billsPaidAPI.data)
        }
        if (billsReceivedAPI.error) { // Recebidas no mês - anterior
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(billsReceivedAPI.data)
        }
        if (paymentsAndReceivesOnYearAPI.error) { // Recebimentos e pagamentos no ano
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(paymentsAndReceivesOnYearAPI.data)
        }
        if (balanceAccountsAPI.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(balanceAccountsAPI.data)
        }

        setGeneralData({
            paymentsLate: Number(paymentsLateAPI.data?.result),
            receiveLate: Number(receiveLateAPI.data?.result),
            billsToPayInMonth: Number(billsToPayAPI.data?.billsToPayCurrentMonth),
            billsToPayPreviousMonth: Number(billsToPayAPI.data?.billsToPaymentPreviousMonth),
            billsToReceive: Number(billsToReceiveAPI.data?.billsToReceiveCurrentMonth),
            billsToReceivePreviousMonth: Number(billsToReceiveAPI.data?.billsToReceivePreviousMonth),
            billsPaid: Number(billsPaidAPI.data?.billsPaidCurrentMonth),
            billsPaidDiff: Number(billsPaidAPI.data?.billsPaidCurrentMonth) - Number(billsPaidAPI.data?.billsPaidPreviousMonth),
            billsReceived: Number(billsReceivedAPI.data?.billsReceivedCurrentMonth),
            billsReceivedDiff: Number(billsReceivedAPI.data?.billsReceivedCurrentMonth) - Number(billsReceivedAPI.data?.billsReceivedPreviousMonth)
        })
        setLoadingDash(prev => ({ ...prev, generalData: false }))
        setPaymentsOnYear(paymentsAndReceivesOnYearAPI.data?.billsPayments)
        setReceivesOnYear(paymentsAndReceivesOnYearAPI.data?.billsReceives)
        setLoadingDash(prev => ({ ...prev, resultOnYear: false }))
        setAccountsData(balanceAccountsAPI.data)
        setLoadingDash(prev => ({ ...prev, balanceAccounts: false }))

    }

    async function updateYearDashboardForAnual(year) {

        setLoadingDash(prev => ({ ...prev, resultOnYear: true }))
        setSelectedYear(year)
        const paymentsAndReceivesOnYearAPI = await defaultCallsFinancial.getFinancialBillsOnYear(userData[0]?.token, year?.description)
        if (paymentsAndReceivesOnYearAPI.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(paymentsAndReceivesOnYearAPI.data)
        }

        setPaymentsOnYear(paymentsAndReceivesOnYearAPI.data?.billsPayments)
        setReceivesOnYear(paymentsAndReceivesOnYearAPI.data?.billsReceives)
        setLoadingDash(prev => ({ ...prev, resultOnYear: false }))

    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Dashboard Financeiro'} />
            </TitlePage>
            <Body shadow={false} background={false} padding={false}>
                {
                    userData[0]?.permissions.includes('financialDashboards:read') || userData[0]?.typeAccess_id < 3 ?
                        <div className='flex flex-col w-full gap-3 items-start lg:justify-center h-full'>
                            <div className='grid grid-cols-1 lg:grid-cols-6 gap-4 w-full'>
                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                    {
                                        loadingDash['generalData'] ?
                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                            :
                                            <>
                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-red-300'>{generalData?.paymentsLate?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Pagamentos atrasados</p>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                    {
                                        loadingDash['generalData'] ?
                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                            :
                                            <>
                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-red-300'>{generalData?.receiveLate?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Recebimentos atrasados</p>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                    {
                                        loadingDash['generalData'] ?
                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                            :
                                            <>
                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{generalData?.billsToReceive?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>A receber (mês)</p>
                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.billsToReceivePreviousMonth <= 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                        <FaReply />
                                                        <p className='text-xs font-semibold opacity-70'>{generalData?.billsToReceivePreviousMonth.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                    {
                                        loadingDash['generalData'] ?
                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                            :
                                            <>
                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{generalData?.billsToPayInMonth?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>A pagar (mês)</p>
                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.billsToPayPreviousMonth <= 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                        <FaReply />
                                                        <p className='text-xs font-semibold opacity-70'>{generalData?.billsToPayPreviousMonth.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                    {
                                        loadingDash['generalData'] ?
                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                            :
                                            <>
                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{generalData?.billsPaid?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Pagos (mês)</p>
                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.billsPaidDiff >= 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                        {
                                                            generalData?.billsPaidDiff > 0 ?
                                                                <FaArrowUp />
                                                                :
                                                                <FaArrowDown />
                                                        }
                                                        <p className='text-xs font-semibold opacity-70'>{generalData?.billsPaidDiff?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className='p-3 flex justify-between rounded-lg flex-row gap-1 min-w-[80px] h-32 w-full bg-bgPrimaryLight dark:bg-secondaryDefaultDark shadow-lg dark:border dark:border-secondaryBorderDark'>
                                    {
                                        loadingDash['generalData'] ?
                                            <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                            :
                                            <>
                                                <div className='flex flex-col items-start justify-start gap-1'>
                                                    <p className='text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold'>{generalData?.billsReceived?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Recebidos (mês)</p>
                                                    <div className={`text-sm animate-[wiggle_1.2s_ease-in-out] flex gap-1 items-center justify-start ${generalData?.billsReceivedDiff >= 0 ? 'text-primaryDefaultLight' : 'text-red-300'}`}>
                                                        {
                                                            generalData?.billsReceivedDiff > 0 ?
                                                                <FaArrowUp />
                                                                :
                                                                <FaArrowDown />
                                                        }
                                                        <p className='text-xs font-semibold opacity-70'>{generalData?.billsReceivedDiff?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className='flex lg:flex-row flex-col gap-4 w-full'>
                                <div className='flex flex-col shadow-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 lg:p-3 w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                    <div className='flex flex-col gap-1 justify-start items-start w-full'>
                                        <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl'>Resultado anual</h1>
                                        {
                                            <LabelInput text={'Ano'}>
                                                <InputAutoComplete
                                                    width={56}
                                                    data={years}
                                                    preSelectedValue={selectedYear?.id}
                                                    selectedLabel={'id'}
                                                    optionList={['id']}
                                                    onChange={(e) => updateYearDashboardForAnual(e)}
                                                />
                                            </LabelInput>
                                        }
                                    </div>
                                    {
                                        loadingDash['resultOnYear'] ?
                                            <div style={{ minHeight: '365px', height: '365px' }} className='w-full items-end justify-center animate-pulse flex flex-row gap-3'>
                                                <div className='bg-gray-300 h-44 w-12'></div>
                                                <div className='bg-gray-300 h-48 w-12'></div>
                                                <div className='bg-gray-300 h-32 w-12'></div>
                                                <div className='bg-gray-300 h-28 w-12'></div>
                                                <div className='bg-gray-300 h-56 w-12'></div>
                                                <div className='bg-gray-300 h-48 w-12'></div>
                                                <div className='bg-gray-300 h-64 w-12'></div>
                                                <div className='bg-gray-300 h-44 w-12'></div>
                                                <div className='bg-gray-300 h-32 w-12'></div>
                                                <div className='bg-gray-300 h-28 w-12'></div>
                                            </div>
                                            :
                                            <div style={{ maxHeight: '365px', height: '365px' }} className='overflow-hidden'>
                                                <FinancialAreaGraph
                                                    id='billsOnYear'
                                                    title={'Análise anual'}
                                                    data={paymentsOnYear}
                                                    data2={receivesOnYear}
                                                />
                                            </div>
                                    }
                                </div>
                                <div className='flex flex-col shadow-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 lg:p-3 w-full xl:w-[50%] border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg gap-2'>
                                    <div className="flex flex-row justify-between items-center w-full">
                                        <h1 className='dark:text-titleGrayTextDark text-titleBlackTextLight font-semibold text-lg xl:text-xl 2xl:text-2xl'>Contas e Saldos</h1>
                                        {
                                            loadingDash['balanceAccounts'] ?
                                                <div className='bg-gray-300 animate-pulse h-4 w-12'></div>
                                                :
                                                !!accountsData?.totalAccounts &&
                                                <p className={`animate-[wiggle_1s_ease-in-out] dark:text-titleGrayTextDark font-semibold text-base ${accountsData?.totalAccounts < 0 ? 'text-red-400' : 'text-titleBlackTextLight'}`}>{accountsData?.totalAccounts?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                        }
                                    </div>
                                    {
                                        accountsData?.data?.length > 0
                                            ?
                                            <div className='flex flex-col gap-1 justify-center items-center h-full'>
                                                {
                                                    loadingDash['balanceAccounts'] ?
                                                        <div className='w-[30rem] h-52 items-center justify-center animate-pulse flex flex-row gap-3'>
                                                            <div className='bg-gray-300 h-full w-full mt-10'></div>
                                                        </div>
                                                        :
                                                        <div className='flex flex-col items-center justify-center rounded-lg lg:max-w-[32rem] w-full lg:h-full' >
                                                            <Swiper
                                                                modules={[Pagination, Autoplay]}
                                                                style={{ maxWidth: '100%', height: '255px' }}
                                                                pagination={true}
                                                                spaceBetween={20}
                                                                slidesPerView={accountsData?.data?.length < 2 ? 1 : 2}
                                                            >
                                                                {
                                                                    accountsData?.data?.map((account, index) => {
                                                                        return (
                                                                            <SwiperSlide key={index}>
                                                                                <div className="max-w-full w-full h-64 p-2">
                                                                                    <div className="grid grid-rows-3 grid-cols-1 justify-between w-full h-full p-2 shadow-borderShadow rounded-lg">
                                                                                        <div className="row-span-1 flex flex-row gap-1 items-start justify-between w-full">
                                                                                            <div>
                                                                                                <p className="dark:text-titleGrayTextDark text-titleBlackTextLight text-sm">{account?.name?.length > 20 ? account?.name?.slice(0, 20) + '...' : account?.name}</p>
                                                                                                <p className="dark:text-titleGrayTextDark text-titleBlackTextLight text-sm">{account?.accountNumber}</p>
                                                                                            </div>
                                                                                            <div className='text-zinc-100 gap-2 flex-col items-center justify-center hidden lg:flex rounded-2xl w-8 h-8 bg-primaryDefaultLight opacity-70 shadow-sm'>
                                                                                                <FaMoneyBill />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row-span-1 flex flex-col justify-center items-center w-full">
                                                                                            <p className={`animate-[wiggle_1s_ease-in-out] text-lg md:text-base xl:text-lg 2xl:text-2xl dark:text-titleGrayTextDark font-semibold ${account?.balance < 0 ? 'text-red-400' : 'text-titleBlackTextLight'}`}>
                                                                                                {account?.balance?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                                                                            </p>
                                                                                            {
                                                                                                account.accountType === 'bank' && userData[0]?.customersData?.useOfxConciliation &&
                                                                                                <p
                                                                                                    className="text-sm text-primaryDefaultLight underline cursor-pointer hover:opacity-70"
                                                                                                    onClick={() => navigate(`/financial/accounts/${account?.id}/conciliations`)}
                                                                                                >
                                                                                                    Conciliar conta
                                                                                                </p>
                                                                                            }
                                                                                            {
                                                                                                account.accountType === 'cash' &&
                                                                                                <p className="text-sm text-primaryDefaultLight">
                                                                                                    Caixa
                                                                                                </p>
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            userData[0]?.customersData?.useOfxConciliation &&
                                                                                            <div className="row-span-1 flex flex-row justify-start items-end">
                                                                                                <p className='dark:text-titleGrayTextDark text-titleBlackTextLight text-sm'>
                                                                                                    Ultima Conciliação: -
                                                                                                </p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </SwiperSlide>
                                                                        )
                                                                    })
                                                                }
                                                            </Swiper>
                                                        </div>
                                                }
                                            </div> :
                                            <div className="w-full h-full flex flex-col items-center justify-center">
                                                <h2 className="text-gray-500 text-base">Nenhuma informação encontrada</h2>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className='py-4 flex flex-col w-full gap-3 items-center justify-center h-full'>
                            <img src={agendaRapidoLogo} width={300} />
                            <h1 className='text-lg text-primaryDefaultLight dark:text-titleGrayTextDark'>Bem vindo {userData[0]?.firstName}</h1>
                            <p className=' text-sm xl:text-xs 2xl:text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Use o menu lateral para acessar as funcionalidades</p>
                        </div>
                }
            </Body>
        </Container>
    )
}
